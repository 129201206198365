<div class="details_search pb-5">
    <div class="container-fluid pb-3">
        <form action="">
            <div class="row">
                <div class="col-md-10">
                    <div class="row">
                        <div class="col-md-2">
                            <div>
                                <mat-form-field>
                                    <mat-label>Type</mat-label>
                                    <mat-select>

                                        <mat-option value="oneway" selected>One Way</mat-option>
                                        <mat-option value="round">Round Trip</mat-option>
                                        <mat-option value="multi">Multi City</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div>
                                <div class="row">
                                    <div class="col-md-5">
                                        <mat-form-field>
                                            <mat-label>From</mat-label>
                                            <mat-select>
                                                <mat-option>None</mat-option>
                                                <mat-option *ngFor="let location of locations"
                                                    [value]="location">{{location}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md-2">
                                        <img src="../../../../../assets/img/about/interchange.png" alt="">
                                    </div>
                                    <div class="col-md-5">
                                        <mat-form-field>
                                            <mat-label>To</mat-label>
                                            <mat-select>
                                                <mat-option>None</mat-option>
                                                <mat-option *ngFor="let location of locations"
                                                    [value]="location">{{location}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="d-flex gap-2">

                                <mat-form-field>
                                    <mat-label>DEPART</mat-label>
                                    <input matInput [matDatepicker]="picker">
                                    <!-- <mat-hint>MM/DD/YYYY</mat-hint> -->
                                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                                    <mat-datepicker #picker></mat-datepicker>
                                </mat-form-field>
                                <mat-form-field>
                                    <mat-label>RETURN</mat-label>
                                    <input matInput [matDatepicker]="picker">
                                    <!-- <mat-hint>MM/DD/YYYY</mat-hint> -->
                                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                                    <mat-datepicker #picker></mat-datepicker>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div>

                                <mat-form-field class="example-full-width">
                                    <mat-label>Rooms & guests</mat-label>
                                    <input matInput placeholder="" value="1 Rooms, 2 Adults">
                                    <!-- <mat-hint>Rooms & guests</mat-hint> -->
                                </mat-form-field>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="col-md-2 submitbtn">
                    <div class="row">
                        <div class="">
                            <div class="text-end">
                                <input class="saas-btn" type="submit" value="Search">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-2">
                    <p class="text-white text-end">Fare Type:</p>
                </div>
                <div class="col-md-10">
                    <div class="d-flex gap-1">
                        <div><mat-radio-group class="d-flex" aria-label="Select an option">
                                <div class="bg-white select12"><mat-radio-button value="1">Regular</mat-radio-button>
                                </div>
                                <div class="bg-white select12"><mat-radio-button value="1">Armed
                                        Forces</mat-radio-button></div>
                                <div class="bg-white select12"><mat-radio-button value="1">Student</mat-radio-button>
                                </div>
                                <div class="bg-white select12"><mat-radio-button value="1">Senior
                                        Citizen</mat-radio-button>
                                </div>
                                <div class="bg-white select12"><mat-radio-button value="1">Doctors &
                                        Nurses</mat-radio-button>
                                </div>
                                <div class="bg-white select12"><mat-radio-button value="1">Double
                                        Seat</mat-radio-button></div>
                            </mat-radio-group></div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>