<div class="bg-dark pb-5">
    <app-navbar-one></app-navbar-one>
    <app-flight-search-top></app-flight-search-top>
    <div class="container-fluid">
        <div class="bg-white p-2 rounded sometop">
            <div class="row">
                <div class="col-2 m-auto">
                    <div class="secfont "><i class="fa fa-filter secfont" aria-hidden="true"></i></div>
                    <div class="text-black">Filter</div>
                </div>
                <div class="col-2">
                    <span class="font-s secfont">Stops:</span>
                    <mat-button-toggle-group name="ingredients" aria-label="Ingredients" multiple>
                        <mat-button-toggle value="0">0</mat-button-toggle>
                        <mat-button-toggle value="1">1</mat-button-toggle>
                        <mat-button-toggle value="2">2</mat-button-toggle>
                    </mat-button-toggle-group>
                </div>
                <div class="col-2">
                    <p class="font-s secfont">Price</p>
                </div>
                <div class="col-2">
                    <p class="font-s secfont">Check-In</p>
                </div>
                <div class="col-2">
                    <p class="font-s secfont">User Rating</p>
                </div>

                <div class="col-2">
                    <p class="font-s secfont">Search</p>
                </div>
            </div>
        </div>
    </div>

    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="d-flex bg-white slider12">
                    <div class="partner-slider owl-carousel owl-theme">

                        <div class="partner-item" target="_blank" class="d-block">
                            <div class="text-center border12 py-2">
                                <p class="mb-0">Thu, 30 Nov</p>
                                <h4>AED 2000</h4>
                            </div>
                        </div>

                        <div class="partner-item" target="_blank" class="d-block">
                            <div class="text-center border12 py-2">
                                <p class="mb-0">Thu, 30 Nov</p>
                                <h4>AED 2000</h4>
                            </div>
                        </div>

                        <div class="partner-item" target="_blank" class="d-block">
                            <div class="text-center border12 py-2">
                                <p class="mb-0">Thu, 30 Nov</p>
                                <h4>AED 2000</h4>
                            </div>
                        </div>

                        <div class="partner-item" target="_blank" class="d-block">
                            <div class="text-center border12 py-2">
                                <p class="mb-0">Thu, 30 Nov</p>
                                <h4>AED 2000</h4>
                            </div>
                        </div>

                        <div class="partner-item" target="_blank" class="d-block">
                            <div class="text-center border12 py-2">
                                <p class="mb-0">Thu, 30 Nov</p>
                                <h4>AED 2000</h4>
                            </div>
                        </div>

                    </div>

                    <div class="customNextBtn border"><i class="fa-solid fa-chevron-right text-black"></i></div>
                    <div class="customPrevBtn border"><i class="fa-solid fa-chevron-left text-black"></i></div>

                    <!-- <div class="cal">
                        <mat-form-field class="example-full-width" style="display: none;">
                            <mat-label>Choose a date</mat-label>
                            <input matInput [matDatepicker]="picker">
                            <mat-hint>MM/DD/YYYY</mat-hint>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                        <button class="calbox" (click)="picker.open()"><i
                                class="fa-solid fa-calendar-days"></i></button>
                    </div> -->
                </div>
            </div>
        </div>
        <div class="container mt-5">
            <div class="row">
                <div class="col-md-12">
                    <div class="bg-white rounded py-2">
                        <div class="row">
                            <div class="col-md-8">
                                <div class="row">
                                    <div class="col-md-3">
                                        <p class="text-center"><b>Sort By:</b></p>
                                    </div>
                                    <div class="col-md-3">
                                        <p class="text-center"><button href="#">DEPART</button></p>
                                    </div>
                                    <div class="col-md-3">
                                        <p class="text-center"><button href="#">ARRIVE</button></p>
                                    </div>
                                    <div class="col-md-3">
                                        <p class="text-center"><button href="#">DURATION</button></p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <p class="text-center"><a routerLink="/" class="text-primary">PRICE PER ADULT <i
                                            class="fa-solid fa-arrow-right-long"></i></a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="container">
            <div class="row mt-5">
                <div class="col-md-12">
                    <div class="bg-white rounded">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="d-flex gap-2 bg-lgpink">
                                    <div class="p-1 px-2 m-1 bg-success rounded">Deal</div>
                                    <p class="py-1">Avail Flat 12% Off on CITI Bank No Cost EMI, Use promo
                                        code -
                                        YRCITIEMI</p>
                                </div>
                            </div>
                        </div>
                        <div class="row p-2 px-5">
                            <div class="col-md-8">
                                <div class="row">
                                    <div class="col-md-3">
                                        <div class="d-flex justify-content-center align-items-center">
                                            <div>
                                                <img src="../../../../assets/img/flight-search/airplane.png" alt=""
                                                    width="50px" />

                                            </div>
                                            <div>
                                                <h6 class="mb-0">Emirates...</h6>
                                                <p class="mb-0">15-560/472</p>

                                            </div>
                                        </div>
                                    </div>
                                    <div class=" col-md-3 slider-con">
                                        <div class="text-center">
                                            <h6 class="mb-0">20:15</h6>
                                            <p class="mb-0">New Delhi</p>

                                        </div>
                                        <div class="slider">
                                            <mat-slider min="200" max="500">
                                                <input value="300" matSliderStartThumb disabled>
                                                <input value="400" matSliderEndThumb disabled>
                                            </mat-slider>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="text-center border-right">

                                            <h6 class="mb-0">04:30</h6>
                                            <p class="mb-0">Mumbai</p>

                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="text-center">

                                            <h6 class="mb-0">8h 15m</h6>
                                            <p class="mb-0">2 Stop(s) <i class="fa-solid fa-circle-exclamation"></i></p>

                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 pr-5">
                                <div class="d-flex justify-content-end align-items-center gap-2">
                                    <h4>AED 500</h4>
                                    <button class="booknow" routerLink="/flight-booking">Book Now</button>
                                </div>
                            </div>
                        </div>
                        <div class="p-2 px-5">
                            <hr>
                        </div>
                        <div class="row p-2 px-5">
                            <div class="col-md-6">
                                <button id="show-details" class="text-primary" (click)="openpassDialog()">Flight Details
                                    <i class="fa-solid fa-chevron-down"></i></button>
                            </div>
                            <div class="col-md-6">
                                <div class="text-end">
                                    <div class="d-flex justify-content-end">
                                        <div class="pr-2">
                                            <div class="border-right p-1 px-2 mr-2">
                                                <p>Emissions: 240 Kg CO2</p>
                                            </div>
                                        </div>
                                        <div class="d-flex">
                                            <div class="border">
                                                <p class="text-black p-1">eCash</p>
                                            </div>
                                            <div class="border bg-warning">
                                                <p class="text-black p-1">AED100</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>

<ng-template #passenger>
    <div id="flight-details" class="container-fluid" style="display: block;">
        <div class="row">
            <div class="text-end">
                <button mat-raised-button class="m-1 btnclose" style="max-width: 100px;" matDialogClose="yes"><i
                        class="fa-solid fa-xmark"></i></button>
            </div>
            <div class="col-md-12 py-2">
                <table class="noBorder">
                    <tr class="head">
                        <th>FARES</th>
                        <th>CABIN BAG</th>
                        <th>CHECK-IN</th>
                        <th>CANCELLATION</th>
                        <th>DATE CHANGE</th>
                        <th>SEAT</th>
                        <th>MEAL</th>
                        <th></th>
                        <th></th>
                        <th></th>

                    </tr>
                    <tr>
                        <td class="text-black">Saver</td>
                        <td>7 Kgs/Adult</td>
                        <td>15 Kgs/Adult</td>
                        <td>Cancellation
                            Fee Starting
                            AED 500</td>
                        <td>Date Change
                            Fee Staring
                            AED 450</td>
                        <td>Chargeable</td>
                        <td>.............</td>
                        <td>
                            <p>AED 700</p> <button class="btn-em">LOCK PRICE</button><button
                                routerLink="/flight-booking" class="btn-fill">BOOK
                                NOW</button>
                        </td>
                        <th></th>
                        <th></th>

                    </tr>
                    <tr>
                        <td colspan="10" style="border-top: 1px solid #000;"></td>
                    </tr>
                    <tr class="bg-warning">
                        <td colspan="8" class="text-start">

                            <p class="text-black">Get Benefits Worth AED 100</p>
                        </td>
                        <td colspan="2">
                            <p><i class="fa-solid fa-shield-halved"></i> Trip Secure</p>
                        </td>
                    </tr>
                    <tr>
                        <td class="text-black">MIMISPECIAL</td>
                        <td>7 Kgs/Adult</td>
                        <td>15 Kgs/Adult</td>
                        <td>Cancellation
                            Fee Starting
                            AED 500</td>
                        <td>Date Change
                            Fee Staring
                            AED 450</td>
                        <td>Chargeable</td>
                        <td>.............</td>
                        <td>
                            <button class="btn-em">LOCK PRICE</button><button routerLink="/flight-booking"
                                class="btn-fill">BOOK
                                NOW</button>
                        </td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr class="bg-gray">
                        <td colspan="8" class="text-start">

                            <p class="text-black"><i class="fa-solid fa-couch"></i> Seat Selection (AED100) <i
                                    class="fa-solid fa-plus"></i> <i class="fa-solid fa-plane-circle-xmark"></i>
                                Cancellation Fee (AED250)</p>
                        </td>

                        <td colspan="2">
                            <p><i class="fa-solid fa-shield-halved"></i> Trip Secure</p>
                        </td>
                    </tr>
                    <tr>
                        <td class="text-black">MIMISPECIAL</td>
                        <td>7 Kgs/Adult</td>
                        <td>15 Kgs/Adult</td>
                        <td>Cancellation
                            Fee Starting
                            AED 500</td>
                        <td>Date Change
                            Fee Staring
                            AED 450</td>
                        <td>Chargeable</td>
                        <td>.............</td>
                        <td>
                            <button class="btn-em">LOCK PRICE</button><button class="btn-fill"
                                routerLink="/flight-booking">BOOK
                                NOW</button>
                        </td>
                        <td></td>
                        <td></td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</ng-template>