<div class="bg-dark">
    <app-navbar-one></app-navbar-one>

    <section class="py-5">
        <div class="container mt-5 pt-5">
            <div class="row">
                <p class="text-white">
                    My Account <i class="fa-solid fa-angle-right"></i> My Profile
                </p>
            </div>
        </div>
    </section>
    <section>
        <div class="container pb-5">
            <div class="row">
                <div class="col-md-12">
                    <div class="bg-white rounded card">
                        <div class="py-4">
                            <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
                                <mat-tab>
                                    <ng-template mat-tab-label>
                                        <img src="../../../../assets/img/bookings/bag.svg" alt="">
                                        UPCOMING
                                    </ng-template>
                                    <div class="container" *ngIf="displayedColumns == null; else havebookings">
                                        <div class="row align-items-center">
                                            <div class="col-md-4">
                                                <div class="text-center">
                                                    <img src="../../../../assets/img/bookings/nobookings.png" alt="">
                                                </div>
                                            </div>
                                            <div class="col-md-8">
                                                <h3 class="text-black">Looks empty, you've no upcoming bookings.</h3>
                                                <p>When you book a trip, you will see your itinerary here.</p>
                                                <button routerLink="/" class="gobooking">PLAN A TRIP</button>
                                            </div>
                                        </div>
                                    </div>

                                    <ng-template #havebookings>
                                        <div class="container">
                                            <div class="row">
                                                <table mat-table class="mat-elevation-z8" [dataSource]="dataSource"
                                                    matSort (matSortChange)="announceSortChange($event)">

                                                    <!--- Note that these columns can be defined in any order.
                                                        The actual rendered columns are set as a property on the row definition" -->

                                                    <!-- Position Column -->
                                                    <ng-container matColumnDef="position">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            No. </th>
                                                        <td mat-cell *matCellDef="let element"> {{element.position}}
                                                        </td>
                                                    </ng-container>

                                                    <!-- Name Column -->
                                                    <ng-container matColumnDef="name">
                                                        <th mat-header-cell *matHeaderCellDef> From </th>
                                                        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                                                    </ng-container>

                                                    <!-- Weight Column -->
                                                    <ng-container matColumnDef="weight">
                                                        <th mat-header-cell *matHeaderCellDef> To </th>
                                                        <td mat-cell *matCellDef="let element"> {{element.weight}} </td>
                                                    </ng-container>

                                                    <!-- Symbol Column -->
                                                    <ng-container matColumnDef="symbol">
                                                        <th mat-header-cell *matHeaderCellDef> Symbol </th>
                                                        <td mat-cell *matCellDef="let element"> {{element.symbol}} </td>
                                                    </ng-container>

                                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                                                </table>


                                            </div>
                                        </div>
                                    </ng-template>
                                </mat-tab>

                                <mat-tab>
                                    <ng-template mat-tab-label>
                                        <img src="../../../../assets/img/bookings/Group 2.svg" alt="">
                                        Cancelled
                                    </ng-template>
                                    <div class="container">
                                        <div class="row align-items-center">
                                            <div class="col-md-4">
                                                <div class="text-center">
                                                    <img src="../../../../assets/img/bookings/nobookings.png" alt="">
                                                </div>
                                            </div>
                                            <div class="col-md-8">
                                                <h3 class="text-black">Looks empty, you've no upcoming bookings.</h3>
                                                <p>When you book a trip, you will see your itinerary here.</p>
                                                <button routerLink="/" class="gobooking">PLAN A TRIP</button>
                                            </div>
                                        </div>
                                    </div>
                                </mat-tab>

                                <mat-tab>
                                    <ng-template mat-tab-label>
                                        <img src="../../../../assets/img/bookings/comp.svg" alt="">
                                        Completed
                                    </ng-template>

                                    <div class="container">
                                        <div class="row align-items-center">
                                            <div class="col-md-4">
                                                <div class="text-center">
                                                    <img src="../../../../assets/img/bookings/nobookings.png" alt="">
                                                </div>
                                            </div>
                                            <div class="col-md-8">
                                                <h3 class="text-black">Looks empty, you've no upcoming bookings.</h3>
                                                <p>When you book a trip, you will see your itinerary here.</p>
                                                <button routerLink="/" class="gobooking">PLAN A TRIP</button>
                                            </div>
                                        </div>
                                    </div>
                                </mat-tab>
                            </mat-tab-group>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

</div>