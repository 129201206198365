import { Component } from '@angular/core';

@Component({
    selector: 'app-hotel-thank-you',
    templateUrl: './hotel-thank-you.component.html',
    styleUrls: ['./hotel-thank-you.component.scss']
})
export class HotelThankYouComponent {
    constructor() {
    }
    public hotel: any = [
        {
            "id": 1,
            "name": "Emirates Grand Hotel Apartments -Near Burj Khalifa",
            "location": "Sheikh Zayed Road",
            "attraction": "3.4 km from Burj Khalifa",
            "amenities": [
                "Swimming Pool",
                "Spa",
                "Nightt Club"
            ],
            "cancel": [
                "Book with ₹0 Payment",
                "Indian food at the property, Free Early Check-in available",
                "Free Cancellation till 24 hrs before check in "
            ],
            "price": "149 AED",
            "gst": "+ AED 1,820 taxes & fees per night",
            "rating": "4.6",
            "overallreview": "Very Good",
            "totalreview": "9,200",
            phone:"+971 542594682",
            ImageURLs: [
                "https://i.travelapi.com/lodging/1000000/30000/21700/21672/faccd376_z.jpg",
                "https://i.travelapi.com/lodging/1000000/30000/21700/21672/93d8a61c_z.jpg",
                "https://i.travelapi.com/lodging/1000000/30000/21700/21672/a94d6c9b_z.jpg",
                "https://i.travelapi.com/lodging/1000000/30000/21700/21672/0b3fa56d_z.jpg",
                "https://i.travelapi.com/lodging/1000000/30000/21700/21672/6ccb7486_z.jpg",
                "https://i.travelapi.com/lodging/1000000/30000/21700/21672/9596601a_z.jpg",
                "https://i.travelapi.com/lodging/1000000/30000/21700/21672/8fb27910_z.jpg",
            ],
        },
    ]
    public booking: any = [
        {
            "bookingname":"Mr. Mahmoud RM",
            "bookingid":"RPQ8264874",
            "date":"31 Dec 2023 - 01 Jan 2024",
            "roomtype":"Premium Club Room",
            "confirmation":"confirmed"

        }
    ]
}
