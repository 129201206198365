import { Component } from '@angular/core';

@Component({
  selector: 'app-flight-thank-you',
  templateUrl: './flight-thank-you.component.html',
  styleUrls: ['./flight-thank-you.component.scss']
})
export class FlightThankYouComponent {

}
