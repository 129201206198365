import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { FormBuilder, ReactiveFormsModule } from "@angular/forms";

@Component({
    selector: "app-checkout",
    templateUrl: "./checkout.component.html",
    styleUrls: ["./checkout.component.scss"],
})
export class CheckoutComponent implements OnInit {
    public form: any;

    constructor(public fb: FormBuilder) {
        this.genrateForm();
    }

    ngOnInit(): void {}

    genrateForm() {
        this.form = this.fb.group({
            emailid: null,
        });
    }

    async addData() {
        // console.log(this.form.value)
        // await this.cat.Addnewsletter();
        this.form.reset();
    }
}
