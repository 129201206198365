import { Component, Renderer2 } from "@angular/core";
import { SharedService } from "../shared/shared.service";
@Component({
    selector: "app-profile",
    templateUrl: "./profile.component.html",
    styleUrls: ["./profile.component.scss"],
})
export class ProfileComponent {
    userdetals: any = [
        {
            NAME: "Amanda Jorden",
            BIRTHDAY: "",
            GENDER: "",
            MARITAL_STATUS: "",
            YOUR_ADDRESS: "",
            PINCODE: "",
            STATE: "",
            img: "/assets/images/user1.jpg",
        },
    ];
    constructor(public shared: SharedService, private renderer: Renderer2) {}
    scrollToId(id: string) {
        console.log("element id : ", id);
        this.shared.scrollToElementById(id);
    }

    public selected = false;

    public sections = 3;

    public scroll;

    // constructor(private renderer: Renderer2) {}

    // public profileheight = document.querySelector("#profile").clientHeight;

    ngOnInit() {
        this.renderer.listen(window, "scroll", ($event) => {
            this.scroll = window.scrollY / this.sections;
            console.log(window.scrollY);
        });
    }

}
