import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { SaasHomeComponent } from "./components/pages/saas-home/saas-home.component";
import { MarketingHomeComponent } from "./components/pages/marketing-home/marketing-home.component";
import { StartupHomeComponent } from "./components/pages/startup-home/startup-home.component";
import { SeoAgencyHomeComponent } from "./components/pages/seo-agency-home/seo-agency-home.component";
import { ContactComponent } from "./components/pages/contact/contact.component";
import { BlogDetailsComponent } from "./components/pages/blog-details/blog-details.component";
import { BlogComponent } from "./components/pages/blog/blog.component";
import { CheckoutComponent } from "./components/pages/checkout/checkout.component";
import { CartComponent } from "./components/pages/cart/cart.component";
import { ProductsDetailsComponent } from "./components/pages/products-details/products-details.component";
import { ProductsListComponent } from "./components/pages/products-list/products-list.component";
import { ComingSoonComponent } from "./components/pages/coming-soon/coming-soon.component";
import { ErrorComponent } from "./components/pages/error/error.component";
import { SignupComponent } from "./components/pages/signup/signup.component";
import { LoginComponent } from "./components/pages/login/login.component";
import { PricingComponent } from "./components/pages/pricing/pricing.component";
import { FaqComponent } from "./components/pages/faq/faq.component";
import { TeamComponent } from "./components/pages/team/team.component";
import { ProjectsComponent } from "./components/pages/projects/projects.component";
import { ProjectsDetailsComponent } from "./components/pages/projects-details/projects-details.component";
import { ServicesComponent } from "./components/pages/services/services.component";
import { ServicesDetailsComponent } from "./components/pages/services-details/services-details.component";
import { AboutComponent } from "./components/pages/about/about.component";
import { HotelComponent } from "./components/pages/hotel/hotel.component";
import { HotelDetailsComponent } from "./components/pages/hotel-details/hotel-details.component";
import { HotelSearchComponent } from "./components/pages/hotel-search/hotel-search.component";
import { HotelBookingFormComponent } from "./components/pages/hotel-booking-form/hotel-booking-form.component";
import { HotelThankYouComponent } from "./components/pages/hotel-thank-you/hotel-thank-you.component";
import { FlightThankYouComponent } from "./components/pages/flight-thank-you/flight-thank-you.component";
import { TopDestinationComponent } from "./components/pages/top-destination/top-destination.component";
import { TopSightComponent } from "./components/pages/top-sight/top-sight.component";
import { TopDestinationDetailsComponent } from './components/pages/top-destination-details/top-destination-details.component';
import { TopSightDetailsComponent } from './components/pages/top-sight-details/top-sight-details.component';
import { OfferComponent } from "./components/pages/offer/offer.component";
import { OffersComponent } from "./components/pages/offers/offers.component";
import { FlightBookingFormComponent } from "./components/pages/flight-booking-form/flight-booking-form.component";
import { FlightFinalGothroughComponent } from "./components/pages/flight-final-gothrough/flight-final-gothrough.component";
import { FlightMealsComponent } from "./components/pages/flight-meals/flight-meals.component";
import { FlightPaymentComponent } from "./components/pages/flight-payment/flight-payment.component";
import { FlightSearchComponent } from "./components/pages/flight-search/flight-search.component";
import { FlightSeatMapComponent } from "./components/pages/flight-seat-map/flight-seat-map.component";
import { ProfileComponent } from "./components/pages/profile/profile.component";
import { BookingsComponent } from "./components/pages/bookings/bookings.component";
const routes: Routes = [
    { path: "", component: MarketingHomeComponent },
    { path: "hotel", component: HotelComponent },
    { path: "hotel-details", component: HotelDetailsComponent },
    { path: "hotel-search", component: HotelSearchComponent },
    { path: "hotel-booking", component: HotelBookingFormComponent },
    { path: "saas-home", component: SaasHomeComponent },
    { path: "startup", component: StartupHomeComponent },
    { path: "seo-agency", component: SeoAgencyHomeComponent },
    { path: "about", component: AboutComponent },
    { path: "services", component: ServicesComponent },
    { path: "services-details", component: ServicesDetailsComponent },
    { path: "projects", component: ProjectsComponent },
    { path: "projects-details", component: ProjectsDetailsComponent },
    { path: "team", component: TeamComponent },
    { path: "faq", component: FaqComponent },
    { path: "pricing", component: PricingComponent },
    { path: "login", component: LoginComponent },
    { path: "signup", component: SignupComponent },
    { path: "error", component: ErrorComponent },
    { path: "coming-soon", component: ComingSoonComponent },
    { path: "products-list", component: ProductsListComponent },
    { path: "products-details", component: ProductsDetailsComponent },
    { path: "cart", component: CartComponent },
    { path: "checkout", component: CheckoutComponent },
    { path: "blog", component: BlogComponent },
    { path: "blog/:id", component: BlogDetailsComponent },
    { path: "contact", component: ContactComponent },
    { path: "hotel-thank-you", component: HotelThankYouComponent },
    { path: "flight-thank-you", component: FlightThankYouComponent },
    { path: "top-destination", component: TopDestinationComponent },
    { path: "top-sight", component: TopSightComponent },
    { path: "places/:id", component: TopDestinationDetailsComponent },
    { path: "sights/:id", component: TopSightDetailsComponent },
    { path: "offer", component: OfferComponent },
    { path: "offers/:id", component: OffersComponent },
    { path: "flight-booking", component: FlightBookingFormComponent },
    { path: "flight-final", component: FlightFinalGothroughComponent },
    { path: "flight-meals", component: FlightMealsComponent },
    { path: "flight-payment", component: FlightPaymentComponent },
    { path: "flight-search", component: FlightSearchComponent },
    { path: "flight-seat-map", component: FlightSeatMapComponent },
    { path: "profile", component: ProfileComponent },
    { path: "bookings", component: BookingsComponent },
    { path: "**", component: ErrorComponent },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule],
})
export class AppRoutingModule {}
