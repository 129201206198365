import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { SharedService } from '../shared/shared.service'
import { ActivatedRoute } from '@angular/router';
import blog from "../../data/blog.json";
@Component({
    selector: 'app-blog-details',
    templateUrl: './blog-details.component.html',
    styleUrls: ['./blog-details.component.scss']
})
export class BlogDetailsComponent implements OnInit {
    public blogdetails: any = blog;
    public id: any =[];
    public blog:any;
    constructor(
        public blogs: SharedService,
        private route: ActivatedRoute
    ) { }

    ngOnInit() {
        const id = this.route.snapshot.paramMap.get('id');
        this.id = id;
        console.log(id)
        this.topplace()
    }

    async topplace() {
        this.blog = this.blogs.blog
        this.blog = this.blog.find(blog => blog.slug == this.id);
        console.log(this.blog)
    }


}
