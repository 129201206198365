import { Component } from "@angular/core";

@Component({
    selector: "app-flight-seat-map",
    templateUrl: "./flight-seat-map.component.html",
    styleUrls: ["./flight-seat-map.component.scss"],
})
export class FlightSeatMapComponent {
    public foods: any = [
        {
            id: "1",
            name: "Vegetarian Meal",
            price: "FREE",
            type: "../../../../assets/img/seat-map/veg.svg",
            icon: "../../../../assets/img/seat-map/food1.svg",
        },
        {
            id: "2",
            name: "Moslem Meal",
            price: "FREE",
            type: "../../../../assets/img/seat-map/non-veg.svg",
            icon: "../../../../assets/img/seat-map/food2.svg",
        },
        {
            id: "3",
            name: "Non-Vegetarian Meal",
            price: "FREE",
            type: "../../../../assets/img/seat-map/non-veg.svg",
            icon: "../../../../assets/img/seat-map/food2.svg",
        },
        {
            id: "4",
            name: "Vegetarian Meal",
            price: "FREE",
            type: "../../../../assets/img/seat-map/veg.svg",
            icon: "../../../../assets/img/seat-map/food1.svg",
        },
    ];
}
