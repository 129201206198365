import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import {
    FormBuilder,
    FormGroup,
    Form,
    Validators,
    FormControl,
    FormArray,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import data from "../../data/banner.json";
import hotel_list from "../../data/hotel-list.json";
import top10 from "../../data/top10-destinations.json";
import blogs from "../../data/blog.json";
import offers from "../../data/offer.json";

@Component({
    selector: "app-hotel",
    templateUrl: "./hotel.component.html",
    styleUrls: ["./hotel.component.scss"],
})
export class HotelComponent implements OnInit {
    totaladult: any;
    rooms: any[] = [];
    adultsOptions: number[] = [1, 2, 3, 4, 5, 6];
    childrenOptions: number[] = [0, 1, 2, 3, 4, 5, 6];
    childAgesOptions: number[] = [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17,
    ];
    public banner = data;
    public blog = blogs;
    public offer = offers;
    // public minDate: Date = new Date();
    minDate: Date;
    maxDate: Date;
    public roomsselect = "1";
    public personsselct = "1";
    public childselect = "0";
    public priceselect = "0";
    public topdestination: any = top10;

    @ViewChild("passenger") passenger: TemplateRef<any>;
    @ViewChild("roomguestopen") roomguestopen: TemplateRef<any>;
    isDisabled = true;
    isAddDisabled = false;
    public dialogRef: any;
    public dialogRef1: any;
    unfilteredDataToSearch: any[] = hotel_list;
    filteredDataToSearch: any[] = [];
    constructor(public dialog: MatDialog, private fb: FormBuilder) {
        // Set the minimum to January 1st 20 years in the past and December 31st a year in the future.
        const currentYear = new Date().getFullYear();
        this.minDate = new Date();
        this.maxDate = new Date(currentYear + 1, 11, 31);

        // this.myForm = this.fb.group({
        //     rooms: this.fb.array([]),
        // });

        // this.setrooms();
    }

    public hotelsearch: FormGroup = new FormGroup({
        hotellocation: new FormControl("", [Validators.required]),
        daterangefrom: new FormControl("", [Validators.required]),
        daterangeto: new FormControl("", [Validators.required]),
        // roomsnumber: new FormControl("1", [Validators.required]),
        // adultsnumber: new FormControl("1", [Validators.required]),
        // childrennumber: new FormControl(""),
        pricerange: new FormControl("0to100"),
        // childage: new FormArray([]),
    });

    // min date user can add be todays date in angular material ?

    ngOnInit() {
        // childno
        this.filteredDataToSearch = this.unfilteredDataToSearch.map((w) => {
            return {
                text:
                    w.teamName +
                    " - " +
                    w.abbreviation +
                    "(" +
                    w.location +
                    ")",
                value: w.teamId,
                location: w.location,
                code: w.abbreviation,
                loc2: w.teamName,
            };
        });
        // this.addData();
        this.addRoom();
        this.submitForm();
    }
    onChildrenChange(index: number) {
        const room = this.rooms[index];

        if (room.childrenAges.length < room.children) {
            while (room.childrenAges.length < room.children) {
                room.childrenAges.push(0);
            }
        } else {
            room.childrenAges = room.childrenAges.slice(0, room.children);
        }
    }

    lookup(e) {
        this.filteredDataToSearch = this.unfilteredDataToSearch
            .filter(
                (i) =>
                    (
                        i.teamName +
                        " - " +
                        i.abbreviation +
                        ">>" +
                        i.location +
                        ")"
                    )
                        .toString()
                        .toLowerCase()
                        .indexOf(e) > -1
            )
            .map((w) => {
                return {
                    text:
                        w.teamName +
                        " - " +
                        w.abbreviation +
                        ">>" +
                        w.location +
                        ")",
                    value: w.teamId,
                };
            });
    }

    clean(t) {
        t.value = "";
        this.lookup(t.value);
    }

    openpassDialog() {
        this.dialogRef = this.dialog.open(this.passenger, {
            width: "50%",
            disableClose: true,
        });
    }
    roomguest() {
        this.dialogRef1 = this.dialog.open(this.roomguestopen, {
            width: "60%",
            disableClose: true,
        });
    }
    disableInputField() {
        (document.getElementById("returndate") as HTMLButtonElement).disabled =
            false;
    }
    enableinput() {
        (document.getElementById("returndate") as HTMLButtonElement).disabled =
            true;
    }
    async addData() {
        // this.nochild1 = +this.childselect;
        // this.nochild = JSON.parse("[" + this.nochild + "]");
        // this.nochild = Array(+this.childselect).fill(0);
        // this.nochild.fill(0);
        // this.childage = new Array(+this.childselect);
        // const control = new FormControl([Validators.required]);
        // (<FormArray>this.hotelsearch.get("childage")).push(control);
        // this.childselect = this.hotelsearch.value.childage.length;

        console.log(
            // typeof this.nochild1,
            // this.nochild,
            // this.hotelsearch
            this.hotelsearch.value
            // console.log(this.minDate)

            // control
        );
    }

    addRoom() {
        if (this.rooms.length < 6) {
            // const newRoom = { adults: 1, children: 0, childrenAges: [] };
            // this.rooms.push(newRoom);
            // this.rooms = [...this.rooms, newRoom];
            this.rooms.push({ adults: 1, children: 0, childrenAges: [] });
        } else {
            alert("You can't add more than six rooms.");
        }
    }

    removeRoom(index: number) {
        this.rooms.splice(index, 1);
    }

    submitForm() {
        // Implement your form submission logic here
        this.hotelsearch.patchValue({
            // childrennumber: this.childselect,
            roomnumber: this.rooms.length,
        });
        var values = Object.values(this.rooms);
        console.log("Form submitted:", values.length);
        for (const element of values) {
            // values.push(...element.values());
            var totaladult = +element.adults;
            for (const child of element.childrenAges) {
                console.log(child);
            }
        }
        console.log(totaladult);
    }
}

// addchild() {
//     const control = new FormControl(null, [Validators.required]);
//     if ((<FormArray>this.hotelsearch.get("childage")).length < 6) {
//         (<FormArray>this.hotelsearch.get("childage")).push(control);
//         this.isDisabled = false;
//     } else {
//
//         this.isAddDisabled = true;
//     }
// }
// removechild() {
//     // const control = new FormControl(null, [Validators.required]);
//     // (<FormArray>this.hotelsearch.get("childage")).push(control);
//     if ((<FormArray>this.hotelsearch.get("childage")).length > 0) {
//         (<FormArray>this.hotelsearch.get("childage")).removeAt(-1);
//         this.isAddDisabled = false;
//     } else {
//         // alert("Please enter at least one child age.");
//         // disable the remove child button?
//         this.isDisabled = true;
//     }
// }
// addroom() {
//     const control12 = new FormControl(null, [Validators.required]);
//     if ((<FormArray>this.hotelsearch.get("rooms")).length < 6) {
//         (<FormArray>this.hotelsearch.get("rooms")).push(control12);
//         this.isDisabled = false;
//     } else {
//
//         this.isAddDisabled = true;
//     }
// }
// removeroom() {
//     // const control = new FormControl(null, [Validators.required]);
//     // (<FormArray>this.hotelsearch.get("childage")).push(control);
//     if ((<FormArray>this.hotelsearch.get("rooms")).length > 0) {
//         (<FormArray>this.hotelsearch.get("rooms")).removeAt(-1);
//         this.isAddDisabled = false;
//     } else {
//         // alert("Please enter at least one child age.");
//         // disable the remove child button?
//         this.isDisabled = true;
//     }
// }

// if addchild is more than 6 disable addchild btn?

// get childControls() {
//     return (<FormArray>this.hotelsearch.get("childage")).controls;
// }
// get roomControls() {
//     return (<FormArray>this.hotelsearch.get("rooms")).controls;
//     // return (<FormArray>this.myForm.get("rooms")).controls;
// }

// addNewRoom() {
//     let control = <FormArray>this.myForm.controls.rooms;
//     control.push(
//         this.fb.group({
//             adults: [""],
//             child: [""],
//             childage: this.fb.array([]),
//         })
//     );
// }

// deleteRoom() {
//     let control = <FormArray>this.myForm.controls.rooms;
//     control.removeAt(-1);
// }

// addNewChild(control) {
//     control.push(
//         this.fb.group({
//             childage: [""],
//         })
//     );
// }

// deleteProject(control, index) {
//     control.removeAt(index);
// }

// setrooms() {
//     let control = <FormArray>this.myForm.controls.rooms;
//     this.data.rooms.forEach((x) => {
//         control.push(
//             this.fb.group({
//                 adults: x.adults,
//                 // child: x.child,
//                 childage: this.setProjects(x),
//             })
//         );
//     });
// this.data.rooms.forEach((z) => {
//     control.push(
//         this.fb.group({
//             // adults: z.adults,
//             // child: z.child,
//             childage: this.setProjects(z),
//         })
//     );
// });
// }

// setProjects(x) {
//     let arr = new FormArray([]);
//     x.childage.forEach((y) => {
//         arr.push(
//             this.fb.group({
//                 childage: y.childage,
//             })
//         );
//     });
//     return arr;
// }
