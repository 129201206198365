import { HttpClient } from "@angular/common/http";
import { AfterViewInit, Component, ViewChild } from "@angular/core";
import { MatSort, Sort, MatSortModule } from "@angular/material/sort";
import { MatTableDataSource, MatTableModule } from "@angular/material/table";
import { LiveAnnouncer } from "@angular/cdk/a11y";
export interface PeriodicElement {
    name: string;
    position: number;
    weight: string;
    symbol: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
    {
        position: 1,
        name: "New Delhi",
        weight: "New Delhi",
        symbol: "12/11/2023",
    },
    { position: 2, name: "Mumbai", weight: "New Delhi", symbol: "12/11/2023" },
    { position: 3, name: "New Delhi", weight: "Mumbai", symbol: "12/11/2023" },
    {
        position: 4,
        name: "New Delhi",
        weight: "New Delhi",
        symbol: "12/11/2023",
    },
    { position: 5, name: "Mumbai", weight: "New Delhi", symbol: "12/11/2023" },
    { position: 6, name: "New Delhi", weight: "Mumbai", symbol: "12/11/2023" },
    { position: 7, name: "Mumbai", weight: "New Delhi", symbol: "12/11/2023" },
    { position: 8, name: "New Delhi", weight: "Mumbai", symbol: "12/11/2023" },
    { position: 9, name: "Mumbai", weight: "New Delhi", symbol: "12/11/2023" },
    { position: 10, name: "New Delhi", weight: "Mumbai", symbol: "12/11/2023" },
];

@Component({
    selector: "app-bookings",
    templateUrl: "./bookings.component.html",
    styleUrls: ["./bookings.component.scss"],
})
export class BookingsComponent implements AfterViewInit {
    displayedColumns: string[] = ["position", "name", "weight", "symbol"];
    // public dataSource = ELEMENT_DATA;
    public dataSource = new MatTableDataSource(ELEMENT_DATA);
    @ViewChild(MatSort) sort: MatSort;
    constructor(private _liveAnnouncer: LiveAnnouncer) {}

    ngAfterViewInit() {
        this.dataSource.sort = this.sort;
    }

    announceSortChange(sortState: Sort) {
        // This example uses English messages. If your application supports
        // multiple language, you would internationalize these strings.
        // Furthermore, you can customize the message to add additional
        // details about the values being sorted.
        if (sortState.direction) {
            this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
        } else {
            this._liveAnnouncer.announce("Sorting cleared");
        }
    }
}
