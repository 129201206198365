<div class="navbar-area">
    <div class="exto-responsive-nav">
        <div class="container">
            <div class="exto-responsive-menu">
                <div class="logo">
                    <a routerLink="/" id="headerlogo">
                        <img src="assets/img/logo/logo.svg" alt="logo" />
                    </a>
                </div>
            </div>
        </div>
    </div>

    <div class="exto-nav">
        <div class="container">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" routerLink="/" id="headerlogodesk">
                    <img src="assets/img/logo/logo-2.svg" alt="logo" />
                </a>

                <div class="collapse navbar-collapse mean-menu">
                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <a routerLink="/" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }">Home
                            </a>
                        </li>
                        <li class="nav-item">
                            <a routerLink="/about" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }">About Us
                            </a>
                        </li>
                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link">Explore <i class="fa fa-angle-down"></i></a>

                            <ul class="dropdown-menu">
                                <li class="nav-item">
                                    <a routerLink="/top-destination" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                                exact: true
                                            }">Top Destination</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/top-sight" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                                exact: true
                                            }">Top Sight</a>
                                </li>
                            </ul>
                        </li>
                        <li class="nav-item">
                            <a routerLink="/blog" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }">Blog</a>
                        </li>
                        <li class="nav-item">
                            <a routerLink="/contact" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }">Contact</a>
                        </li>
                    </ul>

                    <div class="others-options">
                        <a class="saas-nav-btn" routerLink="/login">Login</a>
                        <div class="language-list">
                            <div class="select-box">
                                <select>
                                    <option>AED</option>
                                    <option>USA</option>
                                    <option>GBP</option>
                                </select>
                            </div>
                        </div>
                        <div class="sidebar-menu" data-bs-toggle="modal" data-bs-target="#myModal2">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    </div>
</div>