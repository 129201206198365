<footer class="footer-section">
    <div class="container-fluid footer_top px-0">
        <div class="container">
            <div class="row">
                <!-- <div class="col-lg-3 col-md-6 col-sm-6">
                        <div class="footer-area-content">
                            <img src="assets/img/logo/logo.png" alt="image">
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing. Asperiores soluta est eum corporis laborum
                                aliquid, harum vero! Assumenda sit exercitationem in sapiente optio illo non autem
                                exercitationem</p>
                        </div>
                    </div> -->

                <div class="col-md-7">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="single-footer-widget">
                                <ul class="footer-quick-links">
                                    <li><a routerLink="/">Support Center</a></li>
                                    <li><a routerLink="/">Popular Campaign</a></li>
                                    <li><a routerLink="/">Customer Support</a></li>
                                    <li><a routerLink="/">Copyright</a></li>
                                    <li><a routerLink="/">Latest News</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="single-footer-widget">
                                <ul class="footer-quick-links">
                                    <li><a routerLink="/">Speaker requests</a></li>
                                    <li><a routerLink="/">Images and b-roll</a></li>
                                    <li><a routerLink="/">Social media directories</a></li>
                                    <li><a routerLink="/">Permissions</a></li>
                                    <li><a routerLink="/">Press inquiries</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-5">
                    <div class="single-footer-widget">
                        <h3>Sign up for Newsletter</h3>
                        <div>
                            <form class="example-form">
                                <div class="group">
                                    <input required="" type="email" class="input">
                                    <span class="highlight"></span>
                                    <span class="bar"></span>
                                    <label>Enter you mail here</label><button name="submit" type="submit">
                                        <i class="fa-solid fa-arrow-right-long"></i></button>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid footer_bottom">
        <div class="container">
            <div class="copyright-area">
                <div class="row align-items-center row-rev">
                    <div class="col-lg-6 col-md-6">
                        <ul class="social-list">
                            <li><a href="#" class="facebook" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                            <li><a href="#" class="twitter" target="_blank"><i class="fab fa-twitter"></i></a></li>
                            <li><a href="#" class="linkedin" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                            <li><a href="#" class="instagram" target="_blank"><i class="fab fa-instagram"></i></a></li>
                        </ul>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <h4>©Gulf Travel {{ anio }}. All rights reserved.
                        </h4>
                        <ul>
                            <li><a routerLink="/">ToS</a></li>
                            <li><a routerLink="/">Legal Disclaimer</a></li>
                            <li><a routerLink="/">Privacy Policy</a></li>
                            <li><a routerLink="/">User Agreement</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
<div>

</div>

<div class="go-top">
    <i class="fas fa-chevron-up"></i>
    <i class="fas fa-chevron-up"></i>
</div>

<div class="sidebar-modal">
    <div class="modal right fade" id="myModal2" tabindex="-1" role="dialog" aria-labelledby="myModalLabel2">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">
                            <i class="fa fa-times"></i>
                        </span>
                    </button>

                    <h2 class="modal-title" id="myModalLabel2">
                        <a routerLink="/">
                            <img src="assets/img/logo/logo.png" alt="Logo">
                        </a>
                    </h2>
                </div>

                <div class="modal-body">
                    <div class="sidebar-modal-widget">
                        <h3 class="title">Welcome To Gulf</h3>
                        <img src="assets/img/about.jpg" alt="">
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo dolore saepe exercitationem
                            alias unde, blanditiis non error</p>
                    </div>

                    <div class="sidebar-modal-widget">
                        <h3 class="title">Contact Info</h3>

                        <ul class="contact-info">
                            <li>
                                <i class="fa fa-map-marker"></i>
                                Address
                                <span>Lorem ipsum dolor sit amet.</span>
                            </li>

                            <li>
                                <i class="fa fa-phone"></i>
                                Email
                                <span>info@gulf.com</span>
                            </li>

                            <li>
                                <i class="fa fa-envelope"></i>
                                Phone
                                <span>+12345678990</span>
                            </li>
                        </ul>
                    </div>

                    <div class="sidebar-modal-widget">
                        <h3 class="title">Connect With Us</h3>

                        <ul class="social-list">
                            <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                            <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                            <li><a href="#" target="_blank"><i class="fab fa-instagram"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>