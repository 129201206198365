<!-- <app-navbar-three></app-navbar-three> -->
<section class="bg-dark">
    <div class="container">
        <div class="topbar">
            <div class="row my-3">
                <div class="col-6">
                    <h4 class="prifont">Complete your booking</h4>
                </div>
                <div class="col-6 m-auto">
                    <div class="row">
                        <div class="col-3 text-end">
                            <h6 class="prifont">Flight Summary</h6>
                        </div>
                        <div class="col-3 text-end">
                            <h6 class="prifont">Travel Details</h6>
                        </div>
                        <div class="col-3 text-end">
                            <h6 class="prifont">Seats & Meals</h6>
                        </div>
                        <div class="col-3 text-end">
                            <h6 class="prifont">Final Confirmation</h6>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>

</section>
<section class="pri-bg py-5">
    <div class="container" *ngFor="let flight of flight">
        <div class="card">
            <div class="container my-2">
                <div class="row detail m-auto">
                    <div class="col-8">
                        <h4 class="mt-3 mb-3"><span>{{flight.to}} </span><i class="fa fa-long-arrow-right"
                                aria-hidden="true"></i><span>
                                {{flight.from}}</span></h4>
                        <p><span class="boxx mx-1 font-sm">{{flight.date}}</span><span class="font-sm">{{flight.stop}} -
                                {{flight.duration}}</span></p>
                        <p><span class="mx-1 font-sm"><b>{{flight.flight}}</b></span><span
                                class="font-sm">{{flight.flightno}}</span></p>
                    </div>
                    <div class="col-4 text-endd mt-3 mb-3">
                        <div class="box">
                            <p class="button">Cancellation fees apply</p>
                            <p>View Fare Rules</p>
                            <p>{{flight.offer}} > <span class="highlight"><b>{{flight.value}} </b> <i
                                        class="fa fa-circle-info"></i></span>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="row mx-4 content-card">
                    <div class="col-4">
                        <h6>{{flight.arrival}}</h6>
                        <h6>{{flight.arrivalair}}</h6>
                    </div>
                    <div class="col-4">
                        <p>{{flight.duration}}</p>
                        <i class="fa-solid fa-arrow-right"></i>
                    </div>
                    <div class="col-4">
                        <h6>{{flight.departure}}</h6>
                        <h6>{{flight.departureaircode}} - {{flight.departureair}}</h6>
                    </div>
                    <hr />
                    <div class="col-6 text-right">
                        <span><img src="../../../../assets/img/luggage.png"> Cabin Baggage: 7 Kgs (1 piece only) /
                            Adult</span>
                    </div>
                    <div class="col-6 text-left">
                        <span><img src="../../../../assets/img/luggage.png"> Cabin Baggage: 7 Kgs (1 piece only) /
                            Adult</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="card my-4 py-3 px-4">
            <h4>Fare Summary</h4>
            <div class="row detail mx-3">
                <div class="col-8">
                    <span><i class="fa-regular fa-circle"></i> Base Fare</span><br />
                    <span>Adults(s) (1x AED1,000)</span>
                </div>
                <div class="col-4 text-end">
                    <span><b>AED 1000</b></span>
                </div>
                <hr />
                <div class="col-8">

                    <span><i class="fa-regular fa-circle"></i> Taxes & Surcharges</span><br />
                    <span>Airline Taxes & Surcharges</span>
                </div>
                <div class="col-4 text-end">

                    <span><b>AED 500</b></span>

                </div>
            </div>
            <div class="row content-card">
                <div class="col-8">
                    <span><b>Total</b></span>
                </div>
                <div class="col-4 text-end">
                    <span><b>AED 1500</b></span>
                </div>
            </div>
        </div>

        <div class="card my-4 py-3 px-4">
            <h4>Important Information</h4>
            <div class="row detail mx-3">
                <div class="col-12">
                    <p>Check travel guidelines and baggage information below:</p>
                    <p class="m-0">Carry no more than 1 check-in baggage and 1 hand baggage per passenger. If Violated,
                        airlines may levy extra charges</p>
                    <p class="m-0">Wearing a mask/face cover is no longer mandatory. However, all travellers are advised
                        to do so as a precautionary measure.</p>
                </div>
            </div>
        </div>
        <div class="card my-4 py-3 px-4">
            <h4>Traveller Details</h4>
            <div class="row mx-3" *ngFor="let user of users">
                <div class="col-12">
                    <span><i class="fa-solid fa-user"></i>
                        <p>Adult ({{user.age}}yrs+)</p>
                    </span>
                </div>
            </div>
            <div class="container-fluid pb-3">
                <mat-card fxLayout="column" class="flex-p" style="background-color: transparent;"
                    fxLayout.gt-sm="row wrap">
                    <form [formGroup]="form" style="width: 100%; display: flex;">
                        <div class="row">
                            <div class="col-2">

                                <mat-form-field class="example-full-width flight-booking">
                                    <mat-icon matPrefix color="primary">people</mat-icon>
                                    <mat-label>Select</mat-label>
                                    <mat-select>
                                        <mat-option value="one">Mr.</mat-option>
                                        <mat-option value="two">Mrs.</mat-option>
                                        <mat-option value="three">Miss.</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-4">
                                <mat-form-field class="example-full-width flight-booking">
                                    <mat-icon matPrefix color="primary">person</mat-icon>
                                    <mat-label>First Name</mat-label>
                                    <input matInput placeholder="" value="">
                                </mat-form-field>
                            </div>
                            <div class="col-3">
                                <mat-form-field class="example-full-width flight-booking">
                                    <mat-icon matPrefix color="primary">person</mat-icon>
                                    <mat-label>Middle Name</mat-label>
                                    <input matInput placeholder="" value="">
                                </mat-form-field>
                            </div>
                            <div class="col-3">
                                <mat-form-field class="example-full-width flight-booking">
                                    <mat-icon matPrefix color="primary">person</mat-icon>
                                    <mat-label>Sur Name</mat-label>
                                    <input matInput placeholder="" value="">
                                </mat-form-field>
                            </div>
                            <div class="col-4">
                                <mat-form-field class="example-full-width flight-booking">
                                    <mat-icon matPrefix color="primary">email</mat-icon>
                                    <mat-label>Email Address</mat-label>
                                    <input matInput placeholder="" value="">
                                </mat-form-field>
                            </div>
                            <div class="col-4">
                                <mat-form-field class="example-full-width flight-booking">
                                    <mat-icon matPrefix color="primary">phone</mat-icon>
                                    <mat-label>Phone Number</mat-label>
                                    <input matInput placeholder="" value="">
                                </mat-form-field>
                            </div>
                            <div class="col-4">
                                <mat-form-field class="example-full-width flight-booking">
                                    <mat-icon matPrefix color="primary">contacts</mat-icon>
                                    <mat-label>Alt.Phone Number</mat-label>
                                    <input matInput placeholder="" value="">
                                </mat-form-field>
                            </div>
                        </div>
                    </form>
                </mat-card>

            </div>
        </div>

        <mat-dialog-actions align="end">
            <button class="my-2 saas-btn" color="primary" routerLink="/flight-seat-map"
                (click)="addData()">Continue</button>
            <!-- <button class="my-2 saas-btn" color="primary" routerLink="/flight-seat-map" (click)="addData()"
                [disabled]="!form.valid || form.pristine"><span *ngIf='!form.value.ecCategoryId'>Continue</span><span
                    *ngIf='form.value.ecCategoryId'>Update</span></button> -->
        </mat-dialog-actions>

        <div class="card my-4 py-3 px-4">
            <h3>Seats & Meals</h3>
        </div>

        <div class="card my-4 py-3 px-4">
            <h3>Payment</h3>
        </div>
    </div>
</section>