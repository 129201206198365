import { Component,OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { SharedService } from '../shared/shared.service'
import { ActivatedRoute } from '@angular/router';
import Swal from "sweetalert2";
import offer from "../../data/offer.json";
@Component({
    selector: "app-offer",
    templateUrl: "./offer.component.html",
    styleUrls: ["./offer.component.scss"],
})
export class OfferComponent {
    public topsight: any = offer;
    public place: any;
    public id: any =[];
    constructor(
        public details: SharedService,
        private route: ActivatedRoute
    ){

    }

    ngOnInit() {
        const id = this.route.snapshot.paramMap.get('id');
        this.id = id;
        console.log(id)
        this.offers()
    }

    async offers() {
        this.place = this.details.offer
        this.place = this.place.find(place => place.slug == this.id);
        console.log(this.place)
    }
    // copyInputMessage() {
    //     Swal.fire("Hello world!");
    //     // console.log(inputElement);
    //     // inputElement.select();
    //     document.execCommand("copy");
    //     // inputElement.setSelectionRange(0, 0);
    // }
}
