<div class="body" *ngFor="let hotel of hotel_details">
    <app-navbar-one></app-navbar-one>
    <section class="hotel-details">
        <form action="">
            <div class="container">
                <div class="row">
                    <h1 class="text-white pb-2 text">Review your Booking</h1>
                    <div class="col-md-8 pt-1 p-1">
                        <div class="card bg-white ronded-sm">
                            <div class="p-3 px-5">
                                <h3 class="text-black">{{hotel.HotelInfo.HotelName}}</h3>
                                <i class="fa-solid fa-star"></i>
                                <i class="fa-solid fa-star"></i>
                                <i class="fa-solid fa-star"></i>
                                <i class="fa-solid fa-star"></i>
                                <i class="fa-solid fa-star"></i>

                                <p>{{hotel.HotelInfo.HotelAddress}}</p>

                            </div>
                            <form>

                            </form>
                            <div *ngFor="let req of request">
                                <div class="row">
                                    <div class="col-md-8">
                                        <div class="p-3 px-5 bg-gray">

                                            <!-- <mat-label>Enter a date range</mat-label> -->
                                            <div class="d-flex startend">
                                                <div>
                                                    <sub>Check-In</sub>
                                                    <input placeholder="Start date" value="{{req.CheckIn}}" disabled>
                                                </div>
                                                <div>
                                                    <sub>Check-In</sub>
                                                    <input placeholder="End date" disabled value="{{req.CheckOut}}">
                                                </div>
                                            </div>



                                        </div>
                                    </div>
                                    <div class="col-md-4 startend">
                                        <div class="p-3 pr-5 bg-gray">

                                            <div>
                                                <sub>Other</sub>
                                                <input value="{{'Adults ' + req.PaxRooms[0].Adults }}" disabled>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                                <div>

                                </div>
                            </div>
                            <div *ngFor="let room of hotel_room">
                                <div class="p-3 px-5">

                                    <h5>{{room.Name[0]}}</h5>
                                    <p>{{'Adult 0'}}</p>
                                    <div class="row align-items-end">
                                        <div class="col-md-8">
                                            <ul>
                                                <li>Room Only</li>
                                                <li>No meals included</li>
                                                <li>Free Welcome Drink on Arrival</li>
                                                <li><span class="text-danger">Non-Refundable</span> | On Cancellation,
                                                    You
                                                    will not
                                                    get any refund</li>
                                            </ul>
                                        </div>
                                        <div class="col-md-4">
                                            <a routerLink="#" class="text-primary">Cancellation policy details</a>
                                        </div>
                                    </div>
                                    <h5>
                                        Preferences
                                    </h5>
                                    <mat-radio-group aria-label="Select an option" formControlName="food">
                                        <mat-radio-button value="1">Add Breakfast for AED 99 for all
                                            guests</mat-radio-button>
                                        <mat-radio-button value="2">Add Breakfast + Lunch/Dinner for AED 299 for all
                                            guests</mat-radio-button>
                                    </mat-radio-group>
                                    <p class="pt-3 mb-0">(Bed preferences aren’t guaranteed)</p>
                                    <mat-radio-group aria-label="Select an option" formControlName="bed">
                                        <mat-radio-button value="1">1 King Bed</mat-radio-button>
                                        <mat-radio-button value="2">2 Twin Bed</mat-radio-button>
                                    </mat-radio-group>
                                </div>
                                <div class="bg-gray rounded2">
                                    <div class="p-3 px-5">
                                        <h5 class="color-main">Important information</h5>
                                        <ul>
                                            <li>Passport, Aadhar, Driving License and Govt. ID are accepted as ID
                                                proof(s)
                                            </li>
                                            <li>Pets are not allowed.</li>
                                            <li>Smoking within the premises is not allowed</li>
                                            <li>Unmarried couples allowed</li>
                                        </ul>
                                    </div>
                                </div>


                            </div>
                        </div>
                        <div class="my-2 card bg-white">
                            <div class="p-3 px-5">
                                <h5 class="text-black">Guest Details</h5>
                                <div *ngFor="let child of childControls; index as i">
                                    <div class="d-flex gap-4 py-3">
                                        <div class="select-box flex-grow-0">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Title</mat-label>
                                                <mat-select>
                                                    <mat-option value="Mr">Mr</mat-option>
                                                    <mat-option value="Mrs">Mrs</mat-option>
                                                    <mat-option value="Ms">Ms</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div class="flex-grow-1">
                                            <mat-form-field class="w-100" appearance="fill" appearance="outline">
                                                <mat-label>First Name</mat-label>
                                                <input matInput placeholder="First Name">
                                            </mat-form-field>
                                        </div>
                                        <div class="flex-grow-1">
                                            <mat-form-field class="w-100" appearance="fill" appearance="outline">
                                                <mat-label>Last Name</mat-label>
                                                <input matInput placeholder="Last Name">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="d-flex gap-4 py-3 ">
                                        <div>
                                            <div class="select-box flex-grow-1">
                                                <label for="">EMAIL ADDRESS (Booking voucher will be sent to this email
                                                    ID)</label>
                                                <!-- <input type="email" placeholder="Email ID"> -->
                                                <mat-form-field class="example-full-width w-100" appearance="outline">
                                                    <mat-label>Email</mat-label>
                                                    <input type="email" matInput formControlName="emailFormControl">
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div>
                                            <label for="">MOBILE NUMBER</label>
                                            <div>
                                                <ngx-intl-tel-input [cssClass]="'custom'"
                                                    [preferredCountries]="preferredCountries"
                                                    [enableAutoCountrySelect]="true" [enablePlaceholder]="true"
                                                    [searchCountryFlag]="true"
                                                    [searchCountryField]="[ SearchCountryField.All, SearchCountryField.Name]"
                                                    [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.India"
                                                    [maxLength]="15" [phoneValidation]="true" [separateDialCode]="true"
                                                    [numberFormat]="PhoneNumberFormat.National" name="phone">
                                                </ngx-intl-tel-input>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="d-flex gap-4 py-3">
                                    <mat-radio-group aria-label="Select an option">
                                        <mat-radio-button value="1">Enter GST Details (Optional)</mat-radio-button>

                                    </mat-radio-group>

                                </div>
                                <div class="d-flex gap-4 py-1">

                                    <button (click)="addchild()" [disabled]="isAddDisabled"
                                        class="text-primary addguest">+ Add
                                        Guest</button>
                                </div>
                            </div>
                            <div class="bg-gray rounded2">
                                <div class="p-3 px-5">
                                    <div class="d-flex justify-content-between">
                                        <div>
                                            <p>Login to prefill traveller details and get access to secret deals</p>
                                        </div>
                                        <div><a href="/login" class="btn btn-light">Login</a></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="my-2 card bg-white">
                            <div class="p-3 px-5">
                                <div class="d-flex justify-content-between">
                                    <div>
                                        <h5 class="text-black">Guest Details</h5>
                                        <p class="text-danger">
                                            Special requests are subject to each hotel's availability, may be chargeable
                                            &
                                            can't be guaranteed.
                                        </p>
                                    </div>
                                    <div>
                                        <i class="fa-solid fa-angle-down"></i>
                                    </div>
                                </div>
                                <div class="py-3">
                                    <!-- <h6 class="text-black">Any other request?</h6> -->

                                    <!-- <textarea placeholder="Enter your special request"></textarea> -->
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label>Enter your special request</mat-label>
                                        <textarea matInput></textarea>
                                    </mat-form-field>
                                </div>
                            </div>

                        </div>
                        <div class="my-2 tnc">
                            <p>
                                By proceeding, I agree to MakeMyTrip’s <a href="#">User Agreement</a>, <a href="#">Terms
                                    of
                                    Service</a> and <a href="#">Cancellation</a> & Property Booking Policies.

                            </p>
                        </div>
                        <div class="pynw-btn mb-5 mt-4">
                            <a routerLink="/hotel-thank-you">Pay now</a>
                        </div>
                    </div>
                    <div class="col-md-4 p-1">
                        <div class="bg-white rounded mb-1">
                            <div class="p-3">
                                <h6 class="text-black">Price Breakup</h6>
                                <div class="d-flex justify-content-between">
                                    <div>
                                        <p class="mb-0 text-black">1 Room x 1 Night</p>
                                        <p>Base Price</p>
                                    </div>
                                    <div>
                                        <p class="mb-0 text-black">
                                            AED 54,348
                                        </p>
                                    </div>
                                </div>
                                <hr class="hr">
                                <div class="d-flex justify-content-between">
                                    <div>
                                        <p class="mb-0 text-black">Hotel Taxes</p>

                                    </div>
                                    <div>
                                        <p class="mb-0 text-black">
                                            AED 7,739
                                        </p>
                                    </div>
                                </div>
                                <hr class="hr">
                                <div class="d-flex justify-content-between">
                                    <div>
                                        <mat-radio-group aria-label="Select an option">
                                            <mat-radio-button value="1">Donate ₹5 to build resilient travel
                                                destinations. T&Cs
                                            </mat-radio-button>

                                        </mat-radio-group>
                                    </div>
                                    <div>
                                        <p class="mb-0 text-black">
                                            AED 0
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="bg-gray p-3 rounded2">
                                <div class="d-flex justify-content-between">
                                    <div>
                                        <p class="mb-0 text-black">Total Amount to be paid</p>
                                    </div>
                                    <div>
                                        <p class="mb-0 text-black">AED 61,743</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="bg-white rounded mt-2">
                            <div class="p-3">
                                <h6 class="text-black">Coupon Codes</h6>
                                <sub>No coupon codes applicable for this property.</sub>
                                <div class="cou-cont">
                                    <!-- <input type="text" name="coupon" id=""> -->
                                    <mat-form-field class="example-full-width w-100" appearance="outline">
                                        <mat-label>Coupon Codes</mat-label>
                                        <input matInput placeholder="Coupon Codes">
                                    </mat-form-field>
                                    <button class="coupon"><i class="fa-solid fa-arrow-right-long"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </section>
</div>