<app-navbar-one></app-navbar-one>
<div >
    <section class="co-operative-section">
        <div class="container">
            <div class="co-operative-content" style="background: url({{place.img}});background-size: cover">
                <!-- <img src="{{places.img}}"> -->
                <!-- <p>{{place.id}}</p> -->
            </div>
        </div>
    </section>
    <section class="counter-area bg-color">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-md-6 col-sm-6">
                    <div class="single-fun-facts">
                        <!-- <h3><span class="odometer" data-count="5890">00</span><span class="sign-icon">+</span></h3> -->
                        <h1 class="text-uppercase">{{place.place}}  {{place.title}}</h1>
                        <p class="px-5 py-3 mx-5">{{place.place_desc}}</p>
                    </div>
                    <div class="col-lg-12 col-md-6 col-sm-6">
                        <div class="needs-area-content px-5 py-3 mx-5 ">
                            <h3>{{place.top_title}}</h3>
                            <div class="needs-text" *ngFor="let reason of place.top_content">
                                <p><b>{{reason.title}}</b> : {{reason.description}}</p>
                            </div>

                            <div class="must-see py-5 mx-5" *ngFor="let reason of place.must_see">
                                <span style="align-items: baseline;display: flex;"><i class="fa-solid fa-mountain-sun"></i>  <h4 class="mx-3">  Must-See : <b style="font-weight: 700;">{{reason.place}}</b></h4></span>
                            </div>

                            <h3>{{place.get_around_title}}</h3>
                            <div class="needs-text" *ngFor="let reason of place.get_around_content">
                                <p><b>{{reason.title}}</b> : {{reason.description}}</p>
                            </div>
                            <div class="more_details">                                
                                <p class="py-3">{{place.more_details}}</p>
                            </div>

                            <div class="needs-btn">
                                <a routerLink="/" class="needs-btn-one">Discover More</a>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </section>
</div>
