<app-navbar-three></app-navbar-three>
<section class="hotel-thank">
    <div class="container my-5 p-5" *ngFor="let hotel of hotel">
        <div class="row" *ngFor="let book of booking">
            <div class="col-12 hotel-thank-body">
                <h3 class="headline">Thank You</h3>
                <p class="tagline">Your booking is confirmed</p>
            </div>
            <div class="col-lg-12 col-sm-12">
                <div class="hotelcard row border-5 mt-3 mb-3 p-3 ">
                    <div class="col-4">
                        <div class="row">
                            <div class="col-6">
                                <p>Booking name:</p>
                                <p>Booking no:</p>
                            </div>
                            <div class="col-6">
                                <p class="result">{{book.bookingname}}</p>
                                <p class="result">{{book.bookingid}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-4 borderl-2">
                        <div class="row">
                            <div class="col-4">
                                <p>Date:</p>
                                <p>Room Type:</p>
                            </div>
                            <div class="col-8">
                                <p class="result">{{book.date}}</p>
                                <p class="result">{{book.roomtype}}</p>

                            </div>
                        </div>
                    </div>
                    <div class="col-4 borderl-2">
                        <div class="row text-center">
                            <p>Booking Status:</p>
                            <p class="confirm">The booking is {{book.confirmation}}.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 col-sm-12">

                <div class="hotelcard row border-5 mt-3 mb-3">
                    <div class="row">
                        <div class="col-8 m-auto">
                            <h4 class="headline">Hotel address</h4>
                            <h4>{{hotel.name}}</h4>
                            <h6 class="headline">{{hotel.location}}</h6>
                            <p class="mt-5">Helpline : {{hotel.phone}}</p>
                        </div>
                        <div class="col-4">
                            <p class="m-0">Locate: GOOGLE MAP</p>
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15548.609235719734!2d77.62401938438413!3d13.02597115271441!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae17355dbc2527%3A0x72cf9212205b927a!2sGolden%20Fortune%20Hotel!5e0!3m2!1sen!2sin!4v1701670643834!5m2!1sen!2sin"
                                style="border: 0;width: 100%;border-radius: 15px;aspect-ratio: 1.28;height: 150px;"
                                allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

                        </div>
                    </div>
                </div>
                <div class="hotelcard row border-5 mt-3 mb-3">
                    <div class="row">
                        <div class="col-12 m-auto">
                            <div class="row">
                                <div class="col-6" *ngFor="let img of hotel.ImageURLs | slice:0:2">
                                    <img src="{{img}}">
                                </div>
                                <!-- <div class="col-6" *ngFor="let img of hotel.ImageURLs | slice:1:4" >
                                    <img src="{{img}}">
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>

                <div class="hotelcard row border-5 mt-3 mb-3">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12">
                                <h3 class="text-white">LOCATION</h3>
                                <div class="bg-white5">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="m-3 mapsloc">
                                                <iframe
                                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d17703.00722039818!2d54.97611681645345!3d25.011932901844194!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f11f306f9fb97%3A0x7928a0558d051f31!2sPalm%20Jabel%20Ali%20Villas!5e0!3m2!1sen!2sin!4v1701693514587!5m2!1sen!2sin"
                                                    style="border: 0;width: 100%;border-radius: 10px;box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;"
                                                    allowfullscreen="" loading="lazy"
                                                    referrerpolicy="no-referrer-when-downgrade"></iframe>
                                                <div class="p-3 mapsloctext"><a href="#">View in map</a></div>
                                            </div>
                                            <div class="row m-3">
                                                <div class="col-md-4">
                                                    <div class="p-3">
                                                        <h5 class="text-black pb-4">What's nearby</h5>
                                                        <p class="mb-0">In Palm Jumeirah</p>
                                                        <hr class="my-2">
                                                        <p class="mb-0">Marina Beach - 68 min walk</p>
                                                        <hr class="my-2">
                                                        <p class="mb-0">Aquaventure - 9 min drive</p>
                                                        <hr class="my-2">
                                                        <p class="mb-0">Dubai Marina Mall - 7 min drive</p>
                                                        <hr class="my-2">
                                                        <p class="mb-0">The Walk - 9 min drive</p>
                                                        <hr class="my-2">
                                                        <p class="mb-0">Souk Madinat Jumeirah - 10 min drive</p>
                                                        <hr class="my-2">
                                                        <p class="mb-0">Wild Wadi Water Park - 9 min drive</p>
                                                        <hr class="my-2">
                                                        <p class="mb-0">Burj Al Arab - 10 min drive</p>
                                                        <hr class="my-2">
                                                        <p class="mb-0">Madinat Jumeirah - 11 min drive</p>
                                                        <hr class="my-2">
                                                        <p class="mb-0">Ski Dubai - 10 min drive</p>
                                                        <hr class="my-2">
                                                        <p class="mb-0">Mall of the Emirates - 11 min drive</p>
                                                        <hr class="my-2">
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="p-3">
                                                        <h5 class="text-black pb-4">Restaurants</h5>
                                                        <p class="mb-0">SĀN Beach - 15 min walk</p>
                                                        <hr class="my-2">
                                                        <p class="mb-0">Depachika Food Hall - 6 min walk</p>
                                                        <hr class="my-2">
                                                        <p class="mb-0">Sushisamba - 13 min walk</p>
                                                        <hr class="my-2">
                                                        <p class="mb-0">Joe & The Juice - 17 min walk</p>
                                                        <hr class="my-2">
                                                        <p class="mb-0">McDonald's - 5 min walk</p>
                                                        <hr class="my-2">

                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="p-3">
                                                        <h5 class="text-black pb-4">Getting around</h5>
                                                        <p class="mb-0">Dubai Intl. Airport (DXB) - 33 min drive</p>
                                                        <hr class="my-2">
                                                        <p class="mb-0">Al Maktoum Intl. Airport (DWC) - 37 min drive
                                                        </p>
                                                        <hr class="my-2">
                                                        <p class="mb-0">Sharjah (SHJ-Sharjah Intl.) - 56 min drive</p>
                                                        <hr class="my-2">
                                                        <p class="mb-0">Nakheel Mall Station - 7 min walk</p>
                                                        <hr class="my-2">
                                                        <p class="mb-0">Al Ittihad Park Station - 9 min walk</p>
                                                        <hr class="my-2">
                                                        <p class="mb-0">Palm Gateway Station - 25 min walk</p>
                                                        <hr class="my-2">

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="hotelcard row border-5 mt-3 mb-3">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12">
                                <h3 class="text-white">AMENITIES
                                </h3>
                                <div class="bg-white6">
                                    <div class="row">
                                        <div class="col-md-12">

                                            <div class="row m-3 pt-5">
                                                <h4 class="text-black">Property amenities</h4>
                                                <div class="col-md-3">
                                                    <div class="p-3">
                                                        <h5 class="text-black "><i class="fa-solid fa-circle"></i> Food
                                                            and drink
                                                        </h5>
                                                        <ul>
                                                            <li>Buffet breakfast </li>
                                                            <li>7:00- 11:00 AM</li>
                                                            <li>3 restaurants</li>
                                                            <li>2 coffee shops</li>
                                                            <li>Bar/lounge</li>
                                                            <li>24-hour room </li>
                                                            <li>service</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="p-3">
                                                        <h5 class="text-black "><i class="fa-solid fa-circle"></i>
                                                            Services
                                                        </h5>
                                                        <ul>
                                                            <li>24-hour front desk</li>
                                                            <li>Concierge services</li>
                                                            <li>Tour/ticket assistance</li>
                                                            <li>Luggage storage</li>
                                                            <li>Multilingual staff</li>
                                                            <li>Pool loungers</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="p-3">
                                                        <h5 class="text-black "><i class="fa-solid fa-circle"></i>
                                                            Facilities
                                                        </h5>
                                                        <ul>
                                                            <li>1 building/tower</li>
                                                            <li>Built in 2023</li>
                                                            <li>Safe-deposit box</li>
                                                            <li>Rooftop terrace</li>
                                                            <li>24-hr fitness center</li>
                                                            <li>An outdoor pool</li>
                                                            <li>Full-service spa</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="p-3">
                                                        <h5 class="text-black "><i class="fa-solid fa-circle"></i>
                                                            Traveling with
                                                            children
                                                        </h5>
                                                        <ul>
                                                            <li>Free children's club</li>
                                                            <li>Babysitting (surcharge)</li>
                                                            <li>Children's games</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row m-3 pt-5">
                                                <h4 class="text-black">Room amenities</h4>
                                                <div class="col-md-3">
                                                    <div class="p-3">
                                                        <h5 class="text-black "><i class="fa-solid fa-circle"></i> Be
                                                            entertained
                                                        </h5>
                                                        <ul>
                                                            <li>42-inch LED TV
                                                            </li>
                                                            <li>Cable TV channels</li>

                                                        </ul>
                                                    </div>
                                                    <div class="p-3">
                                                        <h5 class="text-black "><i class="fa-solid fa-circle"></i> What
                                                            to enjoy
                                                        </h5>
                                                        <ul>
                                                            <li>Balcony
                                                            </li>
                                                            <li>City view</li>

                                                        </ul>
                                                    </div>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="p-3">
                                                        <h5 class="text-black "><i class="fa-solid fa-circle"></i> Home
                                                            comfort
                                                        </h5>
                                                        <ul>
                                                            <li>Air conditioning</li>
                                                            <li>Minibar</li>
                                                            <li>Coffee/tea maker</li>

                                                        </ul>
                                                    </div>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="p-3">
                                                        <h5 class="text-black "><i class="fa-solid fa-circle"></i> Sleep
                                                            well
                                                        </h5>
                                                        <ul>
                                                            <li>Soundproofed rooms</li>
                                                            <li>Turndown service</li>
                                                            <li>Free crib/infant bed</li>

                                                        </ul>
                                                    </div>
                                                </div>
                                                <div class="col-md-3">

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>