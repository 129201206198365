import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { SawlService } from '../shared/sawl.service';

@Component({
  selector: 'app-flight-final-gothrough',
  templateUrl: './flight-final-gothrough.component.html',
  styleUrls: ['./flight-final-gothrough.component.scss']
})
export class FlightFinalGothroughComponent {
    public form: FormGroup;
    constructor(
        public fb: FormBuilder,
        public swal: SawlService,) {
            this.genrateForm()
         }
    public flight: any = [
        {
            to: "Dubai",
            from: "Oman",
            date: "Saturday, Dec 23",
            stop:"Non Stop",
            duration:"1h 55m",
            flight:"Emirates",
            flightno:"E15 1789",
            offer:"Economy",
            value:"Xpress Value",
            arrival:"21:10",
            departure:"23:20",
            arrivalaircode:"DXB",
            arrivalair:"Dubai International Airport, Terminal 2",
            departureaircode:"OMN",
            departureair:"Oman International Airport, Terminal 2",
        }
    ]
    public users: any = [
        {
            age: "12",
            numbers:"1",
        }
    ]
    genrateForm() {
        this.form = this.fb.group({
          ecCategoryId: null,
          ecCategoryName: null,
          ecPrefix: null,
          ecStatus: true,
          ecCreatedBy: null,
          ecCreatedOn: null,
          ecModifiedBy: null,
          ecModifiedOn: null,
        })
      }

      async addData() {

      }
}
