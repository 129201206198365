import { Component,OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { SharedService } from '../shared/shared.service'
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-offers',
  templateUrl: './offers.component.html',
  styleUrls: ['./offers.component.scss']
})
export class OffersComponent implements OnInit {
    public place: any;
    public id: any =[];
    constructor(
        public details: SharedService,
        private route: ActivatedRoute
    ){

    }

    ngOnInit() {
        const id = this.route.snapshot.paramMap.get('id');
        this.id = id;
        console.log(id)
        this.offers()
    }

    async offers() {
        this.place = this.details.offer
        this.place = this.place.find(place => place.slug == this.id);
        console.log(this.place)
    }

}
