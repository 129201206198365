import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import {
    FormBuilder,
    FormGroup,
    Form,
    Validators,
    FormControl,
    FormArray,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";

@Component({
    selector: "app-hotel-search",
    templateUrl: "./hotel-search.component.html",
    styleUrls: ["./hotel-search.component.scss"],
})
export class HotelSearchComponent {
    pricerange: string;
    prices: string[] = [
        "AED 120 - AED 154",
        "AED 154 - AED 308",
        "AED 308 - AED 418",
        "AED 418 - AED 507",
        "AED 507 - AED 661",
        "AED 661 - AED 1322",
        "AED 1322+",
    ];
    starrsting: string;
    stars: string[] = ["5 Star", "4 Star", "3 Star"];
    public dialogRef: any;
    public amenities: any;
    @ViewChild("filter") filter: TemplateRef<any>;
    public hotel: any = [
        {
            id: 1,
            name: "Emirates Grand Hotel Apartments -Near Burj Khalifa",
            location: "Sheikh Zayed Road",
            attraction: "3.4 km from Burj Khalifa",
            amenities: ["Swimming Pool", "Spa", "Nightt Club"],
            cancel: [
                "Book with ₹0 Payment",
                "Indian food at the property, Free Early Check-in available",
                "Free Cancellation till 24 hrs before check in ",
            ],
            price: "149 AED",
            gst: "+ AED 1,820 taxes & fees per night",
            rating: "4.6",
            overallreview: "Very Good",
            totalreview: "9,200",
        },
        {
            id: 2,
            name: "Al Habtoor Polo Resort",
            location: "Sheikh Zayed Road",
            attraction: "2.4 km from Dubai Gold Souk",
            amenities: ["Swimming Pooll", "Spa", "Night Club"],
            cancel: [
                "Book with ₹0 Payment",
                "Indian food at the property, Free Early Check-in available",
                "Free Cancellation till 24 hrs before check in ",
            ],
            price: "349 AED",
            gst: "+ AED 1,820 taxes & fees per night",
            rating: "4.5",
            overallreview: "Very Good",
            totalreview: "19,200",
        },
        {
            id: 3,
            name: "Al Habtoor Polo Resort",
            location: "Sheikh Zayed Road",
            attraction: "2.4 km from Dubai Gold Souk",
            amenities: ["Swimming Pooll", "Spa", "Night Club"],
            cancel: [
                "Book with ₹0 Payment",
                "Indian food at the property, Free Early Check-in available",
                "Free Cancellation till 24 hrs before check in ",
            ],
            price: "349 AED",
            gst: "+ AED 1,820 taxes & fees per night",
            rating: "4.5",
            overallreview: "Very Good",
            totalreview: "19,200",
        },
        {
            id: 4,
            name: "Al Habtoor Polo Resort",
            location: "Sheikh Zayed Road",
            attraction: "2.4 km from Dubai Gold Souk",
            amenities: ["Swimming Pooll", "Spa", "Night Club"],
            cancel: [
                "Book with ₹0 Payment",
                "Indian food at the property, Free Early Check-in available",
                "Free Cancellation till 24 hrs before check in ",
            ],
            price: "349 AED",
            gst: "+ AED 1,820 taxes & fees per night",
            rating: "4.5",
            overallreview: "Very Good",
            totalreview: "19,200",
        },
        {
            id: 5,
            name: "Al Habtoor Polo Resort",
            location: "Sheikh Zayed Road",
            attraction: "2.4 km from Dubai Gold Souk",
            amenities: ["Swimming Pooll", "Spa", "Night Club"],
            cancel: [
                "Book with ₹0 Payment",
                "Indian food at the property, Free Early Check-in available",
                "Free Cancellation till 24 hrs before check in ",
            ],
            price: "349 AED",
            gst: "+ AED 1,820 taxes & fees per night",
            rating: "4.5",
            overallreview: "Very Good",
            totalreview: "19,200",
        },
    ];
    constructor(public dialog: MatDialog, private _formBuilder: FormBuilder) {}

    filterdilog() {
        this.dialogRef = this.dialog.open(this.filter, {
            width: "70%",
            disableClose: true,
        });
    }

    getAmenities(items: string | any[]) {
        console.log(items);

        var elems = this.hotel.filter((item: { id: string }) => {
            return items.includes(item.id);
        });
        return elems;
    }
}
