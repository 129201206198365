<!-- <app-navbar-three></app-navbar-three> -->
<section class="bg-dark">
    <div class="container">
        <div class="topbar">
            <div class="row my-3">
                <div class="col-6">
                    <h4 class="prifont">Complete your booking</h4>
                </div>
                <div class="col-6 m-auto">
                    <div class="row">
                        <div class="col-3 text-end">
                            <h6 class="prifont">Flight Summary</h6>
                        </div>
                        <div class="col-3 text-end">
                            <h6 class="prifont">Travel Details</h6>
                        </div>
                        <div class="col-3 text-end">
                            <h6 class="prifont">Seats & Meals</h6>
                        </div>
                        <div class="col-3 text-end">
                            <h6 class="prifont">Final Confirmation</h6>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>

</section>
<section class="pri-bg py-5">
    <div class="container" *ngFor="let flight of flight">
        <div class="card">
            <div class="container my-2">
                <div class="row detail m-auto">
                    <div class="col-8">
                        <h4 class="mt-3 mb-3"><span>{{flight.to}} </span><i class="fa fa-long-arrow-right"
                                aria-hidden="true"></i><span>
                                {{flight.from}}</span></h4>
                        <p><span class="boxx mx-1 font-sm">{{flight.date}}</span><span class="font-sm">{{flight.stop}} -
                                {{flight.duration}}</span></p>
                        <p><span class="mx-1 font-sm"><b>{{flight.flight}}</b></span><span
                                class="font-sm">{{flight.flightno}}</span></p>
                    </div>
                    <div class="col-4 text-endd mt-3 mb-3">
                        <div class="box">
                            <p class="button">Cancellation fees apply</p>
                            <p>View Fare Rules</p>
                            <p>{{flight.offer}} > <span class="highlight"><b>{{flight.value}} </b> <i
                                        class="fa fa-circle-info"></i></span>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="row mx-4 content-card">
                    <div class="col-4">
                        <h6>{{flight.arrival}}</h6>
                        <h6>{{flight.arrivalair}}</h6>
                    </div>
                    <div class="col-4">
                        <p>{{flight.duration}}</p>
                        <i class="fa-solid fa-arrow-right"></i>
                    </div>
                    <div class="col-4">
                        <h6>{{flight.departure}}</h6>
                        <h6>{{flight.departureaircode}} - {{flight.departureair}}</h6>
                    </div>
                    <hr />
                    <div class="col-6 text-right">
                        <span><img src="../../../../assets/img/luggage.png"> Cabin Baggage: 7 Kgs (1 piece only) /
                            Adult</span>
                    </div>
                    <div class="col-6 text-left">
                        <span><img src="../../../../assets/img/luggage.png"> Cabin Baggage: 7 Kgs (1 piece only) /
                            Adult</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="card my-4 py-3 px-4">
            <h4>Fare Summary</h4>
            <div class="row detail mx-3">
                <div class="col-8">
                    <span><i class="fa-regular fa-circle"></i> Base Fare</span><br />
                    <span>Adults(s) (1x AED1,000)</span>
                </div>
                <div class="col-4 text-end">
                    <span><b>AED 1000</b></span>
                </div>
                <hr />
                <div class="col-8">

                    <span><i class="fa-regular fa-circle"></i> Taxes & Surcharges</span><br />
                    <span>Airline Taxes & Surcharges</span>
                </div>
                <div class="col-4 text-end">
                    <span><b>AED 500</b></span>
                </div>
                <hr />
                <div class="col-8">
                    <span><i class="fa-regular fa-circle"></i> Seat Fare</span><br />
                    <span>AE-5C</span>
                </div>
                <div class="col-4 text-end">
                    <span><b>AED 200</b></span>
                </div>
                <hr />
                <div class="col-8">
                    <span><i class="fa-regular fa-circle"></i> Meals Fare</span><br />
                    <span>Veg Meals</span>
                </div>
                <div class="col-4 text-end">
                    <span><b>AED 50</b></span>
                </div>
            </div>
            <div class="row content-card">
                <div class="col-8">
                    <span><b>Total Fare</b></span>
                </div>
                <div class="col-4 text-end">
                    <span><b>AED 1750</b></span>
                </div>
            </div>
        </div>

        <div class="card my-4 py-3 px-4">
            <h4>Terms & Conditions</h4>
            <div class="row detail mx-3">
                <div class="col-12">
                    <p class="m-0">By clicking on the Continue button below to proceed with the booking. I confirm that
                        i have read and I accept the Fare Rules,
                        the Privacy Policy, the User Agreement and Terms of Service of Gulf Travels</p>
                </div>
            </div>
        </div>


        <mat-dialog-actions align="end">
            <!-- <button class="my-2 saas-btn" color="primary" (click)="addData()" routerLink="/flight-payment"
                [disabled]="!form.valid || form.pristine"><span *ngIf='!form.value.ecCategoryId'>Continue</span><span
                    *ngIf='form.value.ecCategoryId'>Update</span></button> -->
            <button class="my-2 saas-btn" color="primary" (click)="addData()" routerLink="/flight-payment">Continue
            </button>
        </mat-dialog-actions>


    </div>
</section>