import { Injectable } from '@angular/core';
import Swal from "sweetalert2";
@Injectable({
  providedIn: 'root'
})
export class SawlService {

  constructor() { }

  public Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    // onOpen: (toast) => {
    //   toast.addEventListener('mouseenter', Swal.stopTimer)
    //   toast.addEventListener('mouseleave', Swal.resumeTimer)
    // }
  })


  success(data) {
    this.Toast.fire({
      icon: 'success',
      title: `${data}`
    })
    // Swal.fire({
    //   position: 'top-end',
    //   icon: 'success',
    //   title:  `${data}`,
    //   showConfirmButton: false,
    //   timer: 1500
    // })
  }

  conflict(data) {
    this.Toast.fire({
      icon: 'error',
      title: `${data}`
    })
    // Swal.fire({
    //   position: 'top-end',
    //   icon: 'error',
    //   title:  `${data}`,
    //   showConfirmButton: false,
    //   timer: 1500
    // })
  }

  errorMsg(data) {
    this.Toast.fire({
      icon: 'warning',
      title: `${data}`
    })
    // Swal.fire({
    //   position: 'top-end',
    //   icon: 'warning',
    //   title:  `${data}`,
    //   showConfirmButton: false,
    //   timer: 1500
    // })
  }
}
