<div class="bg-dark">
    <app-navbar-one></app-navbar-one>
    <section class="py-5">
        <div class="container mt-5 pt-5">
            <div class="row">
                <p class="text-white">
                    My Account <i class="fa-solid fa-angle-right"></i> My Profile
                </p>
            </div>
        </div>
    </section>


    <section>
        <div class="container">
            <div class="row">
                <div class="col-md-4">
                    <div class="sticksidebar pb-2">
                        <div class="bg-white rounded card">
                            <div class="text-center py-4">
                                <div class="profilepic-bg ">
                                    <div class="edit-img"><button><i class="fa-solid fa-pen"></i></button></div>

                                    <img src="" alt="">
                                </div>
                                <div class=" text-center">
                                    <h3>Amanda Jorden</h3>
                                </div>
                                <div>

                                    <div class="d-flex gap-4 rounded mx-5 py-2 px-4 align-items-center seclink active"
                                        (click)="scrollToId('profile')" [class.active]="scroll >= 0 && scroll < 66">
                                        <div>
                                            <i class="fa-solid fa-user"></i>
                                        </div>
                                        <div>
                                            <p class="text-black">
                                                Profile
                                            </p>
                                        </div>
                                    </div>


                                    <div class="d-flex gap-4 rounded mx-5 py-2 px-4 align-items-center seclink"
                                        (click)="scrollToId('details')" [class.active]="scroll >= 66 && scroll < 123">
                                        <div>
                                            <i class="fa-solid fa-clipboard-list"></i>
                                        </div>
                                        <div>
                                            <p class="text-black">
                                                Login Details
                                            </p>
                                        </div>
                                    </div>


                                    <div class="d-flex gap-4 rounded mx-5 py-2 px-4 align-items-center seclink"
                                        (click)="scrollToId('travellers')"
                                        [class.active]="scroll >= 123 && scroll < 1000">
                                        <div>
                                            <i class="fa-solid fa-users"></i>
                                        </div>
                                        <div>
                                            <p class="text-black">
                                                Save Travellers
                                            </p>
                                        </div>
                                    </div>

                                    <a href="logout">
                                        <div class="d-flex gap-4 rounded mx-5 py-2 px-4 align-items-center seclink">
                                            <div>
                                                <i class="fa-solid fa-right-from-bracket"></i>
                                            </div>
                                            <div>
                                                <p class="text-black">
                                                    Logout
                                                </p>
                                            </div>
                                        </div>
                                    </a>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-8">
                    <div class="pb-2">
                        <div class="bg-white rounded p-4">
                            <div class="row">
                                <div class="col-md-8">
                                    <div class="d-flex justify-content-between">
                                        <div>
                                            <h4>Complete your Profile</h4>
                                        </div>
                                        <div>
                                            <div class="text-black">
                                                60%
                                            </div>
                                        </div>
                                    </div>
                                    <mat-progress-bar mode="determinate" value="60" color="warn"></mat-progress-bar>
                                </div>
                                <p class="text-black py-2">Get the best out of Gulf Travel and Tourism by adding the
                                    remaining
                                    details!
                                </p>
                            </div>
                            <div class="row pt-4">
                                <div class="col-md-12">
                                    <div class="bg-gray rounded px-2 py-2">
                                        <div class="row">
                                            <div class="col-md-4 text-center">
                                                <p class="checked"> <i class="fa-solid fa-circle-check"></i>
                                                    Verified Email ID</p>
                                            </div>
                                            <div class="col-md-4 text-center">
                                                <p class="add"> <i class="fa-solid fa-circle-plus"></i>
                                                    Add your mobile number</p>
                                            </div>
                                            <div class="col-md-4 text-center">
                                                <p class="add"> <i class="fa-solid fa-circle-plus"></i>
                                                    Complete Basic Info</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="py-2">
                        <div class="bg-white rounded p-4" id="profile">
                            <div class="row">
                                <div class="col-md-12">
                                    <h2>Profile</h2>
                                    <p>Basic info, for a faster booking experience</p>
                                </div>
                                <div class="col-md-12 p-4">
                                    <div class="row">
                                        <div class="col-4">
                                            <p>NAME</p>
                                        </div>
                                        <div class="col-8">
                                            <p class="text-black">
                                                Amanda Jorden
                                            </p>
                                        </div>
                                        <hr>
                                    </div>
                                    <div class="row">
                                        <div class="col-4">
                                            <p>BIRTHDAY</p>
                                        </div>
                                        <div class="col-8">
                                            <p class="text-black add">
                                                <button><i class="fa-solid fa-plus"></i> Add</button>
                                            </p>
                                        </div>
                                        <hr>
                                    </div>
                                    <div class="row">
                                        <div class="col-4">
                                            <p>GENDER</p>
                                        </div>
                                        <div class="col-8">
                                            <p class="text-black add">
                                                <button><i class="fa-solid fa-plus"></i> Add</button>
                                            </p>
                                        </div>
                                        <hr>
                                    </div>
                                    <div class="row">
                                        <div class="col-4">
                                            <p>MARITAL STATUS</p>
                                        </div>
                                        <div class="col-8">
                                            <p class="text-black add">
                                                <button><i class="fa-solid fa-plus"></i> Add</button>
                                            </p>
                                        </div>
                                        <hr>
                                    </div>
                                    <div class="row">
                                        <div class="col-4">
                                            <p>YOUR ADDRESS</p>
                                        </div>
                                        <div class="col-8">
                                            <p class="text-black add">
                                                <button><i class="fa-solid fa-plus"></i> Add</button>
                                            </p>
                                        </div>
                                        <hr>
                                    </div>
                                    <div class="row">
                                        <div class="col-4">
                                            <p>PINCODE</p>
                                        </div>
                                        <div class="col-8">
                                            <p class="text-black add">
                                                <button><i class="fa-solid fa-plus"></i> Add</button>
                                            </p>
                                        </div>
                                        <hr>
                                    </div>
                                    <div class="row">
                                        <div class="col-4">
                                            <p>STATE</p>
                                        </div>
                                        <div class="col-8">
                                            <p class="text-black add">
                                                <button><i class="fa-solid fa-plus"></i> Add</button>
                                            </p>
                                        </div>
                                        <hr>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="py-2">
                        <div class="bg-white rounded p-4" id="details">
                            <div class="row">
                                <div class="col-md-12">
                                    <h2>Login Details</h2>
                                    <p>Manage your email address mobile number and password</p>
                                </div>
                                <div class="col-md-12 p-4">
                                    <div class="row">
                                        <div class="col-4">
                                            <p>MOBILE NUMBER</p>
                                        </div>
                                        <div class="col-8">
                                            <p class="text-black">
                                                +91*******12
                                            </p>
                                        </div>
                                        <hr>
                                    </div>
                                    <div class="row">
                                        <div class="col-4">
                                            <p>EMAIL ID</p>
                                        </div>
                                        <div class="col-8">
                                            <p class="text-black ">
                                                amandaJorden07@gmail.com
                                            </p>
                                        </div>
                                        <hr>
                                    </div>
                                    <div class="row">
                                        <div class="col-4">
                                            <p>PASSWORD</p>
                                        </div>
                                        <div class="col-4">
                                            <p class="text-black">
                                                .......
                                            </p>
                                        </div>
                                        <div class="col-4 text-end add">
                                            <button>Change Password?</button>
                                        </div>
                                        <hr>
                                    </div>




                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="py-2">
                        <div class="bg-white rounded p-4" id="travellers">
                            <div class="row">
                                <div class="col-md-6">

                                    <h2>Save Traveller(s)</h2>
                                    <p>You have 0 Traveller(s)</p>
                                </div>
                                <div class="col-md-6">
                                    <div class="text-end">
                                        <button class="addtra">Add Traveller</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>