<app-navbar-one></app-navbar-one>

<div class="marketing-home-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 col-md-12">
                        <div class="hero-content">
                            <h1>Travel Effortlessly</h1>

                            <div class="marketing-btn">
                                <!-- <a routerLink="/" class="marketing-btn-one">Discover More</a>
                                <a href="https://www.youtube.com/watch?v=bk7McNUjWgw"
                                    class="video-btn popup-youtube"><span>
                                        <i class="fa fa-play"></i></span>
                                    Watch The Demo
                                </a> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- <div class="shape-img1"><img src="assets/img/shape/shape1.svg" alt="image"></div>
    <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.png" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
    <div class="shape-img5"><img src="assets/img/shape/shape6.png" alt="image"></div> -->
</div>

<section class="top-services-section pb-100">
    <div class="container">
        <div class="sometop">
            <div class="otherservises">
                <div class="services"><a routerLink="/" class="active">Flights</a></div>
                <div class="services"><a routerLink="/hotel">Hotel</a></div>
                <div class="services"><a routerLink="/carrental">Car rental</a></div>
                <div class="services"><a routerLink="/explore">Explore</a></div>
            </div>
            <div class="top-services-area">
                <div class="row align-items-center">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="top-services-content">

                            <form [formGroup]="beComponentForm">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="from-to">
                                            <div class="from_div">
                                                <div>
                                                    <img src="assets/img/icon/takeoff.png" alt="" width="50px">
                                                </div>
                                                <div style="width: 80%;">
                                                    <mat-form-field class="col-sm-4 inp">
                                                        <span>From</span>
                                                        <mat-select formControlName="slct_cntrl1" placeholder="Location"
                                                            [(ngModel)]="demo">
                                                            <div class="fixed">

                                                                <mat-form-field [floatLabel]="'never'"
                                                                    style="margin: 5px;padding-left: 10px;padding-right: 10px;position: absolute;top: 0;width: calc(100% - 30px);background: white;z-index: 100;height:65px;">
                                                                    <input matInput placeholder=""
                                                                        style="padding-left:10px"
                                                                        (input)="lookup($event.target.value);"
                                                                        #inputFilter>
                                                                    <i class="fa fa-search" matPrefix></i>
                                                                    <i class="fa fa-times" matSuffix
                                                                        (click)="clean(inputFilter);"></i>
                                                                </mat-form-field>
                                                            </div>
                                                            <div style="padding-top: 80px">
                                                                <mat-option *ngFor="let data of filteredDataToSearch"
                                                                    [value]="data.value"> {{data.text}}
                                                                </mat-option>
                                                            </div>
                                                        </mat-select>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                            <br>
                                            <div class="swicher">
                                                <div class="swicher-img">
                                                    <button><img src="assets/img/logo/switcher.png" alt=""
                                                            (click)="interchangeValues(demo,demo1)"></button>
                                                </div>
                                            </div>
                                            <div class="from_div">
                                                <div>
                                                    <img src="assets/img/icon/landing.png" alt="" width="50px">
                                                </div>
                                                <div style="width: 80%;">
                                                    <mat-form-field class="col-sm-4 inp">
                                                        <span>To</span>
                                                        <mat-select formControlName="slct_cntrl" placeholder="Location"
                                                            [(ngModel)]="demo1">
                                                            <div class="fixed">

                                                                <mat-form-field [floatLabel]="'never'"
                                                                    style="margin: 5px;padding-left: 10px;padding-right: 10px;
                                                                                                                                                                                                                                border: 1.5px solid rgba(216, 220, 230, 0.87); border-radius: 5px;
                                                                                                                                                                                                                                position: absolute;top: 0;width: calc(100% - 30px);background: white;z-index: 100;height:65px;">
                                                                    <input matInput placeholder=""
                                                                        style="padding-left:10px"
                                                                        (input)="lookup($event.target.value);"
                                                                        #inputFilter>
                                                                    <i class="fa fa-search" matPrefix></i>
                                                                    <i class="fa fa-times" matSuffix
                                                                        (click)="clean(inputFilter);"></i>
                                                                </mat-form-field>
                                                            </div>
                                                            <div style="padding-top: 80px">

                                                                <mat-option *ngFor="let data of filteredDataToSearch"
                                                                    [value]="data.value">
                                                                    {{data.text}}
                                                                </mat-option>
                                                            </div>
                                                        </mat-select>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-md-6">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="date_div">
                                                    <div style="width: 20%;"><img src="assets/img/icon/date.png" alt=""
                                                            width="50px"></div>
                                                    <div style="width: 80%;">
                                                        <mat-form-field style="width: -webkit-fill-available;">
                                                            <mat-label>Departure date</mat-label>
                                                            <input matInput [matDatepicker]="dd"
                                                                formControlName="departure_date" [min]="minDate"
                                                                disabled="true">

                                                            <mat-datepicker-toggle matIconSuffix
                                                                [for]="dd"></mat-datepicker-toggle>
                                                            <mat-datepicker #dd disabled="false"></mat-datepicker>
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="date_div">
                                                    <div style="width: 20%;"><img src="assets/img/icon/date.png" alt=""
                                                            width="50px"></div>
                                                    <div style="width: 80%;">
                                                        <mat-form-field style="width: -webkit-fill-available;"
                                                            *ngIf="isvisble; else retuendata">
                                                            <mat-label>+ ADD return date
                                                            </mat-label>
                                                            <input matInput [matDatepicker]="rd" id="returndate"
                                                                [min]="minDate" formControlName="return_date" disabled>

                                                            <mat-datepicker-toggle matIconSuffix
                                                                [for]="rd"></mat-datepicker-toggle>
                                                            <mat-datepicker #rd
                                                                [disabled]="return_disabled"></mat-datepicker>
                                                        </mat-form-field>
                                                        <ng-template #retuendata>
                                                            <p (click)="enableinput()">+ ADD return date</p>
                                                        </ng-template>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-md-12 pt-4">
                                                <div class="class_div">
                                                    <div>
                                                        <img src="assets/img/icon/class.png" alt="">
                                                    </div>
                                                    <div style="width: 80%;">


                                                        <button class="selecttrv" (click)="openpassDialog()">Travellers
                                                            &
                                                            class

                                                            <p class="mb-0"><b>{{+adults.value + +children.value +
                                                                    +infants.value}}</b>
                                                                <span
                                                                    *ngIf="+adults.value + +children.value + +infants.value == 1; else morethan1">
                                                                    Traveller</span>
                                                                <ng-template #morethan1> Travellers</ng-template>
                                                            </p>
                                                            <sub>{{class.value}}</sub>
                                                        </button>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <mat-radio-group aria-label="Select an option" formControlName="triptype">
                                            <mat-radio-button value="oneway" [checked]="true"
                                                (click)="enableinput()">One-way</mat-radio-button>
                                            <mat-radio-button value="return" (click)="disableInputField()">Round
                                                trip</mat-radio-button>

                                            <!-- <mat-radio-button value="2">Multicity</mat-radio-button> -->
                                        </mat-radio-group>

                                        <!-- <mat-form-field>
                                            <input matInput value="{{+adults.value}}" formControlName="adultsno">
                                        </mat-form-field> -->
                                    </div>
                                    <div class="col-md-6"></div>
                                    <div class="col-md-4"></div>
                                    <div class="col-md-4">
                                        <div class="submit_btn">
                                            <input type="submit" (click)="getvalueform()" routerLink="flight-search"
                                                value="Search flights">
                                            <!-- <input type="submit" routerLink="/flight-search" value="Search flights"> -->
                                        </div>
                                    </div>
                                    <div class="col-md-4"></div>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</section>
<ng-template #passenger>



    <div class="container p-4 popupbox2">
        <div class="text-end">
            <button mat-raised-button class="m-1 btnclose" matDialogClose="yes"><i
                    class="fa-solid fa-xmark"></i></button>
        </div>
        <div class="row">
            <div class="col-12">
                <p class="text-black mb-0 mt-2">ADULTS (12y +) </p>

                <mat-button-toggle-group #adults="matButtonToggleGroup" class="mb-3">
                    <!-- <mat-button-toggle-group [formControl]="adults" #adults="matButtonToggleGroup" class="mb-3"> -->
                    <mat-button-toggle value="1">01</mat-button-toggle>
                    <mat-button-toggle value="2">02</mat-button-toggle>
                    <mat-button-toggle value="3">03</mat-button-toggle>
                    <mat-button-toggle value="4">04</mat-button-toggle>
                    <mat-button-toggle value="5">05</mat-button-toggle>
                    <mat-button-toggle value="6">06</mat-button-toggle>
                </mat-button-toggle-group>

                <p></p> Selected value: {{adults.value}}



            </div>
            <div class="col-6">
                <p class="text-black mb-0 mt-2">CHILDREN (2y - 12y )</p>

                <mat-button-toggle-group [formControl]="children" class="mb-3">
                    <mat-button-toggle value="0">00</mat-button-toggle>
                    <mat-button-toggle value="1">01</mat-button-toggle>
                    <mat-button-toggle value="2">02</mat-button-toggle>
                    <mat-button-toggle value="3">03</mat-button-toggle>
                    <mat-button-toggle value="4">04</mat-button-toggle>
                    <mat-button-toggle value="5">05</mat-button-toggle>
                    <mat-button-toggle value="6">06</mat-button-toggle>
                </mat-button-toggle-group>



            </div>
            <div class="col-6">
                <p class="text-black mb-0 mt-2">INFANTS (below 2y)</p>

                <mat-button-toggle-group [formControl]="infants" class="mb-3">
                    <mat-button-toggle formControlName="inf_avl" value="0">00</mat-button-toggle>
                    <mat-button-toggle formControlName="inf_avl" value="1">01</mat-button-toggle>
                    <mat-button-toggle formControlName="inf_avl" value="2">02</mat-button-toggle>
                    <mat-button-toggle formControlName="inf_avl" value="3">03</mat-button-toggle>
                    <mat-button-toggle formControlName="inf_avl" value="4">04</mat-button-toggle>
                    <mat-button-toggle formControlName="inf_avl" value="5">05</mat-button-toggle>
                    <mat-button-toggle formControlName="inf_avl" value="6">06</mat-button-toggle>
                </mat-button-toggle-group>



            </div>
            <div class="col-6">
                <p class="text-black mb-0 mt-2">CHOOSE TRAVEL CLASS</p>

                <mat-button-toggle-group [formControl]="class" class="mb-3">
                    <mat-button-toggle formControlName="class_aval" value="Economy/Premium Economy">Economy/Premium
                        Economy</mat-button-toggle>
                    <mat-button-toggle formControlName="class_aval" value="Premium Economy">Premium
                        Economy</mat-button-toggle>
                    <mat-button-toggle formControlName="class_aval" value="Business">Business</mat-button-toggle>

                </mat-button-toggle-group>



            </div>
        </div>


        <div class="text-end">
            <button mat-raised-button class="m-1 apply" matDialogClose="yes">Apply</button>
        </div>
    </div>
</ng-template>



<section class="what-we-do-section pb-100">
    <div class="container">
        <div class="row align-items-center">
            <!-- We will make a simple accordian with hover effects
            The markup will have a list with images and the titles-->
            <div class="col-md-12">
                <div class="row">
                    <div class="col-md-6">
                        <h3 class="text-white text-md-left">Top 10 Popular destinations</h3>
                    </div>
                    <div class="col-md-6 text-end "><a routerLink="/top-destination" class="show_more_btn">Find out
                            more</a></div>
                </div>
            </div>
            <div class="accordion1">
                <ul>
                    <li>
                        <div>

                            <a href="#" class="sliderLink">
                                <h2>Vietnam</h2>
                                <p>Experience Vietnam's rich tapestry of culture,<br>
                                    stunning landscapes, and delectable cuisine.
                                </p>
                            </a>
                        </div>
                    </li>
                    <li>
                        <div>
                            <a href="#" class="sliderLink">
                                <h2></h2>
                                <p>
                                </p>
                            </a>
                        </div>
                    </li>
                    <li>
                        <div>
                            <a href="#" class="sliderLink">
                                <h2></h2>
                                <p></p>
                            </a>
                        </div>
                    </li>


                </ul>
            </div>

        </div>
        <div class="row mt-5">
            <div class="col-md-12">
                <mat-progress-bar mode="determinate" value="50"></mat-progress-bar>
            </div>
        </div>
    </div>
</section>

<section class="achieved-section ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-md-5">
                <h3 class="text-white">Grand Offers</h3>
            </div>
            <div class="col-md-7">
                <div class="text-center viewqww">
                    <a routerLink="offer">View All →</a>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 my-3" *ngFor="let offers of offer | slice:0:4">
                <mat-card>
                    <mat-card-content>
                        <div class="d-flex align-items-center grand_offer_cards">
                            <div class="img">
                                <img src="{{offers.img}}" alt="">

                            </div>
                            <div>
                                <span>
                                    Flights
                                </span>
                                <h4>{{offers.name}}</h4>
                                <hr>
                                <span>{{offers.description}}</span>

                            </div>

                        </div>
                        <div class="d-flex align-items-center justify-content-between down_sec">
                            <div class="tc">
                                <span>T&C's Apply</span>
                            </div>
                            <div>
                                <a routerLink="/offers/{{offers.slug}}">BOOK NOW</a>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>

        </div>



        <!-- <div class="row">
            <div class="col-md-5">
                <h3 class="text-white">Grand Offers</h3>
            </div>
            <div class="col-md-12 margin_top_o" style="margin-top: -60px;">
                <mat-tab-group>
                    <mat-tab label="All Offers">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-6 my-3">
                                    <mat-card>
                                        <mat-card-content>
                                            <div class="d-flex align-items-center grand_offer_cards">
                                                <div class="img">
                                                    <img src="assets/img/home-page/tab1img1.jpg" alt="">

                                                </div>
                                                <div>
                                                    <span>
                                                        Flights
                                                    </span>
                                                    <h4>Spain is calling you! The best offers ever await you.</h4>
                                                    <hr>
                                                    <span>+ get FLAT 20% OFF on your air tickets to Spain</span>

                                                </div>

                                            </div>
                                            <div class="d-flex align-items-center justify-content-between down_sec">
                                                <div class="tc">
                                                    <span>T&C's Apply</span>
                                                </div>
                                                <div>
                                                    <a href="#">BOOK NOW</a>
                                                </div>
                                            </div>
                                        </mat-card-content>
                                    </mat-card>
                                </div>
                                <div class="col-md-6 my-3">
                                    <mat-card>
                                        <mat-card-content>
                                            <div class="d-flex align-items-center grand_offer_cards">
                                                <div class="img">
                                                    <img src="assets/img/home-page/Honeymoon.jpg" alt="">

                                                </div>
                                                <div>
                                                    <span>
                                                        Hotels
                                                    </span>
                                                    <h4>Honeymoon at the Taj Mumbai? It can't get better than that!</h4>
                                                    <hr>
                                                    <span>+ get FLAT 40% OFF on your 1st hotel booking</span>

                                                </div>

                                            </div>
                                            <div class="d-flex align-items-center justify-content-between down_sec">
                                                <div class="tc">
                                                    <span>T&C's Apply</span>
                                                </div>
                                                <div>
                                                    <a href="#">BOOK NOW</a>
                                                </div>
                                            </div>
                                        </mat-card-content>
                                    </mat-card>
                                </div>
                                <div class="col-md-6 my-3">
                                    <mat-card>
                                        <mat-card-content>
                                            <div class="d-flex align-items-center grand_offer_cards">
                                                <div class="img">
                                                    <img src="assets/img/home-page/Dubai.jpg" alt="">

                                                </div>
                                                <div>
                                                    <span>
                                                        Flights
                                                    </span>
                                                    <h4>Dubai is ready for your arrival.
                                                        Are you? The Best for You!</h4>
                                                    <hr>
                                                    <span>+ get FLAT 20% OFF on your Dubai trip</span>

                                                </div>

                                            </div>
                                            <div class="d-flex align-items-center justify-content-between down_sec">
                                                <div class="tc">
                                                    <span>T&C's Apply</span>
                                                </div>
                                                <div>
                                                    <a href="#">BOOK NOW</a>
                                                </div>
                                            </div>
                                        </mat-card-content>
                                    </mat-card>
                                </div>
                                <div class="col-md-6 my-3">
                                    <mat-card>
                                        <mat-card-content>
                                            <div class="d-flex align-items-center grand_offer_cards">
                                                <div class="img">
                                                    <img src="assets/img/home-page/fly.jpg" alt="">

                                                </div>
                                                <div>
                                                    <span>
                                                        Hotels
                                                    </span>
                                                    <h4>Fly to New York & stay at The Plaza! Christmas Special.</h4>
                                                    <hr>
                                                    <span>+ get FLAT 35% OFF on your flight & hotel booking </span>

                                                </div>

                                            </div>
                                            <div class="d-flex align-items-center justify-content-between down_sec">
                                                <div class="tc">
                                                    <span>T&C's Apply</span>
                                                </div>
                                                <div>
                                                    <a href="#">BOOK NOW</a>
                                                </div>
                                            </div>
                                        </mat-card-content>
                                    </mat-card>
                                </div>
                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab label="Bank Offers">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-6 my-3">
                                    <mat-card>
                                        <mat-card-content>
                                            <div class="d-flex align-items-center grand_offer_cards">
                                                <div class="img">
                                                    <img src="assets/img/home-page/tab1img1.jpg" alt="">

                                                </div>
                                                <div>
                                                    <span>
                                                        Flights
                                                    </span>
                                                    <h4>Spain is calling you! The best offers ever await you.</h4>
                                                    <hr>
                                                    <span>+ get FLAT 20% OFF on your air tickets to Spain</span>

                                                </div>

                                            </div>
                                            <div class="d-flex align-items-center justify-content-between down_sec">
                                                <div class="tc">
                                                    <span>T&C's Apply</span>
                                                </div>
                                                <div>
                                                    <a href="#">BOOK NOW</a>
                                                </div>
                                            </div>
                                        </mat-card-content>
                                    </mat-card>
                                </div>
                                <div class="col-md-6 my-3">
                                    <mat-card>
                                        <mat-card-content>
                                            <div class="d-flex align-items-center grand_offer_cards">
                                                <div class="img">
                                                    <img src="assets/img/home-page/Honeymoon.jpg" alt="">

                                                </div>
                                                <div>
                                                    <span>
                                                        Hotels
                                                    </span>
                                                    <h4>Honeymoon at the Taj Mumbai? It can't get better than that!</h4>
                                                    <hr>
                                                    <span>+ get FLAT 40% OFF on your 1st hotel booking</span>

                                                </div>

                                            </div>
                                            <div class="d-flex align-items-center justify-content-between down_sec">
                                                <div class="tc">
                                                    <span>T&C's Apply</span>
                                                </div>
                                                <div>
                                                    <a href="#">BOOK NOW</a>
                                                </div>
                                            </div>
                                        </mat-card-content>
                                    </mat-card>
                                </div>
                                <div class="col-md-6 my-3">
                                    <mat-card>
                                        <mat-card-content>
                                            <div class="d-flex align-items-center grand_offer_cards">
                                                <div class="img">
                                                    <img src="assets/img/home-page/Dubai.jpg" alt="">

                                                </div>
                                                <div>
                                                    <span>
                                                        Flights
                                                    </span>
                                                    <h4>Dubai is ready for your arrival.
                                                        Are you? The Best for You!</h4>
                                                    <hr>
                                                    <span>+ get FLAT 20% OFF on your Dubai trip</span>

                                                </div>

                                            </div>
                                            <div class="d-flex align-items-center justify-content-between down_sec">
                                                <div class="tc">
                                                    <span>T&C's Apply</span>
                                                </div>
                                                <div>
                                                    <a href="#">BOOK NOW</a>
                                                </div>
                                            </div>
                                        </mat-card-content>
                                    </mat-card>
                                </div>
                                <div class="col-md-6 my-3">
                                    <mat-card>
                                        <mat-card-content>
                                            <div class="d-flex align-items-center grand_offer_cards">
                                                <div class="img">
                                                    <img src="assets/img/home-page/fly.jpg" alt="">

                                                </div>
                                                <div>
                                                    <span>
                                                        Hotels
                                                    </span>
                                                    <h4>Fly to New York & stay at The Plaza! Christmas Special.</h4>
                                                    <hr>
                                                    <span>+ get FLAT 35% OFF on your flight & hotel booking </span>

                                                </div>

                                            </div>
                                            <div class="d-flex align-items-center justify-content-between down_sec">
                                                <div class="tc">
                                                    <span>T&C's Apply</span>
                                                </div>
                                                <div>
                                                    <a href="#">BOOK NOW</a>
                                                </div>
                                            </div>
                                        </mat-card-content>
                                    </mat-card>
                                </div>
                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab label="Flights">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-6 my-3">
                                    <mat-card>
                                        <mat-card-content>
                                            <div class="d-flex align-items-center grand_offer_cards">
                                                <div class="img">
                                                    <img src="assets/img/home-page/tab1img1.jpg" alt="">

                                                </div>
                                                <div>
                                                    <span>
                                                        Flights
                                                    </span>
                                                    <h4>Spain is calling you! The best offers ever await you.</h4>
                                                    <hr>
                                                    <span>+ get FLAT 20% OFF on your air tickets to Spain</span>

                                                </div>

                                            </div>
                                            <div class="d-flex align-items-center justify-content-between down_sec">
                                                <div class="tc">
                                                    <span>T&C's Apply</span>
                                                </div>
                                                <div>
                                                    <a href="#">BOOK NOW</a>
                                                </div>
                                            </div>
                                        </mat-card-content>
                                    </mat-card>
                                </div>
                                <div class="col-md-6 my-3">
                                    <mat-card>
                                        <mat-card-content>
                                            <div class="d-flex align-items-center grand_offer_cards">
                                                <div class="img">
                                                    <img src="assets/img/home-page/Honeymoon.jpg" alt="">

                                                </div>
                                                <div>
                                                    <span>
                                                        Hotels
                                                    </span>
                                                    <h4>Honeymoon at the Taj Mumbai? It can't get better than that!</h4>
                                                    <hr>
                                                    <span>+ get FLAT 40% OFF on your 1st hotel booking</span>

                                                </div>

                                            </div>
                                            <div class="d-flex align-items-center justify-content-between down_sec">
                                                <div class="tc">
                                                    <span>T&C's Apply</span>
                                                </div>
                                                <div>
                                                    <a href="#">BOOK NOW</a>
                                                </div>
                                            </div>
                                        </mat-card-content>
                                    </mat-card>
                                </div>
                                <div class="col-md-6 my-3">
                                    <mat-card>
                                        <mat-card-content>
                                            <div class="d-flex align-items-center grand_offer_cards">
                                                <div class="img">
                                                    <img src="assets/img/home-page/Dubai.jpg" alt="">

                                                </div>
                                                <div>
                                                    <span>
                                                        Flights
                                                    </span>
                                                    <h4>Dubai is ready for your arrival.
                                                        Are you? The Best for You!</h4>
                                                    <hr>
                                                    <span>+ get FLAT 20% OFF on your Dubai trip</span>

                                                </div>

                                            </div>
                                            <div class="d-flex align-items-center justify-content-between down_sec">
                                                <div class="tc">
                                                    <span>T&C's Apply</span>
                                                </div>
                                                <div>
                                                    <a href="#">BOOK NOW</a>
                                                </div>
                                            </div>
                                        </mat-card-content>
                                    </mat-card>
                                </div>
                                <div class="col-md-6 my-3">
                                    <mat-card>
                                        <mat-card-content>
                                            <div class="d-flex align-items-center grand_offer_cards">
                                                <div class="img">
                                                    <img src="assets/img/home-page/fly.jpg" alt="">

                                                </div>
                                                <div>
                                                    <span>
                                                        Hotels
                                                    </span>
                                                    <h4>Fly to New York & stay at The Plaza! Christmas Special.</h4>
                                                    <hr>
                                                    <span>+ get FLAT 35% OFF on your flight & hotel booking </span>

                                                </div>

                                            </div>
                                            <div class="d-flex align-items-center justify-content-between down_sec">
                                                <div class="tc">
                                                    <span>T&C's Apply</span>
                                                </div>
                                                <div>
                                                    <a href="#">BOOK NOW</a>
                                                </div>
                                            </div>
                                        </mat-card-content>
                                    </mat-card>
                                </div>
                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab label="Hotels">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-6 my-3">
                                    <mat-card>
                                        <mat-card-content>
                                            <div class="d-flex align-items-center grand_offer_cards">
                                                <div class="img">
                                                    <img src="assets/img/home-page/tab1img1.jpg" alt="">

                                                </div>
                                                <div>
                                                    <span>
                                                        Flights
                                                    </span>
                                                    <h4>Spain is calling you! The best offers ever await you.</h4>
                                                    <hr>
                                                    <span>+ get FLAT 20% OFF on your air tickets to Spain</span>

                                                </div>

                                            </div>
                                            <div class="d-flex align-items-center justify-content-between down_sec">
                                                <div class="tc">
                                                    <span>T&C's Apply</span>
                                                </div>
                                                <div>
                                                    <a href="#">BOOK NOW</a>
                                                </div>
                                            </div>
                                        </mat-card-content>
                                    </mat-card>
                                </div>
                                <div class="col-md-6 my-3">
                                    <mat-card>
                                        <mat-card-content>
                                            <div class="d-flex align-items-center grand_offer_cards">
                                                <div class="img">
                                                    <img src="assets/img/home-page/Honeymoon.jpg" alt="">

                                                </div>
                                                <div>
                                                    <span>
                                                        Hotels
                                                    </span>
                                                    <h4>Honeymoon at the Taj Mumbai? It can't get better than that!</h4>
                                                    <hr>
                                                    <span>+ get FLAT 40% OFF on your 1st hotel booking</span>

                                                </div>

                                            </div>
                                            <div class="d-flex align-items-center justify-content-between down_sec">
                                                <div class="tc">
                                                    <span>T&C's Apply</span>
                                                </div>
                                                <div>
                                                    <a href="#">BOOK NOW</a>
                                                </div>
                                            </div>
                                        </mat-card-content>
                                    </mat-card>
                                </div>
                                <div class="col-md-6 my-3">
                                    <mat-card>
                                        <mat-card-content>
                                            <div class="d-flex align-items-center grand_offer_cards">
                                                <div class="img">
                                                    <img src="assets/img/home-page/Dubai.jpg" alt="">

                                                </div>
                                                <div>
                                                    <span>
                                                        Flights
                                                    </span>
                                                    <h4>Dubai is ready for your arrival.
                                                        Are you? The Best for You!</h4>
                                                    <hr>
                                                    <span>+ get FLAT 20% OFF on your Dubai trip</span>

                                                </div>

                                            </div>
                                            <div class="d-flex align-items-center justify-content-between down_sec">
                                                <div class="tc">
                                                    <span>T&C's Apply</span>
                                                </div>
                                                <div>
                                                    <a href="#">BOOK NOW</a>
                                                </div>
                                            </div>
                                        </mat-card-content>
                                    </mat-card>
                                </div>
                                <div class="col-md-6 my-3">
                                    <mat-card>
                                        <mat-card-content>
                                            <div class="d-flex align-items-center grand_offer_cards">
                                                <div class="img">
                                                    <img src="assets/img/home-page/fly.jpg" alt="">

                                                </div>
                                                <div>
                                                    <span>
                                                        Hotels
                                                    </span>
                                                    <h4>Fly to New York & stay at The Plaza! Christmas Special.</h4>
                                                    <hr>
                                                    <span>+ get FLAT 35% OFF on your flight & hotel booking </span>

                                                </div>

                                            </div>
                                            <div class="d-flex align-items-center justify-content-between down_sec">
                                                <div class="tc">
                                                    <span>T&C's Apply</span>
                                                </div>
                                                <div>
                                                    <a href="#">BOOK NOW</a>
                                                </div>
                                            </div>
                                        </mat-card-content>
                                    </mat-card>
                                </div>
                            </div>
                        </div>
                    </mat-tab>
                </mat-tab-group>
                <div class="text-center viewqww">
                    <a routerLink="offers">View All</a>
                </div>
            </div>
        </div> -->
        <!-- <div class="achieved-title">
            <span>Why people know us</span>
            <h3>We have Achieved 150+ projects</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                dolet, consectetur adipiscing seore suspendisse ultrices gravir.</p>
        </div>

        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="fun-facts-content">
                    <h3><span class="odometer" data-count="16">00</span><span class="sign-icon">k</span></h3>
                    <p>Expert Employee</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="fun-facts-content">
                    <h3><span class="odometer" data-count="130">00</span><span class="sign-icon">+</span></h3>
                    <p>Ultimate Growth</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="fun-facts-content">
                    <h3><span class="odometer" data-count="20">00</span><span class="sign-icon">k</span></h3>
                    <p>Global Customer</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="fun-facts-content">
                    <h3><span class="odometer" data-count="210">00</span><span class="sign-icon">+</span></h3>
                    <p>Expert Employee</p>
                </div>
            </div>
        </div> -->
    </div>
</section>
<section class="project-section pb-70">
    <div class="container">
        <div class="row">
            <div class="col-md-6">

                <h3 class="text-white">Top 10 Sights</h3>
            </div>
            <div class="col-md-6">

                <div class=" text-md-end"><a routerLink="top-sight" class="project show_more_btn">Find out more</a>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-project-item">
                    <img src="assets/img/home-page/eiffel.jpg" alt="image">

                    <div class="project-content">
                        <h3><a href="#">Eiffel Tower</a></h3>
                        <p>Indulge in the art, culture, and romance of Paris!</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-project-item">
                    <img src="assets/img/home-page/burj.jpg" alt="image">

                    <div class="project-content">
                        <h3><a href="#">Burj Al Arab</a></h3>
                        <p>Discover the vibrant charm of Burj Al Arab</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6 offset-sm-3 offset-lg-0">
                <div class="single-project-item">
                    <img src="assets/img/home-page/taj.jpg" alt="image">

                    <div class="project-content">
                        <h3><a href="#">Taj Mahal</a></h3>
                        <p>Revel in the architectural marvels of India </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="history-section ptb-100">
    <div class="container text-center">
        <div class="row mb-2 align-items-center">
            <div class="col-md-2"></div>
            <div class="col-md-4">
                <h3 class="text-white text-md-end">Map your locations</h3>
            </div>
            <div class="col-md-4">

                <div class="group">
                    <!-- <mat-form-field class="example-full-width">
                            <mat-label>Map your locations</mat-label>
                            <input matInput placeholder="Map your locations" value="">
                        </mat-form-field> -->
                    <!-- <i class="fa-solid fa-arrow-right-long"></i> -->
                    <mat-progress-bar class="white" mode="determinate" value="100"></mat-progress-bar>

                </div>

            </div>
            <div class="col-md-2"></div>
        </div>



    </div>
    <div class="container-fluid p-0">
        <img src="assets/img/home-page/map.jpg" alt="">
    </div>
</section>
<section class="testimonials">
    <div class="container">

        <div class="row">
            <div class="col-md-6">
                <div class="title">
                    <h3 class="text-white">What our customers say about us</h3>
                    <img src="assets/img/home-page/quote.png" alt="">
                </div>
            </div>
            <div class="col-md-6">
                <div class="know_more">
                    <p class="text-center text-md-end"><a href="#" class="show_more_btn">See all →</a></p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 my-3">
                <div class="testi_card">
                    <div class="row">
                        <div class="col-6">
                            <h4>Franklin C</h4>
                        </div>
                        <div class="col-6 text-end"><img src="assets/img/home-page/star.png" alt=""></div>
                    </div>
                    <div class="">
                        <div>
                            <p>Booking flights has never been easier! Gulf Travel and Tourism
                                provided me with a seamless online experience,
                                making my travel plans a breeze.
                            </p>

                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 my-3">
                <div class="testi_card">
                    <div class="row">
                        <div class="col-6">
                            <h4>Tracy D</h4>
                        </div>
                        <div class="col-6 text-end"><img src="assets/img/home-page/star.png" alt=""></div>
                    </div>
                    <div class="">
                        <div>
                            <p>Of all the various portals that I’ve tried, Gulf Travel and Tourism is at the very top of
                                that list.
                                They have everything you need,
                                making life really a breeze.
                            </p>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</section>

<section class="performance-section ptb-100">
    <div class="container">
        <div class="project-title">
            <div class="row">
                <div class="col-md-6">
                    <h3 class="text-white">Flights News and Updates</h3>
                </div>
                <div class="col-md-6">
                    <div class="text-md-end"><a routerLink="blog" class="show_more_btn">See all →</a></div>
                </div>
            </div>


        </div>

        <div class=row>
            <div class="col-6 col-md-3 col-sm-6" *ngFor="let blogs of blog">
                <div class="single-performance">
                    <div>
                        <img src="{{blogs.image}}" alt="">
                    </div>
                    <h3>{{blogs.title}}</h3>
                    <div class="link">
                        <a routerLink="/blog/{{blogs.slug}}/">Read More</a>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class=row>
            <div class="col-6 col-md-3 col-sm-6">
                <div class="single-performance">
                    <div class=circlechart data-percentage=70></div>
                    <h3>Visualizing is a real-time growth</h3>
                </div>
            </div>

            <div class="col-6 col-lg-3 col-md-6 col-sm-6">
                <div class="single-performance">
                    <div class=circlechart data-percentage=50></div>
                    <h3>Generation of revenue For people</h3>
                </div>
            </div>

            <div class="col-6 col-lg-3 col-md-6 col-sm-6">
                <div class="single-performance">
                    <div class=circlechart data-percentage=90></div>
                    <h3>Performance growth by peoples</h3>
                </div>
            </div>

            <div class="col-6 col-lg-3 col-md-6 col-sm-6 offset-lg-0 offset-md-0">
                <div class="single-performance">
                    <div class=circlechart data-percentage=67></div>
                    <h3>A realtime partnership Management</h3>
                </div>
            </div>
        </div> -->
    </div>
</section>

<section class="productive-section">
    <div class="container">

        <div class="row">
            <div class="col-md-6">
                <div class="p-3">
                    <h3>Why Gulf Travel and Tourism?</h3>
                    <p>Gulf Travel and Tourism offers attractive deals, competitive airfares, exclusive discounts, and a
                        smooth
                        online booking process to our customer base. Booking flights, through our user-friendly desktop
                        and mobile site ensures a hassle-free experience.
                        Additionally, we continually enhance our offerings, including unmatched prices, multi-faceted
                        services, ease of accessibility and more, catering to the evolving preferences and demands of
                        people across the
                        UAE and the world.</p>
                </div>
            </div>
            <div class="col-md-6">
                <div class="p-3">
                    <h3>Why book flights via Gulf Travel and Tourism?</h3>
                    <p>At Gulf Travel and Tourism, you can discover fantastic deals and budget-friendly air tickets to
                        your desired
                        destinations by booking through our user-friendly website. Gulf Travel and Tourism ensures
                        affordable and
                        customized flight ticket
                        options to suit your convenience. We prioritize customer satisfaction and provide a dedicated
                        24/7 helpline to
                        address any queries or concerns. We take pride in fulfilling the travel aspirations of
                        individuals seeking a quick and
                        seamless experience in finding air tickets. Start your journey today by securing the most
                        affordable flights of your
                        choice.</p>
                </div>
            </div>
            <div class="col-md-12">
                <div class="text-center">
                    <button routerLink="/about" class="show_more_btn btnabout">About Us <i
                            class="fa-solid fa-arrow-right"></i></button>
                </div>
            </div>
        </div>
        <!-- <div class="productive-title">
            <span>Why Our services</span>
            <h3>Our Productive Services is Ready</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                dolet, consectetur adipiscing seore suspendisse ultrices gravir.</p>
        </div> -->

        <!-- <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="productive-area-content">
                    <div class="icon">
                        <i class="flaticon-headphones"></i>
                    </div>
                    <h3>Customer Assistance</h3>
                    <p>Lorem ipsum dolor sitamet cosectetur adipising elit sed do eiusmod tempor.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="productive-area-content">
                    <div class="icon">
                        <i class="flaticon-tag"></i>
                    </div>
                    <h3>Affordable Prices</h3>
                    <p>Lorem ipsum dolor sitamet cosectetur adipising elit sed do eiusmod tempor.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="productive-area-content">
                    <div class="icon">
                        <i class="flaticon-cyber-security"></i>
                    </div>
                    <h3>Global Presence</h3>
                    <p>Lorem ipsum dolor sitamet cosectetur adipising elit sed do eiusmod tempor.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="productive-area-content">
                    <div class="icon">
                        <i class="flaticon-innovation"></i>
                    </div>
                    <h3>Innovative Ideas</h3>
                    <p>Lorem ipsum dolor sitamet cosectetur adipising elit sed do eiusmod tempor.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="productive-area-content">
                    <div class="icon">
                        <i class="flaticon-content"></i>
                    </div>
                    <h3>Content Marketing</h3>
                    <p>Lorem ipsum dolor sitamet cosectetur adipising elit sed do eiusmod tempor.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="productive-area-content">
                    <div class="icon">
                        <i class="flaticon-unlink"></i>
                    </div>
                    <h3>Pro Building Services</h3>
                    <p>Lorem ipsum dolor sitamet cosectetur adipising elit sed do eiusmod tempor.</p>
                </div>
            </div>
        </div>-->
    </div>
</section>
<section class="product_offering">
    <div class="container">

        <div class="row">
            <div class="col-md-12">
                <div class="p-3">
                    <h4>Product Offering</h4>
                    <p>Flights, International Flights, Hotels, International Hotels, Book Hotels From UAE, Corporate
                        Travel, Book Flights From
                        UAE, Travel Blog.</p>
                    <h4>TOP Hotels in dubai</h4>
                    <p>Burj Al Arab, One&Only The Palm, Raffles The Palm Dubai, Armani Hotel Dubai, Mandarin Oriental
                        Jumeira, Park Hyatt
                        Dubai, Palazzo Versace Dubai,
                        Madinat Jumeirah, Four Seasons Resort Dubai At Jumeirah Beach, Anantara World Islands Dubai
                        Resort.</p>
                    <h4>TOP International Hotles</h4>
                    <p>Adaaran Club Rannalhi, Marina Bay Sands Singapore, Coco Bodu Hithi, Taj Dubai, Atlantis Hotel
                        Dubai, Amari Phuket, Jw
                        Marriott Dubai, Armani Hotel Dubai,
                        Grand Hyatt Dubai, Saii Lagoon Maldives, Gevora Hotel Dubai, Hyatt Regency Dubai, Pan Pacific
                        Singapore, The Palm Dubai,
                        Caesars Palace, Baiyoke Sky Hotel,
                        Centara Pattaya Hotel, Embudu Village, Orchard Hotel Singapore, Reethi Beach Resort.</p>
                    <h4>CORPORATE TRAVEL</h4>
                    <p>Corporate Travel, Corporate Hotel Booking, Corporate Flight Booking, Business Travel for SME, GST
                        Invoice for
                        International flights, Business Travel Solutions,
                        GST Invoice for Bus, Corporate Bus booking, myBiz - Best Business Travel Platform, GST Invoice
                        for Flights, GST Invoice
                        for Corporate Travel,
                        GST Invoice for Hotels, Free cancellation on International Flights</p>
                    <h4>QUICK LINKS
                    </h4>
                    <p>Dubai USA Flights, France Italy Flights, Canada India Flights, USA Japan Flights, London Italy
                        Flights, India Lebnanon
                        Flights</p>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- <section class="needs-section pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="needs-area-content">
                    <span>What We do</span>
                    <h3>Your business agency needs a goal Our agency helps to achieve in the way</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua. Ut enim ad minim venia.</p>
                    <div class="needs-text">
                        <p><b>8.93</b> (Loremips) um dolor sit amet, consectetur adipisicing elit, sed aliquaem ipsum
                            dolor si Ut enim ad minim venia.</p>
                    </div>
                    <div class="needs-text">
                        <p><b>1.9</b> (important) um dolor sit amet, consectetur adipisicing elit, sed aliquaem ipsum
                            dolor si Ut enim ad minim venia.</p>
                    </div>
                    <div class="needs-btn">
                        <a routerLink="/" class="needs-btn-one">Discover More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="needs">
                    <img src="assets/img/needs.jpg" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>



<section class="saying-section ptb-100">
    <div class="container">
        <div class="saying-title">
            <h3>What is saying clients about us</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                dolet, consectetur adipiscing seore suspendisse ultrices gravir.</p>
        </div>

        <div class="saying-slides owl-carousel owl-theme">
            <div class="saying-single-item">
                <div class="saying-content-text">
                    <h3>It’s Amazing Agency i made ever</h3>
                    <p>Lorem ipsum dolor sit ametipisicing elit, sed do eiusmod tempor incididunt ut labore et Lorem
                        ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore
                        et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud.</p>
                </div>

                <div class="saying-info">
                    <div class="say-image">
                        <img src="assets/img/say-image/say-img1.jpg" alt="image">
                    </div>

                    <h4>Ketax Andon</h4>
                    <span>Bussiness Solution</span>
                </div>
            </div>

            <div class="saying-single-item">
                <div class="saying-content-text">
                    <h3>It’s Amazing Agency i made ever</h3>
                    <p>Lorem ipsum dolor sit ametipisicing elit, sed do eiusmod tempor incididunt ut labore et Lorem
                        ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore
                        et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud.</p>
                </div>

                <div class="saying-info">
                    <div class="say-image">
                        <img src="assets/img/say-image/say-img2.jpg" alt="image">
                    </div>
                    <h4>Kolil Ward</h4>
                    <span>CEO Manager</span>
                </div>
            </div>

            <div class="saying-single-item">
                <div class="saying-content-text">
                    <h3>It’s Amazing Agency i made ever</h3>
                    <p>Lorem ipsum dolor sit ametipisicing elit, sed do eiusmod tempor incididunt ut labore et Lorem
                        ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore
                        et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud.</p>
                </div>

                <div class="saying-info">
                    <div class="say-image">
                        <img src="assets/img/say-image/say-img3.jpg" alt="image">
                    </div>
                    <h4>Jandhu Dew</h4>
                    <span>Marketing Manager</span>
                </div>
            </div>

            <div class="saying-single-item">
                <div class="saying-content-text">
                    <h3>It’s Amazing Agency i made ever</h3>
                    <p>Lorem ipsum dolor sit ametipisicing elit, sed do eiusmod tempor incididunt ut labore et Lorem
                        ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore
                        et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud.</p>
                </div>

                <div class="saying-info">
                    <div class="say-image">
                        <img src="assets/img/say-image/say-img4.jpg" alt="image">
                    </div>
                    <h4>Andon Smait</h4>
                    <span>Bussiness Solution</span>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="faq-section ptb-100">
    <div class="container">
        <div class="faq-title">
            <span>Asked Question</span>
            <h3>Frequently Ask Question</h3>
        </div>

        <div class="faq-accordion">
            <ul class="accordion">
                <li class="accordion-item">
                    <a class="accordion-title active" href="javascript:void(0)"><i class="fa fa-plus"></i> A satisfied
                        customer is the best business strategies</a>

                    <p class="accordion-content show">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra
                        maecenas accumsan lacus vel facilisis.
                    </p>
                </li>

                <li class="accordion-item">
                    <a class="accordion-title" href="javascript:void(0)"><i class="fa fa-plus"></i> We challenge
                        everything</a>

                    <p class="accordion-content">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra
                        maecenas accumsan lacus vel facilisis.
                    </p>
                </li>

                <li class="accordion-item">
                    <a class="accordion-title" href="javascript:void(0)"><i class="fa fa-plus"></i> Cooperation is our
                        best policy</a>

                    <p class="accordion-content">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra
                        maecenas accumsan lacus vel facilisis.
                    </p>
                </li>

                <li class="accordion-item">
                    <a class="accordion-title" href="javascript:void(0)"><i class="fa fa-plus"></i> Read the most
                        popular Medical News & Article?</a>

                    <p class="accordion-content">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra
                        maecenas accumsan lacus vel facilisis.
                    </p>
                </li>
            </ul>
        </div>
    </div>
</section>

<section class="provide-section">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="provide-image">
                    <img src="assets/img/provide.jpg" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="provide-area-content">
                    <h3>How to provide service to the customer</h3>
                    <p>Note: Please call us from 12 pm to 8 am. otherwise, our customer supporter will not available to
                        reach your call, but you can drop a mail anytime.</p>

                   
                </div>
            </div>
        </div>
    </div>
</section> -->