<div class="bg-dark py-5">
    <app-navbar-one></app-navbar-one>
    <section>
        <div class="container pt-5">
            <div class="row pt-5">
                <div class="col-md-12 p-0">
                    <div class="bg-white px-4">
                        <div>
                            <div class="row p-2">
                                <div class="col-md-6">
                                    <div class="text-left">
                                        <h3>Traveller Details <span class="name">Abdul</span></h3>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="text-end">
                                        <button><i class="fa-regular fa-pen-to-square"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row p-3 bg-gray"></div>
            <div class="row">
                <div class="col-md-12 p-0">
                    <div class="bg-white px-4">
                        <div class="row p-2">
                            <div class="col-md-12">

                                <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
                                    <mat-tab label="Seats">
                                        <ng-template mat-tab-label class="text-black">
                                            <mat-icon class="text-black">airline_seat_recline_extra</mat-icon>
                                            Seats
                                        </ng-template>
                                        <div class="container">
                                            <div class="row">
                                                <div class="col-md-12 p-0">
                                                    <div class="bg-white px-4">
                                                        <div class="row p-2">
                                                            <div class="col-md-12">
                                                                <div class="bg-yello px-2 rounded">
                                                                    <p class="text-black">Majority of our solo travelers
                                                                        prefer an aisle seat for longer
                                                                        flights. Book now
                                                                        to enjoy higher flexibility to move
                                                                        around during your flight.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-12 p-0">
                                                    <div class="bg-white px-4">
                                                        <div class="row p-2">
                                                            <div class="col-md-8">
                                                                <div class="text-black">
                                                                    Dubai <i class="fa-solid fa-arrow-right-long"></i>
                                                                    Oman
                                                                    <p>1of 1 Seat(s) Selected</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-12 p-0">
                                                    <div class="bg-white px-4">
                                                        <div class="row p-2">
                                                            <div class="col-md-12">
                                                                <img src="../../../../assets/img/seat-map/seat-map.jpg"
                                                                    alt="">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </mat-tab>
                                    <mat-tab label="Meals" class="text-black">
                                        <ng-template mat-tab-label>
                                            <mat-icon class="text-black">fastfood</mat-icon>
                                            Meals
                                        </ng-template>
                                        <div class="container">
                                            <div class="row">
                                                <div class="col-md-12 p-0">
                                                    <div class="bg-white px-4">
                                                        <div class="row p-2">
                                                            <div class="col-md-12">
                                                                <div class="bg-yello px-2 rounded">
                                                                    <p class="text-black">Majority of our solo travelers
                                                                        prefer an aisle seat for longer
                                                                        flights. Book now
                                                                        to enjoy higher flexibility to move
                                                                        around during your flight.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-12 p-0">
                                                    <div class=" px-4">
                                                        <div class="row p-2">
                                                            <div class="col-md-12">
                                                                <div class="text-black bg-gray border-botm">
                                                                    <div class="p-4 py-2">
                                                                        Dubai <i
                                                                            class="fa-solid fa-arrow-right-long"></i>
                                                                        Oman
                                                                        <p>1of 1 Seat(s) Selected</p>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <div class="border-botm" *ngFor="let food of foods">
                                                                        <div class="row py-2 align-items-center px-3">
                                                                            <div class="col-md-6">
                                                                                <div
                                                                                    class="row align-items-center py-2">
                                                                                    <div
                                                                                        class="col-2 position-relative">
                                                                                        <img src="{{food.icon}}" alt="">
                                                                                        <div
                                                                                            class="position-absolute type-img">
                                                                                            <img src="{{food.type}}"
                                                                                                alt="">

                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-9">
                                                                                        <h5 class="mb-0">{{food.name}}
                                                                                        </h5>
                                                                                        <p
                                                                                            class="text-black text-uppercase">
                                                                                            <b>{{food.price}}</b>
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-md-6">
                                                                                <div class="text-end">
                                                                                    <div class="add">
                                                                                        <button
                                                                                            class="add-btn">ADD</button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <div class="bg-gray rounded my-5 p-2">
                                                                            <p class="text-black"><b>Note</b>:- Gulf
                                                                                Travels forwards your
                                                                                meal
                                                                                preferences to the respective airlines.
                                                                                The meal provided to the customers is
                                                                                subject to liability in this regard.</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </mat-tab>

                                </mat-tab-group>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row my-2">
                <div class="col-md-12 p-0">
                    <div class=" px-4">
                        <div class="row p-2">
                            <div class="col-md-12">
                                <button class="btn-nor" routerLink="/flight-final">CONTINUE</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 p-0">
                    <div class="bg-white rounded px-4">
                        <div class="row p-2">
                            <div class="col-md-12">
                                <div class="row">
                                    <h4>Fare Summary</h4>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div>
                                            <p class="text-black mb-0">
                                                Base Fare
                                            </p>
                                            <p class="mb-0">Adults(s) (1x AED1,000)</p>

                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="text-end">
                                            <p class="text-black m-auto">
                                                AED 1,000
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <hr>
                        </div>
                        <div class="row p-2">
                            <div class="col-md-12">

                                <div class="row">
                                    <div class="col-md-6">
                                        <div>
                                            <p class="text-black mb-0">
                                                Taxes & Surcharges
                                            <p class="mb-0">Airline Taxes & Surcharges</p>

                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="text-end">
                                            <p class="text-black m-auto">
                                                AED 500
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row my-4">
                <div class="col-md-12 p-0">
                    <div class="bg-white rounded">
                        <div class="row p-2 pt-0">
                            <div class="col-md-12 p-1 pt-0">
                                <div class="bg-btn p-2 px-4">
                                    <h4 class="text-white">PROMO CODE</h4>
                                </div>
                            </div>
                        </div>
                        <div class="row p-2 pt-0">
                            <div class="col-md-12 p-1 pt-0">
                                <div class="rounded p-2 px-4">
                                    <input type="text" name="" id="" placeholder="Enter Promo Code Here">
                                    <a routerLink="/offers" class="text-primary pt-4">VIEW ALL COUPONS</a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    </section>
</div>