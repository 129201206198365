import { Component } from '@angular/core';

@Component({
  selector: 'app-flight-meals',
  templateUrl: './flight-meals.component.html',
  styleUrls: ['./flight-meals.component.scss']
})
export class FlightMealsComponent {

}
