<div class="top-des">
    <app-navbar-one></app-navbar-one>
    <div class="top-destination-banner">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <div class="hero-content">
                                <h1>Let your dreams take off!</h1>

                                <div class="marketing-btn">
                                    <!-- <a routerLink="/" class="marketing-btn-one">Discover More</a>
                                <a href="https://www.youtube.com/watch?v=bk7McNUjWgw"
                                    class="video-btn popup-youtube"><span>
                                        <i class="fa fa-play"></i></span>
                                    Watch The Demo
                                </a> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <section class="py-5">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <h3 class="text-white">
                        Top 10 <b>Destinations</b>
                    </h3>
                </div>
                <div class="col-md-4 p-1" *ngFor="let img of topdestination">
                    <div class="text-overlay">
                        <img src="{{img.img}}" alt="">
                        <div class="place">
                            <a routerLink="/places/{{img.slug}}">
                                <p>{{img.place}} : {{img.title}}</p>
                                <p class="saas-btn">Explore</p>
                            </a>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </section>
    <!-- <section class="py-5 des">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <h3 class="text-white">
                        Seasonal Destinations
                    </h3>
                </div>
                <div class="col-md-3 p-1" *ngFor="let img of seasonal">
                    <div class="card bg-white">
                        <img src="{{img.img}}" alt="" style="height: 200px;">
                        <div class="p-3">
                            <h4 class="card-title">{{img.name}}</h4>

                            <div class="d-flex flex-wrap gap-2 py-2">
                                <a href="#" *ngFor="let aaa of img.attr">{{aaa}}</a>

                            </div>
                        </div>

                        <hr>
                        <div class="p-3">
                            <div class="d-flex justify-content-between align-items-end last">
                                <div>
                                    <p class="mb-0"> Starting Price</p>
                                    <h4 class="mb-0">AED {{img.price}}</h4>
                                </div>
                                <div>
                                    <a href="#">Details</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </section> -->