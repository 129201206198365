import { Component, OnInit } from '@angular/core';
import topsight from "../../data/top10-sight.json";
import { Observable } from 'rxjs';
import { SharedService } from '../shared/shared.service'
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-top-sight-details',
  templateUrl: './top-sight-details.component.html',
  styleUrls: ['./top-sight-details.component.scss']
})
export class TopSightDetailsComponent implements OnInit {
    public place: any;
    public id: any =[];

    public seasonal: any = [
        {
            id: "1",
            img: "../../../../assets/img/top-destination/sea1.jpg",
            name: "Thailand",
            price: "450.99",
            attr: [" Honeymoon", "Warm Weather", "Beach", "Family with Kids"],
        },
        {
            id: "2",
            img: "../../../../assets/img/top-destination/sea2.jpg",
            name: "Carribean",
            price: "450.99",
            attr: [" Honeymoon", "Warm Weather", "Beach", "Family with Kids"],
        },
        {
            id: "3",
            img: "../../../../assets/img/top-destination/sea3.jpg",
            name: "Mexico ",
            price: "450.99",
            attr: [" Honeymoon", "Warm Weather", "Beach", "Family with Kids"],
        },
        {
            id: "4",
            img: "../../../../assets/img/top-destination/sea4.jpg",
            name: "Trivandrum",
            price: "450.99",
            attr: [" Honeymoon", "Warm Weather", "Beach", "Family with Kids"],
        },
    ];

    constructor(
        public details: SharedService,
        private route: ActivatedRoute
    ) { }
    ngOnInit() {
        const id = this.route.snapshot.paramMap.get('id');
        this.id = id;
        console.log(id)
        this.topplace()
    }

    async topplace() {
        // this.place = await this.details.getService(this.id);
        this.place = this.details.topsights
        console.log(this.place)
        this.place = this.place.find(place => place.slug == this.id);
    }
}
