<div class="page">
    <app-navbar-one></app-navbar-one>
    <section class="content">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-6 offset-md-3">
                            <div class="py-5">
                                <div class="text-center">
                                    <h3>Unveiling Gulf Travel and Tourism:</h3>
                                    <h5>Crafting Your Journey Beyond Boundaries </h5>
                                    <p>In the vast tapestry of travel, where every journey tells a story, Gulf Travel
                                        and Tourism
                                        emerges
                                        as your storyteller, weaving
                                        experiences beyond boundaries. Embark on a seamless expedition, where more than
                                        destinations, we offer passages to
                                        unforgettable moments. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="py-5">
                                <div class="text-left">
                                    <h3>Crafting Your Tale:</h3>
                                    <h5>Why Gulf Travel and Tourism? </h5>
                                    <p>Gulf Travel and Tourism isn't just a platform; it's the architect of your travel
                                        narrative.
                                        Picture this – a user-friendly portal
                                        that unfolds a world of attractive deals, competitive airfares, and exclusive
                                        discounts. This is where your journey
                                        begins, where your story takes flight.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-6 offset-md-6">
                            <div class="py-5">
                                <div class="text-end">
                                    <h3>Beyond Bookings:</h3>
                                    <h5>The Gulf Travel and Tourism Difference </h5>
                                    <p>We transcend the ordinary by redefining travel convenience. Gulf Travel and
                                        Tourism is your
                                        gateway to more than just
                                        budget-friendly air tickets; it's an experience crafted around you. Navigate
                                        effortlessly through our dynamic Fare
                                        Calendar, embrace personalized features like Rewards Program, and so much more. 
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="py-5">
                                <div class="text-left">
                                    <h3>Your Journey, Our Commitment </h3>

                                    <p>Why choose Gulf Travel and Tourism? Because we offer more than flights; we offer
                                        satisfaction. Our commitment echoes in a 24/7
                                        helpline ready to address your queries. Gulf Travel and Tourism is more than a
                                        travel
                                        platform; it's a promise to make every
                                        journey remarkable. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-6 offset-md-6">
                            <div class="py-5">
                                <div class="text-end">
                                    <h3>Seamless Adventures Await </h3>

                                    <p>Your aspirations deserve more than a ticket; they deserve a narrative. Gulf
                                        Travel and Tourism invites you to start your journey
                                        today, where affordability meets customization, and every adventure becomes a
                                        chapter worth remembering.   </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-6 offset-md-3">
                            <div class="py-5">
                                <div class="text-center">
                                    <h3>Embark on a new era of travel</h3>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-6 offset-md-3">
                            <div class="py-5">
                                <div class="text-center">
                                    <p>with</p>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-6 offset-md-3">
                            <div class="py-5">
                                <div class="text-center">
                                    <h1>Gulf Travel and Tourism</h1>
                                    <h3 class="mainclr">crafting journeys, crafting stories </h3>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </section>

</div>