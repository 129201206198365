<app-navbar-one></app-navbar-one>

<div class="marketing-home-area1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 col-md-12">
                        <div class="hero-content">
                            <h1 class="text-black" *ngFor="let item of banner">{{item.title}}</h1>
                            <div class="marketing-btn">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="top-services-section pb-100">
    <div class="container-fluid">
        <div class="sometop">
            <div class="otherservises">
                <div class="services"><a routerLink="/">Flights</a></div>
                <div class="services"><a routerLink="/hotel" class="active">Hotel</a></div>
                <div class="services"><a routerLink="/">Car rental</a></div>
                <div class="services"><a routerLink="/">Explore</a></div>
            </div>
            <div class="top-services-area">
                <div class="row align-items-center">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="top-services-content">
                            <form [formGroup]="hotelsearch">
                                <div class="row">
                                    <div class="col-md-12">
                                        <mat-radio-group aria-label="Select an option">
                                            <mat-radio-button value="1" (click)="disableInputField()"
                                                [checked]="true">Upto
                                                6 Rooms</mat-radio-button>

                                            <!-- <mat-radio-button value="2">Multicity</mat-radio-button> -->
                                        </mat-radio-group>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="from_div">
                                            <!-- <div>
                                                        <img src="assets/img/icon/takeoff.png" alt="" width="50px">
                                                    </div> -->
                                            <div style="width: 100%;">
                                                <mat-form-field class="col-sm-4 inp">

                                                    <mat-select formControlName="hotellocation" placeholder="Location">
                                                        <div class="fixed">

                                                            <mat-form-field [floatLabel]="'never'"
                                                                style="margin: 5px;padding-left: 10px;padding-right: 10px;
                                                                    border: 1.5px solid rgba(216, 220, 230, 0.87); border-radius: 5px;
                                                                    position: absolute;top: 0;width: calc(100% - 30px);background: white;z-index: 100;height:65px;">
                                                                <input matInput placeholder="" style="padding-left:10px"
                                                                    (input)="lookup($event.target.value);" #inputFilter>
                                                                <i class="fa fa-search" matPrefix></i>
                                                                <i class="fa fa-times" matSuffix
                                                                    (click)="clean(inputFilter);"></i>
                                                            </mat-form-field>
                                                        </div>
                                                        <div style="padding-top: 80px">

                                                            <mat-option *ngFor="let data of filteredDataToSearch"
                                                                [value]="data.value">
                                                                {{data.text}}
                                                                <!-- <span>{{data.country}}</span> -->
                                                            </mat-option>
                                                        </div>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <br>
                                        <!-- <div class="from_div">
                                                    <div>
                                                        <img src="assets/img/icon/landing.png" alt="" width="50px">
                                                    </div>
                                                    <div style="width: 80%;">
                                                        <mat-form-field class="col-sm-4 inp">
                                                            <span>To</span>
                                                            <mat-select formControlName="slct_cntrl" placeholder="Location">
                                                                <div class="fixed">

                                                                    <mat-form-field [floatLabel]="'never'"
                                                                        style="margin: 5px;padding-left: 10px;padding-right: 10px;
                                                                                                                                                                                    border: 1.5px solid rgba(216, 220, 230, 0.87); border-radius: 5px;
                                                                                                                                                                                    position: absolute;top: 0;width: calc(100% - 30px);background: white;z-index: 100;height:65px;">
                                                                        <input matInput placeholder="" style="padding-left:10px"
                                                                            (input)="lookup($event.target.value);" #inputFilter>
                                                                        <i class="fa fa-search" matPrefix></i>
                                                                        <i class="fa fa-times" matSuffix
                                                                            (click)="clean(inputFilter);"></i>
                                                                    </mat-form-field>
                                                                </div>
                                                                <div style="padding-top: 80px">

                                                                    <mat-option *ngFor="let data of filteredDataToSearch"
                                                                        [value]="data.value">
                                                                        {{data.text}}
                                                                    </mat-option>
                                                                </div>
                                                            </mat-select>
                                                        </mat-form-field>
                                                    </div>
                                                </div> -->

                                    </div>
                                    <div class="col-md-9">
                                        <div class="row">
                                            <div class="col-md-4">
                                                <div class="date_div">
                                                    <!-- <div style="width: 20%;"><img src="assets/img/icon/date.png" alt=""
                                                                    width="50px"></div> -->
                                                    <div style="width: 100%;"><mat-form-field
                                                            style="width: -webkit-fill-available;">

                                                            <mat-label>Check In - Check Out</mat-label>
                                                            <mat-date-range-input [rangePicker]="picker" [min]="minDate"
                                                                disabled>
                                                                <input matStartDate placeholder="Check In"
                                                                    formControlName="daterangefrom" disabled>
                                                                <input matEndDate placeholder="Check Out"
                                                                    formControlName="daterangeto">
                                                            </mat-date-range-input>

                                                            <mat-datepicker-toggle matIconSuffix
                                                                [for]="picker"></mat-datepicker-toggle>
                                                            <mat-date-range-picker #picker
                                                                disabled="false"></mat-date-range-picker>
                                                        </mat-form-field>


                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="date_div">
                                                    <button (click)="roomguest()">
                                                        <span>Rooms & Guest</span>
                                                        <br>
                                                        <span>{{this.rooms.length}}<span> Room</span>
                                                        </span>
                                                        <div formArrayName="roomdetails">


                                                        </div>

                                                    </button>
                                                    <ng-template #roomguestopen id="memberselector">


                                                        <div class="container p-4 popupbox1">
                                                            <div class="text-end">
                                                                <button mat-raised-button class="m-1 btnclose"
                                                                    matDialogClose="yes"><i
                                                                        class="fa-solid fa-xmark"></i></button>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-6">
                                                                    <p class="text-black">Rooms</p>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <fieldset>
                                                                    <div *ngFor="let room of rooms; let i = index"
                                                                        class="room-container">
                                                                        <div class="row">
                                                                            <div class="col-md-6">
                                                                                <p class="text-black">Room {{ i + 1 }}
                                                                                </p>
                                                                            </div>
                                                                            <div class="col-md-6 text-end text-black">
                                                                                <div *ngIf="i!= 0">
                                                                                    <button type="button"
                                                                                        class="btnremive text-black"
                                                                                        (click)="removeRoom(i)"><i
                                                                                            class="fa-solid fa-xmark"></i>
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <div class="row roomcon">
                                                                                <div class="col-md-6">
                                                                                    <p>Adults</p>
                                                                                </div>
                                                                                <div class="col-md-4 offset-md-2">
                                                                                    <mat-form-field
                                                                                        appearance="outline">
                                                                                        <mat-label>Adults:</mat-label>
                                                                                        <mat-select
                                                                                            [(ngModel)]="room.adults"
                                                                                            [ngModelOptions]="{standalone: true}"
                                                                                            formControlName="adults">
                                                                                            <mat-option
                                                                                                *ngFor="let num of adultsOptions"
                                                                                                [value]="num">
                                                                                                {{num}}
                                                                                            </mat-option>
                                                                                        </mat-select>
                                                                                    </mat-form-field>
                                                                                </div>
                                                                                <div class="col-md-6">
                                                                                    <p>Children</p>
                                                                                </div>
                                                                                <div class="col-md-4 offset-md-2">

                                                                                    <mat-form-field
                                                                                        appearance="outline">
                                                                                        <mat-label>Children:</mat-label>
                                                                                        <mat-select
                                                                                            [(ngModel)]="room.children"
                                                                                            [ngModelOptions]="{standalone: true}"
                                                                                            name="children"
                                                                                            (selectionChange)="onChildrenChange(i)">
                                                                                            <mat-option
                                                                                                *ngFor="let num of childrenOptions"
                                                                                                [value]="num">
                                                                                                {{num}}
                                                                                            </mat-option>
                                                                                        </mat-select>
                                                                                    </mat-form-field>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row">

                                                                                <div class="col-md-4"
                                                                                    *ngFor="let child of room.childrenAges; let j = index">
                                                                                    <div>

                                                                                        <mat-form-field
                                                                                            appearance="outline"
                                                                                            id="childAge{{j}}__{{i}}">
                                                                                            <mat-label>Child {{ j + 1 }}
                                                                                                Age:</mat-label>
                                                                                            <mat-select required
                                                                                                name="childAge{{j}}__{{i}}_1"
                                                                                                [ngModelOptions]="{standalone: true}"
                                                                                                [(ngModel)]="room.childrenAges[j]">
                                                                                                <mat-option
                                                                                                    *ngFor="let age of childAgesOptions;index as k"
                                                                                                    [value]="age">
                                                                                                    {{age}}
                                                                                                </mat-option>

                                                                                            </mat-select>
                                                                                        </mat-form-field>

                                                                                    </div>

                                                                                </div>
                                                                            </div>


                                                                        </div>
                                                                        <div>



                                                                        </div>


                                                                    </div>
                                                                </fieldset>
                                                                <button type="button" class="addroom"
                                                                    (click)="addRoom()">Add
                                                                    Room +</button>

                                                                <!-- </form> -->
                                                            </div>
                                                            <div class="text-end">
                                                                <button mat-raised-button class="m-1 apply"
                                                                    matDialogClose="yes"
                                                                    (click)="submitForm()">Apply</button>
                                                            </div>
                                                        </div>
                                                    </ng-template>




                                                    <ng-template>

                                                        <div formArrayName="teachers">

                                                        </div>
                                                    </ng-template>
                                                </div>
                                            </div>

                                            <div class="col-md-4">
                                                <div class="priceselect">

                                                    <div>

                                                        <mat-form-field>
                                                            <mat-label>Price</mat-label>
                                                            <mat-select [(value)]="priceselect"
                                                                formControlName="pricerange">

                                                                <mat-option class="text-black" value="0to100">AED 0 -
                                                                    AED
                                                                    100</mat-option>
                                                                <mat-option class="text-black" value="100to500">AED 100
                                                                    - AED
                                                                    500</mat-option>
                                                                <mat-option class="text-black" value="500to1000">AED 500
                                                                    - AED
                                                                    1000</mat-option>
                                                                <mat-option class="text-black" value="1000to2000">AED
                                                                    1000 -
                                                                    AED
                                                                    2000</mat-option>
                                                            </mat-select>
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <p class="text-md-end text-black">Trending Searches:</p>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="d-flex recentsearch">
                                            <button>Dubai, UAE</button>
                                            <button>London, United Kingdom </button>
                                            <button>Bangkok, Thailand</button>
                                            <button>London, United Kingdom</button>
                                            <button>Goa, India</button>
                                        </div>
                                    </div>
                                    <div class="col-md-3"></div>
                                    <div class="col-md-4"></div>
                                    <div class="col-md-4">
                                        <div class="submit_btn">
                                            <!-- <input type="submit" routerLink="/hotel-search" value="Search Hotel"> -->
                                            <button (click)="addData()" routerLink="/hotel-search">Search
                                                Hotel</button>
                                        </div>

                                        <!-- [disabled]="!hotelsearch.valid || hotelsearch.pristine"/ -->
                                    </div>
                                    <div class="col-md-4"></div>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<ng-template #passenger>
    <p>Hii</p>
</ng-template>


<section class="what-we-do-section pb-100">
    <div class="container">
        <div class="hotelsec p-4">
            <div class="row align-items-center">

                <div class="col-md-3">
                    <h3 class="text-white">Luxurious Abodes
                        Just a click away!</h3>
                    <p class="text-white">Take your vacation to all new heights
                        with epic hotels complete with world-class
                        amenities.</p>
                </div>
                <div class="col-md-3">
                    <div class="back_whit">
                        <img src="../../../../assets/img/flight/sec2img1.jpg" alt="">
                        <div class="Content">
                            <h4 class="text-black">Atlantis,<br>
                                The Palm</h4>
                            <p class="text-black  mh-23">Experience the epitome of luxury coupled
                                with world class amenties.</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="back_whit">
                        <img src="../../../../assets/img/flight/taj.jpg" alt="">
                        <div class="Content">
                            <h4 class="text-black">The Taj Mahal Palace,
                                Mumbai</h4>
                            <p class="text-black  mh-23">Revel in a piece of history and magnificence
                                at one of the world’s best.</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="back_whit">
                        <img src="../../../../assets/img/flight/plaza.jpg" alt="">
                        <div class="Content">
                            <h4 class="text-black">The Plaza Hotel,
                                New York</h4>
                            <p class="text-black mh-23">Waltz across the pinnacle of exclusivity
                                at The Big Apple.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</section>

<section class="achieved-section ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-md-5">
                <h3 class="text-white">Hotel Offers</h3>
            </div>
            <div class="col-md-7"></div>
        </div>
        <div class="row">
            <div class="col-md-6 my-3" *ngFor="let offers of offer | slice:0:4">
                <mat-card>
                    <mat-card-content>
                        <div class="d-flex align-items-center grand_offer_cards">
                            <div class="img">
                                <img src="{{offers.img}}" alt="">

                            </div>
                            <div>
                                <span>
                                    Flights
                                </span>
                                <h4>{{offers.name}}</h4>
                                <hr>
                                <span>{{offers.description}}</span>

                            </div>

                        </div>
                        <div class="d-flex align-items-center justify-content-between down_sec">
                            <div class="tc">
                                <span>T&C's Apply</span>
                            </div>
                            <div>
                                <a routerLink="/offers/{{offers.slug}}">BOOK NOW</a>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
        <div class="text-center viewqww">
            <a routerLink="/offer/" class="btn-all">View All <i class="fa-solid fa-arrow-right"></i></a>
        </div>
    </div>
</section>


<section class="project-section pb-70">
    <div class="container">


        <div class="row">
            <div class="col-md-4 col-6" *ngFor="let destinations of topdestination">
                <a routerLink="/places/{{destinations.slug}}">
                    <div class="sometext">
                        <img src="{{destinations.img}}" alt="image">

                        <div class="project-content flex-shrink-1">
                            <h5 class="text-white"><a href="#" class="text-white">{{destinations.place}}</a></h5>
                            <p class="text-white">{{destinations.title}}</p>
                        </div>
                    </div>
                </a>
            </div>

        </div>
    </div>
</section>

<section class="history-section ptb-100">
    <div class="container text-center">
        <div class="row mb-2 align-items-center">
            <div class="col-md-2"></div>
            <div class="col-md-4">
                <h3 class="text-white text-md-end">Map your locations</h3>
            </div>
            <div class="col-md-4">
                <form class="example-form">
                    <div class="group">
                        <mat-progress-bar class="white" mode="determinate" value="100"></mat-progress-bar>

                    </div>
                </form>
            </div>
            <div class="col-md-2"></div>
        </div>



    </div>
    <div class="container-fluid p-0">
        <img src="assets/img/home-page/map.jpg" alt="">
    </div>
</section>
<section class="testimonials">
    <div class="container">

        <div class="row">
            <div class="col-md-6">
                <div class="title">
                    <h3 class="text-white">What our customers say about us</h3>
                    <img src="assets/img/home-page/quote.png" alt="">
                </div>
            </div>
            <div class="col-md-6">
                <div class="know_more">
                    <p class="text-center text-md-end"><a routerLink="#" class="show_more_btn">See all →</a></p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 my-3">
                <div class="testi_card">
                    <div class="row">
                        <div class="col-6">
                            <h4>Franklin C</h4>
                        </div>
                        <div class="col-6 text-end"><img src="assets/img/home-page/star.png" alt=""></div>
                    </div>
                    <div class="">
                        <div>
                            <p>Booking flights has never been easier! Gulf Travel and Tourism
                                provided me with a seamless online experience,
                                making my travel plans a breeze.
                            </p>

                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 my-3">
                <div class="testi_card">
                    <div class="row">
                        <div class="col-6">
                            <h4>Tracy D</h4>
                        </div>
                        <div class="col-6 text-end"><img src="assets/img/home-page/star.png" alt=""></div>
                    </div>
                    <div class="">
                        <div>
                            <p>Of all the various portals that I’ve tried, Gulf Travel and Tourism is at the very top of
                                that list.
                                They have everything you need,
                                making life really a breeze.
                            </p>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</section>

<section class="performance-section ptb-100">
    <div class="container">
        <div class="project-title">
            <div class="row">
                <div class="col-md-6">
                    <h3 class="text-white">Flights News and Updates</h3>
                </div>
                <div class="col-md-6">
                    <div class="text-md-end"><a routerLink="/../blog" class="show_more_btn">See all →</a></div>
                </div>
            </div>


        </div>

        <div class=row>
            <div class="col-6 col-md-3 col-sm-6" *ngFor="let blogs of blog">
                <div class="single-performance">
                    <div>
                        <img src="{{blogs.image}}" alt="">
                    </div>
                    <h3>{{blogs.title}}</h3>
                    <div class="link">
                        <a routerLink="/blog/{{blogs.slug}}/">Read More</a>
                    </div>
                </div>
            </div>

        </div>
        <!-- <div class=row>
            <div class="col-6 col-md-3 col-sm-6">
                <div class="single-performance">
                    <div class=circlechart data-percentage=70></div>
                    <h3>Visualizing is a real-time growth</h3>
                </div>
            </div>

            <div class="col-6 col-lg-3 col-md-6 col-sm-6">
                <div class="single-performance">
                    <div class=circlechart data-percentage=50></div>
                    <h3>Generation of revenue For people</h3>
                </div>
            </div>

            <div class="col-6 col-lg-3 col-md-6 col-sm-6">
                <div class="single-performance">
                    <div class=circlechart data-percentage=90></div>
                    <h3>Performance growth by peoples</h3>
                </div>
            </div>

            <div class="col-6 col-lg-3 col-md-6 col-sm-6 offset-lg-0 offset-md-0">
                <div class="single-performance">
                    <div class=circlechart data-percentage=67></div>
                    <h3>A realtime partnership Management</h3>
                </div>
            </div>
        </div> -->
    </div>
</section>

<section class="productive-section">
    <div class="container">

        <div class="row">
            <div class="col-md-6">
                <div class="p-3">
                    <h3>Why Gulf Travel and Tourism?</h3>
                    <p>Gulf Travel and Tourism offers attractive deals, competitive airfares, exclusive discounts, and a
                        smooth
                        online booking process to our customer base. Booking flights, through our user-friendly desktop
                        and mobile site ensures a hassle-free experience.
                        Additionally, we continually enhance our offerings, including unmatched prices, multi-faceted
                        services, ease of accessibility and more, catering to the evolving preferences and demands of
                        people across the
                        UAE and the world.</p>
                </div>
            </div>
            <div class="col-md-6">
                <div class="p-3">
                    <h3>Why book flights via Gulf Travel and Tourism?</h3>
                    <p>At Gulf Travel and Tourism, you can discover fantastic deals and budget-friendly air tickets to
                        your desired
                        destinations by booking through our user-friendly website. Gulf Travel and Tourism ensures
                        affordable and
                        customized flight ticket
                        options to suit your convenience. We prioritize customer satisfaction and provide a dedicated
                        24/7 helpline to
                        address any queries or concerns. We take pride in fulfilling the travel aspirations of
                        individuals seeking a quick and
                        seamless experience in finding air tickets. Start your journey today by securing the most
                        affordable flights of your
                        choice.</p>
                </div>
            </div>
            <div class="col-md-12">
                <div class="text-center">
                    <button routerLink="/about" class="show_more_btn btnabout">About Us <i
                            class="fa-solid fa-arrow-right"></i></button>
                </div>
            </div>
        </div>
        <!-- <div class="productive-title">
            <span>Why Our services</span>
            <h3>Our Productive Services is Ready</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                dolet, consectetur adipiscing seore suspendisse ultrices gravir.</p>
        </div> -->

        <!-- <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="productive-area-content">
                    <div class="icon">
                        <i class="flaticon-headphones"></i>
                    </div>
                    <h3>Customer Assistance</h3>
                    <p>Lorem ipsum dolor sitamet cosectetur adipising elit sed do eiusmod tempor.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="productive-area-content">
                    <div class="icon">
                        <i class="flaticon-tag"></i>
                    </div>
                    <h3>Affordable Prices</h3>
                    <p>Lorem ipsum dolor sitamet cosectetur adipising elit sed do eiusmod tempor.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="productive-area-content">
                    <div class="icon">
                        <i class="flaticon-cyber-security"></i>
                    </div>
                    <h3>Global Presence</h3>
                    <p>Lorem ipsum dolor sitamet cosectetur adipising elit sed do eiusmod tempor.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="productive-area-content">
                    <div class="icon">
                        <i class="flaticon-innovation"></i>
                    </div>
                    <h3>Innovative Ideas</h3>
                    <p>Lorem ipsum dolor sitamet cosectetur adipising elit sed do eiusmod tempor.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="productive-area-content">
                    <div class="icon">
                        <i class="flaticon-content"></i>
                    </div>
                    <h3>Content Marketing</h3>
                    <p>Lorem ipsum dolor sitamet cosectetur adipising elit sed do eiusmod tempor.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="productive-area-content">
                    <div class="icon">
                        <i class="flaticon-unlink"></i>
                    </div>
                    <h3>Pro Building Services</h3>
                    <p>Lorem ipsum dolor sitamet cosectetur adipising elit sed do eiusmod tempor.</p>
                </div>
            </div>
        </div>-->
    </div>
</section>
<section class="product_offering">
    <div class="container">

        <div class="row">
            <div class="col-md-12">
                <div class="p-3">
                    <h4>Product Offering</h4>
                    <p>Flights, International Flights, Hotels, International Hotels, Book Hotels From UAE, Corporate
                        Travel, Book Flights From
                        UAE, Travel Blog.</p>
                    <h4>TOP Hotels in dubai</h4>
                    <p>Burj Al Arab, One&Only The Palm, Raffles The Palm Dubai, Armani Hotel Dubai, Mandarin Oriental
                        Jumeira, Park Hyatt
                        Dubai, Palazzo Versace Dubai,
                        Madinat Jumeirah, Four Seasons Resort Dubai At Jumeirah Beach, Anantara World Islands Dubai
                        Resort.</p>
                    <h4>TOP International Hotles</h4>
                    <p>Adaaran Club Rannalhi, Marina Bay Sands Singapore, Coco Bodu Hithi, Taj Dubai, Atlantis Hotel
                        Dubai, Amari Phuket, Jw
                        Marriott Dubai, Armani Hotel Dubai,
                        Grand Hyatt Dubai, Saii Lagoon Maldives, Gevora Hotel Dubai, Hyatt Regency Dubai, Pan Pacific
                        Singapore, The Palm Dubai,
                        Caesars Palace, Baiyoke Sky Hotel,
                        Centara Pattaya Hotel, Embudu Village, Orchard Hotel Singapore, Reethi Beach Resort.</p>
                    <h4>CORPORATE TRAVEL</h4>
                    <p>Corporate Travel, Corporate Hotel Booking, Corporate Flight Booking, Business Travel for SME, GST
                        Invoice for
                        International flights, Business Travel Solutions,
                        GST Invoice for Bus, Corporate Bus booking, myBiz - Best Business Travel Platform, GST Invoice
                        for Flights, GST Invoice
                        for Corporate Travel,
                        GST Invoice for Hotels, Free cancellation on International Flights</p>
                    <h4>QUICK LINKS
                    </h4>
                    <p>Dubai USA Flights, France Italy Flights, Canada India Flights, USA Japan Flights, London Italy
                        Flights, India Lebnanon
                        Flights</p>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- <section class="needs-section pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="needs-area-content">
                    <span>What We do</span>
                    <h3>Your business agency needs a goal Our agency helps to achieve in the way</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua. Ut enim ad minim venia.</p>
                    <div class="needs-text">
                        <p><b>8.93</b> (Loremips) um dolor sit amet, consectetur adipisicing elit, sed aliquaem ipsum
                            dolor si Ut enim ad minim venia.</p>
                    </div>
                    <div class="needs-text">
                        <p><b>1.9</b> (important) um dolor sit amet, consectetur adipisicing elit, sed aliquaem ipsum
                            dolor si Ut enim ad minim venia.</p>
                    </div>
                    <div class="needs-btn">
                        <a routerLink="/" class="needs-btn-one">Discover More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="needs">
                    <img src="assets/img/needs.jpg" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>



<section class="saying-section ptb-100">
    <div class="container">
        <div class="saying-title">
            <h3>What is saying clients about us</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                dolet, consectetur adipiscing seore suspendisse ultrices gravir.</p>
        </div>

        <div class="saying-slides owl-carousel owl-theme">
            <div class="saying-single-item">
                <div class="saying-content-text">
                    <h3>It’s Amazing Agency i made ever</h3>
                    <p>Lorem ipsum dolor sit ametipisicing elit, sed do eiusmod tempor incididunt ut labore et Lorem
                        ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore
                        et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud.</p>
                </div>

                <div class="saying-info">
                    <div class="say-image">
                        <img src="assets/img/say-image/say-img1.jpg" alt="image">
                    </div>

                    <h4>Ketax Andon</h4>
                    <span>Bussiness Solution</span>
                </div>
            </div>

            <div class="saying-single-item">
                <div class="saying-content-text">
                    <h3>It’s Amazing Agency i made ever</h3>
                    <p>Lorem ipsum dolor sit ametipisicing elit, sed do eiusmod tempor incididunt ut labore et Lorem
                        ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore
                        et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud.</p>
                </div>

                <div class="saying-info">
                    <div class="say-image">
                        <img src="assets/img/say-image/say-img2.jpg" alt="image">
                    </div>
                    <h4>Kolil Ward</h4>
                    <span>CEO Manager</span>
                </div>
            </div>

            <div class="saying-single-item">
                <div class="saying-content-text">
                    <h3>It’s Amazing Agency i made ever</h3>
                    <p>Lorem ipsum dolor sit ametipisicing elit, sed do eiusmod tempor incididunt ut labore et Lorem
                        ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore
                        et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud.</p>
                </div>

                <div class="saying-info">
                    <div class="say-image">
                        <img src="assets/img/say-image/say-img3.jpg" alt="image">
                    </div>
                    <h4>Jandhu Dew</h4>
                    <span>Marketing Manager</span>
                </div>
            </div>

            <div class="saying-single-item">
                <div class="saying-content-text">
                    <h3>It’s Amazing Agency i made ever</h3>
                    <p>Lorem ipsum dolor sit ametipisicing elit, sed do eiusmod tempor incididunt ut labore et Lorem
                        ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore
                        et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud.</p>
                </div>

                <div class="saying-info">
                    <div class="say-image">
                        <img src="assets/img/say-image/say-img4.jpg" alt="image">
                    </div>
                    <h4>Andon Smait</h4>
                    <span>Bussiness Solution</span>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="faq-section ptb-100">
    <div class="container">
        <div class="faq-title">
            <span>Asked Question</span>
            <h3>Frequently Ask Question</h3>
        </div>

        <div class="faq-accordion">
            <ul class="accordion">
                <li class="accordion-item">
                    <a class="accordion-title active" href="javascript:void(0)"><i class="fa fa-plus"></i> A satisfied
                        customer is the best business strategies</a>

                    <p class="accordion-content show">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra
                        maecenas accumsan lacus vel facilisis.
                    </p>
                </li>

                <li class="accordion-item">
                    <a class="accordion-title" href="javascript:void(0)"><i class="fa fa-plus"></i> We challenge
                        everything</a>

                    <p class="accordion-content">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra
                        maecenas accumsan lacus vel facilisis.
                    </p>
                </li>

                <li class="accordion-item">
                    <a class="accordion-title" href="javascript:void(0)"><i class="fa fa-plus"></i> Cooperation is our
                        best policy</a>

                    <p class="accordion-content">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra
                        maecenas accumsan lacus vel facilisis.
                    </p>
                </li>

                <li class="accordion-item">
                    <a class="accordion-title" href="javascript:void(0)"><i class="fa fa-plus"></i> Read the most
                        popular Medical News & Article?</a>

                    <p class="accordion-content">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra
                        maecenas accumsan lacus vel facilisis.
                    </p>
                </li>
            </ul>
        </div>
    </div>
</section>

<section class="provide-section">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="provide-image">
                    <img src="assets/img/provide.jpg" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="provide-area-content">
                    <h3>How to provide service to the customer</h3>
                    <p>Note: Please call us from 12 pm to 8 am. otherwise, our customer supporter will not available to
                        reach your call, but you can drop a mail anytime.</p>

                    <form class="provide-form">
                        <input type="email" class="input-newsletter" placeholder="Enter your email" name="EMAIL"
                            required>
                        <button type="submit">Submit Questions</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section> -->