import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { SharedService } from '../shared/shared.service'
import { ActivatedRoute } from '@angular/router';
import blog from "../../data/blog.json";

@Component({
    selector: 'app-blog',
    templateUrl: './blog.component.html',
    styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {
    public blog: any = blog;
    constructor() { }

    ngOnInit(): void {
    }



}
