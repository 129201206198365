import { Component, OnInit } from '@angular/core';
import top10sight from "../../data/top10-sight.json";
import { Observable } from 'rxjs';
import { SharedService } from '../shared/shared.service'
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-top-sight',
    templateUrl: './top-sight.component.html',
    styleUrls: ['./top-sight.component.scss']
})
export class TopSightComponent {
    constructor() { }

    public topsight: any = top10sight;

    public seasonal: any = [
        {
            id: "1",
            img: "../../../../assets/img/top-destination/sea1.jpg",
            name: "Thailand",
            price: "450.99",
            attr: [" Honeymoon", "Warm Weather", "Beach", "Family with Kids"],
        },
        {
            id: "2",
            img: "../../../../assets/img/top-destination/sea2.jpg",
            name: "Carribean",
            price: "450.99",
            attr: [" Honeymoon", "Warm Weather", "Beach", "Family with Kids"],
        },
        {
            id: "3",
            img: "../../../../assets/img/top-destination/sea3.jpg",
            name: "Mexico ",
            price: "450.99",
            attr: [" Honeymoon", "Warm Weather", "Beach", "Family with Kids"],
        },
        {
            id: "4",
            img: "../../../../assets/img/top-destination/sea4.jpg",
            name: "Trivandrum",
            price: "450.99",
            attr: [" Honeymoon", "Warm Weather", "Beach", "Family with Kids"],
        },
    ];

    ngOnInit(): void {

    }


}
