<div class="page" >
    <app-navbar-one></app-navbar-one>

    <div class="top-home-area122" style="background: url('{{place.img}}');background-size: cover;background-repeat: no-repeat;">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <div class="hero-content">

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <section>

        <div class="container py-5 offer-der">
            <div class="row">
                <div class="col-md-12">
                    <div class="text-center">
                        <img src="{{place.logo}}" alt="" width="200px">
                    </div>
                    <div class="card  bg-white my-3">
                        <div class="p-3">
                            <p class="text-black">{{place.name}}</p>
                            <p class="text-black">{{place.description}}</p>
                            <div class="d-flex justify-content-between">
                                <div>
                                    <p>Use Code: <span class="text-black">{{place.coupon}}</span></p>
                                </div>
                                <div>
                                    <a href="#">BOOK NOW</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </section>
</div>
