<app-navbar-one></app-navbar-one>
<div>
    <div class="offer-home-area" style="background-image: url({{blog.image}});">
        <div class="d-table">
            <div class="d-table-cell">

            </div>

        </div>
    </div>

    <section class="blog-section py-4">
        <div class="container">

            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <div class="hero-content">
                        <h1>{{blog.title}}</h1>
                    </div>
                    <div class="description">
                        <p>{{blog.description}}</p>
                        <p>{{blog.description1}}</p>
                        <p>{{blog.description2}}</p>
                        <p>{{blog.description3}}</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="container">
            <div class="profile-header">
                <div class="profile-img">
                    <img src="../../../../assets/img/marketing-agency-bg.jpg" width="200" alt="Profile Image">
                </div>
                <div class="profile-nav-info">
                    <h3 class="user-name">Bright Code</h3>

                </div>
            </div>
        </div>
    </section>
</div>
