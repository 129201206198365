<app-navbar-one></app-navbar-one>

<div class="offer-home-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 col-md-12">
                        <div class="hero-content">
                            <h1>Blogs</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="blog-section ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6" *ngFor="let blog of blog | slice:0:1">
                <a routerLink="/blog/{{blog.slug}}">
                    <div class="blog-item">
                        <div class="blog-image">
                            <a routerLink="/blog/{{blog.slug}}"><img src="{{blog.image}}" alt="image"
                                    class="blog-img"></a>
                        </div>

                        <div class="single-blog-item">
                            <div class="blog-content">
                                <a routerLink="/blog/{{blog.slug}}">
                                    <h3>{{blog.title}}</h3>
                                </a>
                                <p>{{blog.short}}</p>
                            </div>

                            <ul class="blog-list">
                                <li><a routerLink="/"><i class="flaticon-appointment"></i>{{blog.dateofpublish}}</a></li>
                                <li><i class="flaticon-user"></i>{{blog.author}}</li>
                            </ul>
                        </div>
                    </div>
                </a>
            </div>

            <div class="col-lg-8 col-md-6" *ngFor="let blog of blog | slice:1:2">
                <a routerLink="/blog/{{blog.slug}}">
                    <div class="blog-item">
                        <div class="blog-image">
                            <a routerLink="/blog/{{blog.slug}}"><img src="{{blog.image}}" alt="image"
                                    class="blog-img"></a>
                        </div>

                        <div class="single-blog-item">
                            <div class="blog-content">
                                <a routerLink="/blog/{{blog.slug}}">
                                    <h3>{{blog.title}}</h3>
                                </a>
                                <p>{{blog.short}}</p>
                            </div>

                            <ul class="blog-list">
                                <li><a routerLink="/"><i class="flaticon-appointment"></i>{{blog.dateofpublish}}</a></li>
                                <li><i class="flaticon-user"></i>{{blog.author}}</li>
                            </ul>
                        </div>
                    </div>
                </a>
            </div>

            <div class="col-lg-8 col-md-6" *ngFor="let blog of blog | slice:2:3">
                <a routerLink="/blog/{{blog.slug}}">
                    <div class="blog-item">
                        <div class="blog-image">
                            <a routerLink="/blog/{{blog.slug}}"><img src="{{blog.image}}" alt="image"
                                    class="blog-img"></a>
                        </div>

                        <div class="single-blog-item">
                            <div class="blog-content">
                                <a routerLink="/blog/{{blog.slug}}">
                                    <h3>{{blog.title}}</h3>
                                </a>
                                <p>{{blog.short}}</p>
                            </div>

                            <ul class="blog-list">
                                <li><a routerLink="/"><i class="flaticon-appointment"></i>{{blog.dateofpublish}}</a></li>
                                <li><i class="flaticon-user"></i>{{blog.author}}</li>
                            </ul>
                        </div>
                    </div>
                </a>
            </div>

            <div class="col-lg-4 col-md-6" *ngFor="let blog of blog | slice:3:4">
                <a routerLink="/blog/{{blog.slug}}">
                    <div class="blog-item">
                        <div class="blog-image">
                            <a routerLink="/blog/{{blog.slug}}"><img src="{{blog.image}}" alt="image"
                                    class="blog-img"></a>
                        </div>

                        <div class="single-blog-item">
                            <div class="blog-content">
                                <a routerLink="/blog/{{blog.slug}}">
                                    <h3>{{blog.title}}</h3>
                                </a>
                                <p>{{blog.short}}</p>
                            </div>

                            <ul class="blog-list">
                                <li><a routerLink="/"><i class="flaticon-appointment"></i>{{blog.dateofpublish}}</a></li>
                                <li><i class="flaticon-user"></i>{{blog.author}}</li>
                            </ul>
                        </div>
                    </div>
                </a>
            </div>
            <div class="row">
                <div class="col-lg-4 col-md-6" *ngFor="let blog of blog | slice:4:7">
                    <a routerLink="/blog/{{blog.slug}}">

                        <div class="blog-item">
                            <div class="blog-image">
                                <a routerLink="/blog/{{blog.slug}}"><img src="{{blog.image}}" alt="image"
                                        class="blog-img"></a>
                            </div>

                            <div class="single-blog-item">
                                <div class="blog-content">
                                    <a routerLink="/blog/{{blog.slug}}">
                                        <h3>{{blog.id}}{{blog.title}}</h3>
                                    </a>
                                    <p>{{blog.short}}</p>
                                </div>

                                <ul class="blog-list">
                                    <li><a routerLink="/"><i class="flaticon-appointment"></i>{{blog.dateofpublish}}</a></li>
                                    <li><i class="flaticon-user"></i>{{blog.author}}</li>
                                </ul>
                            </div>
                        </div>
                    </a>
                </div>

            </div>
        </div>
    </div>
</section>
