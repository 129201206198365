import { Injectable } from "@angular/core";
import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
// import { rejects } from "assert";
import { Observable } from "rxjs";
import { map, filter, switchMap } from "rxjs/operators";
import { Router } from "@angular/router";
import top10 from "../../data/top10-destinations.json";
import top10sight from "../../data/top10-sight.json";
import offer from "../../data/offer.json";
import blog from "../../data/blog.json";


@Injectable({
    providedIn: "root",
})
export class SharedService {
    public id: any = 1;
    public topdetails: any = top10;
    public topdetail: any = top10;
    public topsight : any = top10sight;
    public topsights : any = top10sight;
    public offer : any = offer;
    public offers : any = offer;
    public blog : any = blog;
    public blogs : any = blog;

    constructor(private router: Router, private http: HttpClient) { }


    public getService(id:any) {
        this.topdetail = this.topdetails.find(topdetails => topdetails.id == 1);
        console.log(this.topdetails)
    }

    public getSight(id:any) {
        this.topsight = this.topsights.find(topsights => topsights.id == 1);
        console.log(this.topsights)
    }

    scrollToElementById(id: string) {
        const element = this.__getElementById(id);
        this.scrollToElement(element);
    }

    private __getElementById(id: string): HTMLElement {
        console.log("element id : ", id);
        // const element = <HTMLElement>document.querySelector(`#${id}`);
        const element = document.getElementById(id);
        return element;
    }

    scrollToElement(element: HTMLElement) {
        element.scrollIntoView({ behavior: "smooth" });
    }
}


