<app-navbar-three></app-navbar-three>

<div class="login-section ptb-100 bg-dark">
    <div class="container">
        <div class="row">
            <div class="col-6"></div>
            <div class="col-6">
                <div class="login-form">
                    <main class="main">
                        <div class="container">
                            <section class="wrapper">
                                <div class="heading">
                                    <h1 class="text text-large text-center mb-2">Login/Sign Up </h1>
                                </div>
                                <div class="social-container m-auto text-center my-2">
                                    <a href="#" class="social"><img src="../../../../assets/img/social/google.svg"></a>
                                </div>
                                <div class="striped">
                                    <span class="striped-line"></span>
                                    <span class="striped-text">Or</span>
                                    <span class="striped-line"></span>
                                </div>
                                <!-- <form [formGroup]="form" style="width: 100%; display: flex;"> -->
                                <form style="width: 100%;">

                                    <mat-form-field class="example-full-width">
                                        <mat-icon matPrefix color="primary">login</mat-icon>
                                        <mat-label>Email Address</mat-label>
                                        <input matInput placeholder="" value="">
                                    </mat-form-field>

                                    <mat-form-field class="example-full-width">
                                        <mat-icon matPrefix color="primary">password</mat-icon>
                                        <mat-label>Password</mat-label>
                                        <input matInput placeholder="" value="">
                                    </mat-form-field>


                                    <button class="my-2 saas-btn" color="primary" >
                                        Login
                                    </button>
                                </form>

                            </section>
                        </div>
                    </main>
                </div>
            </div>
        </div>

    </div>
    <div class="bg-text-stroke">
        <span>We are </span>
        <span>VALORANT</span>
    </div>
</div>
