import { BrowserModule } from "@angular/platform-browser";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatDialogModule } from "@angular/material/dialog";
import { HttpClientModule } from "@angular/common/http";
import { SweetAlert2Module } from "@sweetalert2/ngx-sweetalert2";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { PreloaderComponent } from "./components/layouts/preloader/preloader.component";
import { FooterComponent } from "./components/layouts/footer/footer.component";
import { SaasHomeComponent } from "./components/pages/saas-home/saas-home.component";
import { NavbarOneComponent } from "./components/layouts/navbar-one/navbar-one.component";
import { MarketingHomeComponent } from "./components/pages/marketing-home/marketing-home.component";
import { StartupHomeComponent } from "./components/pages/startup-home/startup-home.component";
import { SeoAgencyHomeComponent } from "./components/pages/seo-agency-home/seo-agency-home.component";
import { NavbarTwoComponent } from "./components/layouts/navbar-two/navbar-two.component";
import { NavbarThreeComponent } from "./components/layouts/navbar-three/navbar-three.component";
import { NavbarFourComponent } from "./components/layouts/navbar-four/navbar-four.component";
import { AboutComponent } from "./components/pages/about/about.component";
import { ServicesComponent } from "./components/pages/services/services.component";
import { ServicesDetailsComponent } from "./components/pages/services-details/services-details.component";
import { ProjectsComponent } from "./components/pages/projects/projects.component";
import { ProjectsDetailsComponent } from "./components/pages/projects-details/projects-details.component";
import { TeamComponent } from "./components/pages/team/team.component";
import { FaqComponent } from "./components/pages/faq/faq.component";
import { PricingComponent } from "./components/pages/pricing/pricing.component";
import { LoginComponent } from "./components/pages/login/login.component";
import { SignupComponent } from "./components/pages/signup/signup.component";
import { ErrorComponent } from "./components/pages/error/error.component";
import { ComingSoonComponent } from "./components/pages/coming-soon/coming-soon.component";
import { ProductsListComponent } from "./components/pages/products-list/products-list.component";
import { ProductsDetailsComponent } from "./components/pages/products-details/products-details.component";
import { CartComponent } from "./components/pages/cart/cart.component";
import { CheckoutComponent } from "./components/pages/checkout/checkout.component";
import { BlogComponent } from "./components/pages/blog/blog.component";
import { BlogDetailsComponent } from "./components/pages/blog-details/blog-details.component";
import { ContactComponent } from "./components/pages/contact/contact.component";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatCardModule } from "@angular/material/card";
import { MatRadioModule } from "@angular/material/radio";
import { MAT_RADIO_DEFAULT_OPTIONS } from "@angular/material/radio";
import { Component } from "@angular/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatNativeDateModule } from "@angular/material/core";
import { MatTabsModule } from "@angular/material/tabs";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { FormGroup } from "@angular/forms";
import { JsonPipe } from "@angular/common";
import { ClipboardModule } from "@angular/cdk/clipboard";
import { MatIconModule } from "@angular/material/icon";
import {
    FormControl,
    FormGroupDirective,
    NgForm,
    Validators,
} from "@angular/forms";
import { ErrorStateMatcher } from "@angular/material/core";
import { NgIf } from "@angular/common";
import { HotelComponent } from "./components/pages/hotel/hotel.component";
import { HotelDetailsComponent } from "./components/pages/hotel-details/hotel-details.component";
import { HotelFormComponent } from "./components/pages/components/hotel-form/hotel-form.component";
import { HotelSearchComponent } from "./components/pages/hotel-search/hotel-search.component";
import { HotelBookingFormComponent } from "./components/pages/hotel-booking-form/hotel-booking-form.component";
import { HotelThankYouComponent } from "./components/pages/hotel-thank-you/hotel-thank-you.component";
import { FlightThankYouComponent } from "./components/pages/flight-thank-you/flight-thank-you.component";
import { NgxIntlTelInputModule } from "ngx-intl-tel-input";
import { TopDestinationComponent } from "./components/pages/top-destination/top-destination.component";
import { TopSightComponent } from "./components/pages/top-sight/top-sight.component";
import { OfferComponent } from "./components/pages/offer/offer.component";
import { OffersComponent } from "./components/pages/offers/offers.component";
import { FlightSearchComponent } from "./components/pages/flight-search/flight-search.component";
import { FlightBookingFormComponent } from "./components/pages/flight-booking-form/flight-booking-form.component";
import { FlightSeatMapComponent } from "./components/pages/flight-seat-map/flight-seat-map.component";
import { FlightMealsComponent } from "./components/pages/flight-meals/flight-meals.component";
import { FlightFinalGothroughComponent } from "./components/pages/flight-final-gothrough/flight-final-gothrough.component";
import { FlightPaymentComponent } from "./components/pages/flight-payment/flight-payment.component";
import { FlightSearchTopComponent } from "./components/pages/components/flight-search-top/flight-search-top.component";
import { MatChipsModule } from "@angular/material/chips";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatButtonModule } from "@angular/material/button";
import { MatSliderModule } from "@angular/material/slider";
import { A11yModule } from "@angular/cdk/a11y";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { PortalModule } from "@angular/cdk/portal";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { CdkStepperModule } from "@angular/cdk/stepper";
import { CdkTableModule } from "@angular/cdk/table";
import { CdkTreeModule } from "@angular/cdk/tree";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatBadgeModule } from "@angular/material/badge";
import { MatBottomSheetModule } from "@angular/material/bottom-sheet";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatStepperModule } from "@angular/material/stepper";
import { MatDividerModule } from "@angular/material/divider";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatRippleModule } from "@angular/material/core";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { Sort, MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatTreeModule } from "@angular/material/tree";
import {
    MomentDateAdapter,
    MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from "@angular/material-moment-adapter";
import {
    DateAdapter,
    MAT_DATE_FORMATS,
    MAT_DATE_LOCALE,
} from "@angular/material/core";
import { MatDatepicker } from "@angular/material/datepicker";
import { ProfileComponent } from "./components/pages/profile/profile.component";
import { BookingsComponent } from "./components/pages/bookings/bookings.component";
import { HttpClient } from "@angular/common/http";
import { ViewChild, AfterViewInit } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort, SortDirection } from "@angular/material/sort";
import { merge, Observable, of as observableOf } from "rxjs";
import { catchError, map, startWith, switchMap } from "rxjs/operators";
import { DatePipe } from "@angular/common";
import { TopDestinationDetailsComponent } from "./components/pages/top-destination-details/top-destination-details.component";
import { TopSightDetailsComponent } from "./components/pages/top-sight-details/top-sight-details.component";

import { FormBuilder } from "@angular/forms";

// import { MatTableDataSource } from "@angular/material/table";
@NgModule({
    declarations: [
        AppComponent,
        PreloaderComponent,
        FooterComponent,
        SaasHomeComponent,
        NavbarOneComponent,
        MarketingHomeComponent,
        StartupHomeComponent,
        SeoAgencyHomeComponent,
        NavbarTwoComponent,
        NavbarThreeComponent,
        NavbarFourComponent,
        AboutComponent,
        ServicesComponent,
        ServicesDetailsComponent,
        ProjectsComponent,
        ProjectsDetailsComponent,
        TeamComponent,
        FaqComponent,
        PricingComponent,
        LoginComponent,
        SignupComponent,
        ErrorComponent,
        ComingSoonComponent,
        ProductsListComponent,
        ProductsDetailsComponent,
        CartComponent,
        CheckoutComponent,
        BlogComponent,
        BlogDetailsComponent,
        ContactComponent,
        HotelComponent,
        HotelDetailsComponent,
        HotelFormComponent,
        HotelFormComponent,
        HotelSearchComponent,
        HotelBookingFormComponent,
        HotelThankYouComponent,
        FlightThankYouComponent,
        TopDestinationComponent,
        TopSightComponent,
        OfferComponent,
        OffersComponent,
        FlightSearchComponent,
        FlightBookingFormComponent,
        FlightSeatMapComponent,
        FlightMealsComponent,
        FlightFinalGothroughComponent,
        FlightPaymentComponent,
        FlightSearchTopComponent,
        ProfileComponent,
        BookingsComponent,
        TopDestinationDetailsComponent,
        TopSightDetailsComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserModule,
        FormsModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        ReactiveFormsModule,
        MatInputModule,
        MatSelectModule,
        MatCardModule,
        MatRadioModule,
        MatFormFieldModule,
        MatProgressBarModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatTabsModule,
        MatDialogModule,
        NgIf,
        JsonPipe,
        NgxIntlTelInputModule,
        ClipboardModule,
        SweetAlert2Module,
        MatChipsModule,
        MatButtonToggleModule,
        MatButtonModule,
        MatSliderModule,
        MatIconModule,
        MatProgressSpinnerModule,
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        DatePipe,
        MatCheckboxModule,
    ],
    providers: [
        {
            provide: MAT_RADIO_DEFAULT_OPTIONS,
            useValue: { color: "#000" },
        },
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    bootstrap: [AppComponent],
})
export class AppModule {}
