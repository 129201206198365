import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import {
    FormBuilder,
    FormGroup,
    Form,
    Validators,
    FormControl,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { SharedService } from "../shared/shared.service";
import { Router, ActivatedRoute } from "@angular/router";
import { Location } from "@angular/common";

@Component({
    selector: "app-hotel-details",
    templateUrl: "./hotel-details.component.html",
    styleUrls: ["./hotel-details.component.scss"],
})
export class HotelDetailsComponent implements OnInit {
    currentSection = "overviews";
    public section = "overviews";
    // sections = ["overviews", "rooms", "location", "amenities", "reviews"];
    //  currentSection = 'section1';
    constructor(
        public shared: SharedService,
        private _router: Router,
        private _route: ActivatedRoute,
        private _location: Location
    ) {}

    scrollToId(id: string) {
        console.log("element id : ", id);
        this.shared.scrollToElementById(id);
    }
    ngOnInit() {
        // const element1 = document.getElementById("pagemenu");

        // element1.addEventListener("scroll", () => {
        //     if (
        //         element1.scrollTop + element1.clientHeight >=
        //         element1.scrollHeight
        //     ) {
        //         element1.classList.add("active");
        //     }
        //     console.log(element1.scrollTop);
        //     console.log(element1.clientHeight);
        //     console.log(element1.scrollHeight);
        // });
        this._route.params.subscribe((params) => {
            this.currentSection = params["sectionId"];
            this.scrollTo(this.currentSection);
        });
    }

    // add active class on button according to the section in the view?

    onSectionChange(sectionId: string) {
        console.log(sectionId);
        this._location.go(sectionId);
        this.currentSection = sectionId;
        console.log(this.currentSection);
    }

    scrollTo(section) {
        if (section == null) {
        } else {
            document.querySelector("#" + section).scrollIntoView();
        }
    }
    public hotel_rooms: any = [
        {
            Name: ["Luxury Room,1 Queen Bed"],
            BookingCode: "1033602!TB!2!TB!968fd4dd-08d5-4fd9-bb30-743e850d2431",
            Inclusion: "Free WiFi ",
            TotalFare: 408.25,
            TotalTax: 62.18,
            RoomPromotion: ["Member’s price: 10%"],
            CancelPolicies: [
                {
                    FromDate: "22-12-2023 00:00:00",
                    ChargeType: "Percentage",
                    CancellationCharge: 100.0,
                },
                {
                    FromDate: "04-12-2023 00:00:00",
                    ChargeType: "Fixed",
                    CancellationCharge: 0.0,
                },
            ],
            MealType: "Room_Only",
            IsRefundable: true,
            WithTransfers: false,
            ImageURLs: [
                "https://i.travelapi.com/lodging/1000000/30000/21700/21672/faccd376_z.jpg",
                "https://i.travelapi.com/lodging/1000000/30000/21700/21672/93d8a61c_z.jpg",
                "https://i.travelapi.com/lodging/1000000/30000/21700/21672/a94d6c9b_z.jpg",
                "https://i.travelapi.com/lodging/1000000/30000/21700/21672/0b3fa56d_z.jpg",
                "https://i.travelapi.com/lodging/1000000/30000/21700/21672/6ccb7486_z.jpg",
                "https://i.travelapi.com/lodging/1000000/30000/21700/21672/9596601a_z.jpg",
                "https://i.travelapi.com/lodging/1000000/30000/21700/21672/8fb27910_z.jpg",
            ],
        },
        {
            Name: ["Room (Assigned On Arrival),1 King Bed"],
            BookingCode: "1033602!TB!9!TB!968fd4dd-08d5-4fd9-bb30-743e850d2431",
            Inclusion: "Free WiFi ",
            TotalFare: 408.25,
            TotalTax: 62.18,
            RoomPromotion: ["Member’s price: 10%"],
            CancelPolicies: [
                {
                    FromDate: "22-12-2023 00:00:00",
                    ChargeType: "Percentage",
                    CancellationCharge: 100.0,
                },
                {
                    FromDate: "04-12-2023 00:00:00",
                    ChargeType: "Fixed",
                    CancellationCharge: 0.0,
                },
            ],
            MealType: "Room_Only",
            IsRefundable: true,
            WithTransfers: false,
            ImageURLs: [
                "https://i.travelapi.com/lodging/1000000/30000/21700/21672/e7a7e8ee_z.jpg",
            ],
        },
        {
            Name: ["Luxury Room,1 Queen Bed"],
            BookingCode: "1033602!TB!1!TB!968fd4dd-08d5-4fd9-bb30-743e850d2431",
            Inclusion: "Breakfast buffet,Free WiFi ",
            TotalFare: 433.77,
            TotalTax: 66.06,
            RoomPromotion: ["Member’s price: 10%"],
            CancelPolicies: [
                {
                    FromDate: "22-12-2023 00:00:00",
                    ChargeType: "Percentage",
                    CancellationCharge: 100.0,
                },
                {
                    FromDate: "04-12-2023 00:00:00",
                    ChargeType: "Fixed",
                    CancellationCharge: 0.0,
                },
            ],
            MealType: "BreakFast",
            IsRefundable: true,
            WithTransfers: false,
            ImageURLs: [
                "https://i.travelapi.com/lodging/1000000/30000/21700/21672/faccd376_z.jpg",
                "https://i.travelapi.com/lodging/1000000/30000/21700/21672/93d8a61c_z.jpg",
                "https://i.travelapi.com/lodging/1000000/30000/21700/21672/a94d6c9b_z.jpg",
                "https://i.travelapi.com/lodging/1000000/30000/21700/21672/0b3fa56d_z.jpg",
                "https://i.travelapi.com/lodging/1000000/30000/21700/21672/6ccb7486_z.jpg",
                "https://i.travelapi.com/lodging/1000000/30000/21700/21672/9596601a_z.jpg",
                "https://i.travelapi.com/lodging/1000000/30000/21700/21672/8fb27910_z.jpg",
            ],
        },
    ];
    public hotel_details: any = [
        {
            HotelBookingCode: "1033602!TB!968fd4dd-08d5-4fd9-bb30-743e850d2431",
            HotelInfo: {
                HotelCode: 1033602,
                HotelName: "The Oberoi, Bengaluru",
                HotelPicture:
                    "https://api.tbotechnology.in/imageresource.aspx?img=9eMP+0FIICgCIk6ZClzZH9Cs+1gwAq6BFWcc22yNLMF/UJIXMdxPdRpAyYgh22RZ7a2etvet1bnt39ScRwKUn2CK1UdA+3QpX4YEQOIpViYndWHBp6/0DA==",
                HotelDescription:
                    "Property Location A stay at The Oberoi, Bengaluru places you in the heart of Bengaluru, steps from M.G. Road and 11 minutes by foot from Kempegowda Museum.  This 5-star hotel is 3 mi ( ",
                Latitude: "12.97279",
                Longitude: "77.61807",
                HotelAddress: "37-39 Mahatma Gandhi Road Bengaluru 560001 ",
                Rating: "FiveStar",
                TripAdvisorRating: "5.0",
                TagIds: "54988",
            },
            MinHotelPrice: {
                TotalPrice: 408.25,
                Currency: "USD",
                OriginalPrice: 408.25,
            },
            IsPkgProperty: false,
            IsPackageRate: true,
            MappedHotel: false,
            IsHalal: false,
        },
    ];
}
