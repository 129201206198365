<div *ngFor="let hotel of hotel_details">
    <app-navbar-three></app-navbar-three>
    <app-hotel-form></app-hotel-form>
    <section class="hotel-details">
        <div class="container">
            <div class="row">
                <div class="col-lg-5 p-1">
                    <div>
                        <div class="hotel-gallery owl-carousel owl-theme">
                            <div class="single-item">
                                <img src="{{hotel.HotelInfo.HotelPicture}}" alt="">

                            </div>

                            <div class="single-item">
                                <img src="assets/img/hotel-details/img2.jpg" alt="">
                            </div>

                            <div class="single-item">
                                <img src="assets/img/hotel-details/img.jpg" alt="">
                            </div>

                            <div class="single-item">
                                <img src="assets/img/hotel-details/img1.jpg" alt="">
                            </div>
                        </div>

                    </div>
                </div>
                <div class="col-lg-3 p-1">
                    <div class="row">
                        <div class="pb-1">
                            <img src="assets/img/hotel-details/img2.jpg" alt="" width="100%">
                        </div>
                        <div class="pt-1">
                            <img src="assets/img/hotel-details/img.jpg" alt="" width="100%">
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 mb-4 p-1">
                    <div class="bg-white1">
                        <div class="card1 p-3 py-3">
                            <h4 class="mb-0">{{hotel.HotelInfo.HotelName}}</h4>
                            <div class="d-flex hotel-info">
                                <div>
                                    <div class="mb-2">

                                        <p>For 2 Adults</p>
                                    </div>
                                    <div>
                                        <p class="text-danger mb-0"><i class="fa-solid fa-xmark"></i> Non-Refundable</p>
                                        <p><i class="fa-solid fa-check"></i> Room Only</p>
                                    </div>
                                </div>
                                <div class="text-end">
                                    <p class="mb-0">Per Night</p>
                                    <h4 class="mb-0">{{hotel.MinHotelPrice.Currency}}
                                        {{hotel.MinHotelPrice.OriginalPrice}}</h4>
                                    <p class="mb-0">+ {{hotel.MinHotelPrice.Currency}}
                                        {{hotel.MinHotelPrice.TotalPrice}} taxes & fees</p>
                                </div>
                            </div>
                        </div>
                        <div class="card2 p-3">
                            <div class="d-flex hotel-info2">
                                <div>
                                    <a href="#">View other Rooms</a>
                                </div>
                                <div>
                                    <a routerLink="/hotel-booking" class="booknow">Book Now</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="row py-2">
                            <script>
                                {
                                    {
                                        '//maps.google.com/maps?q=' + hotel.HotelInfo.Latitude
                                    }
                                }
                            </script>
                            <div class="col-6 mw-100 pr-1"><iframe
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15548.609235719734!2d77.62401938438413!3d13.02597115271441!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae17355dbc2527%3A0x72cf9212205b927a!2sGolden%20Fortune%20Hotel!5e0!3m2!1sen!2sin!4v1701670643834!5m2!1sen!2sin"
                                    style="border: 0;width: 100%;border-radius: 15px;aspect-ratio: 1.28;"
                                    allowfullscreen="" loading="lazy"
                                    referrerpolicy="no-referrer-when-downgrade"></iframe>
                                <!-- <iframe
                                    src="{{'//maps.google.com/maps?q=' + hotel.HotelInfo.Latitude + ',' + hotel.HotelInfo.Latitude + '&z=15&output=embed'}}"></iframe> -->
                                <!-- <iframe
                                    src="//maps.google.com/maps?q='{{hotel.HotelInfo.Latitude}}','{{hotel.HotelInfo.Latitude}}'&z=15&output=embed"></iframe> -->
                                <!-- <iframe src="//maps.google.com/maps?q=53.3381768,-6.2613077&z=15&output=embed"></iframe> -->
                            </div>
                            <!-- {{'//maps.google.com/maps?q=' + hotel.HotelInfo.Latitude + ',' + hotel.HotelInfo.Latitude +
                            '&z=15&output=embed'}} -->
                            <div class="col-6 pl-1">
                                <div class="bg-white3 py-2 px-3">
                                    <div class="rating">{{hotel.HotelInfo.TripAdvisorRating}}/5</div>
                                    <div class="review">
                                        <h5 class="mb-0">Very Good</h5>
                                        <p>Based on 9,764 Ratings</p>

                                    </div>
                                    <div class="all-review"><a href="#">Reed all reviews</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row hotel-name">
                <div class="col-12">
                    <h3>{{hotel.HotelInfo.HotelName}}<span>
                            <i class="fa-solid fa-star"></i>
                            <i class="fa-solid fa-star"></i>
                            <i class="fa-solid fa-star"></i>
                            <i class="fa-solid fa-star"></i>
                            <i class="fa-solid fa-star-half-stroke"></i>
                        </span>
                    </h3>
                    <p class="text-white"><b>{{hotel.HotelInfo.HotelAddress}}</b></p>
                </div>
            </div>
        </div>
    </section>
    <section class="amenities">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <h3 class="text-white">Popular Amenities</h3>
                    <div class="bg-white4 p-4">
                        <div class="row align-items-center">
                            <div class="col-md-10">
                                <div class="row align-items-center">
                                    <div class="col-md-2 text-center text-center">
                                        <div class="ami-icon">
                                            <i class="fa-solid fa-football"></i>
                                        </div>
                                        <p>Kids Play Area</p>
                                    </div>
                                    <div class="col-md-2 text-center">
                                        <div class="ami-icon">
                                            <i class="fa-solid fa-table-tennis-paddle-ball"></i>
                                        </div>
                                        <p>Indoor Games</p>
                                    </div>
                                    <div class="col-md-2 text-center">
                                        <div class="ami-icon">
                                            <i class="fa-solid fa-utensils"></i>
                                        </div>
                                        <p>Restaurant</p>
                                    </div>
                                    <div class="col-md-2 text-center">
                                        <div class="ami-icon">
                                            <i class="fa-solid fa-champagne-glasses"></i>
                                        </div>
                                        <p>Bar</p>
                                    </div>
                                    <div class="col-md-2 text-center">
                                        <div class="ami-icon">
                                            <i class="fa-solid fa-car"></i>
                                        </div>
                                        <p>Vehicle
                                            Rentals</p>
                                    </div>
                                    <div class="col-md-2 text-center">
                                        <div class="ami-icon">
                                            <i class="fa-solid fa-person-swimming"></i>
                                        </div>
                                        <p>Pool</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 text-right">
                                <button (click)="scrollTo('amenities')">View More</button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>
    <section id="pagemenu" class="pagemenu">
        <div class="container">
            <div class="row border-btm ">
                <div class="col-md-2"></div>
                <div class="col-md-8">
                    <div class="d-flex justify-content-between align-items-center">
                        <div>

                            <!-- <a routerLink="/overviews" class="active">Overviews</a> -->
                            <button [ngClass]="{'active': currentSection=='overviews'}"
                                (click)="scrollTo('overviews')">Overviews</button>
                        </div>
                        <div>
                            <button [ngClass]="{'active': currentSection==='rooms'}"
                                (click)="scrollTo('rooms')">Rooms</button>

                        </div>
                        <div>
                            <button [ngClass]="{'active': currentSection==='location'}"
                                (click)="scrollTo('location')">Location</button>

                        </div>
                        <div>
                            <button [ngClass]="{'active': currentSection==='amenities'}"
                                (click)="scrollTo('amenities')">Amenities</button>

                        </div>
                        <div>
                            <button [ngClass]="{'active': currentSection==='reviews'}"
                                (click)="scrollTo('reviews')">Reviews</button>

                        </div>
                    </div>
                </div>
                <div class="col-md-2"></div>
            </div>
        </div>
    </section>
    <div>
        <section class="overviews" id="overviews" #target>
            <div class="container">
                <div class="row">
                    <div class="col-md-12">

                        <div class="container">
                            <div class="row">
                                <div class="col-12">
                                    <h3 class="text-white pb-3">Overviews</h3>
                                    <p class="text-white">{{hotel.HotelInfo.HotelDescription}}</p>
                                    <ul class="text-white overlist">
                                        <li>Keep your little ones engaged in the safe play area within the premises.
                                        </li>
                                        <li>Chillout at the lounge with a book or play foosball with loved ones.
                                        </li>
                                        <li>
                                            Enjoy a lively ambience with your drink or relish global delicacies at the
                                            Restaurant.</li>


                                    </ul>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="overviews" id="rooms" #target>
            <div class="container">
                <div class="row">
                    <div class="col-md-12">

                        <div class="container">
                            <div class="row">

                                <div class="col-md-12 pt-5">
                                    <h3 class="text-white pb-3">ROOMS & AVAILABILITY</h3>
                                    <div class="row">
                                        <div class="col-md-4 mb-4" *ngFor="let item of hotel_rooms">
                                            <div class="roomsinfo">
                                                <div class="hotel-gallery owl-carousel owl-theme">
                                                    <div *ngFor="let img of item.ImageURLs">
                                                        <img src="{{img}}" alt="">
                                                    </div>
                                                </div>
                                                <div class="p-4 pb-1">
                                                    <h4 class="text-black">{{item.Name}}</h4>
                                                    <h6 class="text-black">10/10 Exceptional</h6>
                                                    <div class="room-fea">
                                                        <div>
                                                            <p><i class="fa-solid fa-check"></i> {{item.Inclusion}}</p>

                                                        </div>
                                                        <div class="pt-2"><a href="#">More details</a></div>
                                                    </div>
                                                </div>
                                                <div class="hr">

                                                </div>
                                                <div class="p-4 pt-1">
                                                    <h6 class="text-black">Cancellation Policy</h6>
                                                    <p>More details on all policy optional</p>
                                                    <mat-radio-group aria-label="Select an option">
                                                        <mat-radio-button value="1">
                                                            <div
                                                                class="d-flex justify-content-between align-items-center">
                                                                <div>
                                                                    <p>Non-refundable</p>
                                                                </div>
                                                                <div>
                                                                    <h6 class="mb-0">+ AED 0</h6>
                                                                </div>
                                                            </div>
                                                        </mat-radio-button>
                                                        <mat-radio-button value="2">
                                                            <div
                                                                class="d-flex justify-content-between align-items-center">
                                                                <div>
                                                                    <p>Fully refundable before 30 Nov</p>
                                                                </div>
                                                                <div>
                                                                    <h6 class="mb-0">+ AED 5,267</h6>
                                                                </div>
                                                            </div>
                                                        </mat-radio-button>
                                                    </mat-radio-group>
                                                    <div class="pt-5">
                                                        <h4 class="mb-0">{{hotel.MinHotelPrice.Currency}}
                                                            {{item.TotalFare}}
                                                            <span class="text-muted h6"><s>{{hotel.MinHotelPrice.Currency}}
                                                                    {{item.TotalFare}}</s></span>
                                                        </h4>
                                                        <p class="mb-0">{{hotel.MinHotelPrice.Currency}}
                                                            {{item.TotalTax}}
                                                            total</p>
                                                        <p class="mb-0">Includes taxes & fees</p>
                                                    </div>
                                                    <div class="d-flex justify-content-between pt-3">
                                                        <div class="price-details">
                                                            <a href="#">Price details</a>
                                                        </div>
                                                        <div>
                                                            <a routerLink="/hotel-booking" class="reserve">Reserve</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="like">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <h3 class="text-white">YOU MAY ALSO LIKE</h3>
                        <div class="row">
                            <div class="col-md-4 mb-4">
                                <div class="roomsinfo">
                                    <img src="assets/img/hotel-details/like1.jpg" alt="">
                                    <div class="p-4 pb-1">
                                        <h4 class="text-black">Dukes The Palm, a Royal
                                            Hideaway Hotel</h4>
                                        <div class="d-flex">
                                            <h5 class="text-black mr-3">8.7/10</h5>
                                            <p>Very Good (1005)</p>
                                        </div>


                                        <div class="room-fea py-2">
                                            <div>
                                                <p class="mb-0"><i class="fa-solid fa-check"></i> Parking Included</p>
                                                <p class="mb-0"><i class="fa-solid fa-check"></i> Pool</p>
                                                <p class="mb-0"><i class="fa-solid fa-check"></i> Free wifi</p>
                                                <p class="mb-0"><i class="fa-solid fa-check"></i> Free breakfast</p>
                                                <p class="mb-0"><i class="fa-solid fa-check"></i> Pet-friendly</p>

                                            </div>

                                        </div>
                                    </div>

                                    <div class="p-4 pt-1">

                                        <div class="d-flex justify-content-between pt-3">
                                            <div class="price-details">

                                            </div>
                                            <div>
                                                <h4 class="mb-0 text-end">AED 45,201</h4>
                                                <p class="mb-0 text-end">AED 51.012 total</p>
                                                <p class="mb-0 text-end">Per night</p>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 mb-4">
                                <div class="roomsinfo">
                                    <img src="assets/img/hotel-details/like2.jpg" alt="">
                                    <div class="p-4 pb-1">
                                        <h4 class="text-black">Radisson Beach Resort
                                            Palm Jumeirah</h4>
                                        <div class="d-flex">
                                            <h5 class="text-black mr-3">8.7/10</h5>
                                            <p>Very Good (1005)</p>
                                        </div>


                                        <div class="room-fea py-2">
                                            <div>
                                                <p class="mb-0"><i class="fa-solid fa-check"></i> Parking Included</p>
                                                <p class="mb-0"><i class="fa-solid fa-check"></i> Pool</p>
                                                <p class="mb-0"><i class="fa-solid fa-check"></i> Free wifi</p>
                                                <p class="mb-0"><i class="fa-solid fa-check"></i> Free breakfast</p>
                                                <p class="mb-0"><i class="fa-solid fa-check"></i> Pet-friendly</p>

                                            </div>

                                        </div>
                                    </div>

                                    <div class="p-4 pt-1">

                                        <div class="d-flex justify-content-between pt-3">
                                            <div class="price-details">

                                            </div>
                                            <div>
                                                <h4 class="mb-0 text-end">AED 45,201</h4>
                                                <p class="mb-0 text-end">AED 51.012 total</p>
                                                <p class="mb-0 text-end">Per night</p>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 mb-4">
                                <div class="roomsinfo">
                                    <img src="assets/img/hotel-details/like3.jpg" alt="">
                                    <div class="p-4 pb-1">
                                        <h4 class="text-black">Taj Exotica Resort & Spa,
                                            The Palm, Dubai</h4>
                                        <div class="d-flex">
                                            <h5 class="text-black mr-3">8.7/10</h5>
                                            <p>Very Good (1005)</p>
                                        </div>


                                        <div class="room-fea py-2">
                                            <div>
                                                <p class="mb-0"><i class="fa-solid fa-check"></i> Parking Included</p>
                                                <p class="mb-0"><i class="fa-solid fa-check"></i> Pool</p>
                                                <p class="mb-0"><i class="fa-solid fa-check"></i> Free wifi</p>
                                                <p class="mb-0"><i class="fa-solid fa-check"></i> Free breakfast</p>
                                                <p class="mb-0"><i class="fa-solid fa-check"></i> Pet-friendly</p>

                                            </div>

                                        </div>
                                    </div>

                                    <div class="p-4 pt-1">

                                        <div class="d-flex justify-content-between pt-3">
                                            <div class="price-details">

                                            </div>
                                            <div>
                                                <h4 class="mb-0 text-end">AED 45,201</h4>
                                                <p class="mb-0 text-end">AED 51.012 total</p>
                                                <p class="mb-0 text-end">Per night</p>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id="location" class="location">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <h3 class="text-white">LOCATION</h3>
                        <div class="bg-white5">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="m-3 mapsloc">
                                        <iframe
                                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d17703.00722039818!2d54.97611681645345!3d25.011932901844194!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f11f306f9fb97%3A0x7928a0558d051f31!2sPalm%20Jabel%20Ali%20Villas!5e0!3m2!1sen!2sin!4v1701693514587!5m2!1sen!2sin"
                                            style="border:0;" allowfullscreen="" loading="lazy"
                                            referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        <div class="p-3 mapsloctext"><a href="#">View in map</a></div>
                                    </div>
                                    <div class="row m-3">
                                        <div class="col-md-4">
                                            <div class="p-3">
                                                <h5 class="text-black pb-4">What's nearby</h5>
                                                <p class="mb-0">In Palm Jumeirah</p>
                                                <hr class="my-2">
                                                <p class="mb-0">Marina Beach - 68 min walk</p>
                                                <hr class="my-2">
                                                <p class="mb-0">Aquaventure - 9 min drive</p>
                                                <hr class="my-2">
                                                <p class="mb-0">Dubai Marina Mall - 7 min drive</p>
                                                <hr class="my-2">
                                                <p class="mb-0">The Walk - 9 min drive</p>
                                                <hr class="my-2">
                                                <p class="mb-0">Souk Madinat Jumeirah - 10 min drive</p>
                                                <hr class="my-2">
                                                <p class="mb-0">Wild Wadi Water Park - 9 min drive</p>
                                                <hr class="my-2">
                                                <p class="mb-0">Burj Al Arab - 10 min drive</p>
                                                <hr class="my-2">
                                                <p class="mb-0">Madinat Jumeirah - 11 min drive</p>
                                                <hr class="my-2">
                                                <p class="mb-0">Ski Dubai - 10 min drive</p>
                                                <hr class="my-2">
                                                <p class="mb-0">Mall of the Emirates - 11 min drive</p>
                                                <hr class="my-2">
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="p-3">
                                                <h5 class="text-black pb-4">Restaurants</h5>
                                                <p class="mb-0">SĀN Beach - 15 min walk</p>
                                                <hr class="my-2">
                                                <p class="mb-0">Depachika Food Hall - 6 min walk</p>
                                                <hr class="my-2">
                                                <p class="mb-0">Sushisamba - 13 min walk</p>
                                                <hr class="my-2">
                                                <p class="mb-0">Joe & The Juice - 17 min walk</p>
                                                <hr class="my-2">
                                                <p class="mb-0">McDonald's - 5 min walk</p>
                                                <hr class="my-2">

                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="p-3">
                                                <h5 class="text-black pb-4">Getting around</h5>
                                                <p class="mb-0">Dubai Intl. Airport (DXB) - 33 min drive</p>
                                                <hr class="my-2">
                                                <p class="mb-0">Al Maktoum Intl. Airport (DWC) - 37 min drive</p>
                                                <hr class="my-2">
                                                <p class="mb-0">Sharjah (SHJ-Sharjah Intl.) - 56 min drive</p>
                                                <hr class="my-2">
                                                <p class="mb-0">Nakheel Mall Station - 7 min walk</p>
                                                <hr class="my-2">
                                                <p class="mb-0">Al Ittihad Park Station - 9 min walk</p>
                                                <hr class="my-2">
                                                <p class="mb-0">Palm Gateway Station - 25 min walk</p>
                                                <hr class="my-2">

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id="amenities" class="amenities">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <h3 class="text-white">AMENITIES
                        </h3>
                        <div class="bg-white6">
                            <div class="row">
                                <div class="col-md-12">

                                    <div class="row m-3 pt-5">
                                        <h4 class="text-black">Property amenities</h4>
                                        <div class="col-md-3">
                                            <div class="p-3">
                                                <h5 class="text-black "><i class="fa-solid fa-circle"></i> Food and
                                                    drink
                                                </h5>
                                                <ul>
                                                    <li>Buffet breakfast </li>
                                                    <li>7:00- 11:00 AM</li>
                                                    <li>3 restaurants</li>
                                                    <li>2 coffee shops</li>
                                                    <li>Bar/lounge</li>
                                                    <li>24-hour room </li>
                                                    <li>service</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="p-3">
                                                <h5 class="text-black "><i class="fa-solid fa-circle"></i> Services
                                                </h5>
                                                <ul>
                                                    <li>24-hour front desk</li>
                                                    <li>Concierge services</li>
                                                    <li>Tour/ticket assistance</li>
                                                    <li>Luggage storage</li>
                                                    <li>Multilingual staff</li>
                                                    <li>Pool loungers</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="p-3">
                                                <h5 class="text-black "><i class="fa-solid fa-circle"></i> Facilities
                                                </h5>
                                                <ul>
                                                    <li>1 building/tower</li>
                                                    <li>Built in 2023</li>
                                                    <li>Safe-deposit box</li>
                                                    <li>Rooftop terrace</li>
                                                    <li>24-hr fitness center</li>
                                                    <li>An outdoor pool</li>
                                                    <li>Full-service spa</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="p-3">
                                                <h5 class="text-black "><i class="fa-solid fa-circle"></i> Traveling
                                                    with
                                                    children
                                                </h5>
                                                <ul>
                                                    <li>Free children's club</li>
                                                    <li>Babysitting (surcharge)</li>
                                                    <li>Children's games</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row m-3 pt-5">
                                        <h4 class="text-black">Room amenities</h4>
                                        <div class="col-md-3">
                                            <div class="p-3">
                                                <h5 class="text-black "><i class="fa-solid fa-circle"></i> Be
                                                    entertained
                                                </h5>
                                                <ul>
                                                    <li>42-inch LED TV
                                                    </li>
                                                    <li>Cable TV channels</li>

                                                </ul>
                                            </div>
                                            <div class="p-3">
                                                <h5 class="text-black "><i class="fa-solid fa-circle"></i> What to enjoy
                                                </h5>
                                                <ul>
                                                    <li>Balcony
                                                    </li>
                                                    <li>City view</li>

                                                </ul>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="p-3">
                                                <h5 class="text-black "><i class="fa-solid fa-circle"></i> Home comfort
                                                </h5>
                                                <ul>
                                                    <li>Air conditioning</li>
                                                    <li>Minibar</li>
                                                    <li>Coffee/tea maker</li>

                                                </ul>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="p-3">
                                                <h5 class="text-black "><i class="fa-solid fa-circle"></i> Sleep well
                                                </h5>
                                                <ul>
                                                    <li>Soundproofed rooms</li>
                                                    <li>Turndown service</li>
                                                    <li>Free crib/infant bed</li>

                                                </ul>
                                            </div>
                                        </div>
                                        <div class="col-md-3">

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id="reviews" class="reviews">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <h3 class="text-white">REVIEWS
                        </h3>
                        <div class="bg-white7">
                            <div class="row">
                                <div class="col-md-12">

                                    <div class="row m-3 pt-5">
                                        <div class="col-md-4 rew">
                                            <div class="d-flex align-items-center">
                                                <div>
                                                    <h2 class="mainrating text-black">9.3</h2>
                                                </div>
                                                <div>
                                                    <p class="mb-0">Excellent</p>
                                                    <a href="#">87 reviews</a>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-6 p-3">
                                                    <h4>9.2/10</h4>
                                                    <p>Cleanliness</p>
                                                </div>
                                                <div class="col-6 p-3">
                                                    <h4>9.0/10</h4>
                                                    <p>Staff & service</p>
                                                </div>
                                                <div class="col-6 p-3">
                                                    <h4>8.8/10</h4>
                                                    <p>Amenities</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-8">
                                            <h4>8/10 very good</h4>
                                            <p>28 November 2023</p>
                                            <p>Beautiful place, great location and amenities! The pools (beach pool and
                                                rooftop
                                                pool) were really
                                                nice. The beach club was very cool, we loved the 70’s vibe. Sometimes
                                                was
                                                hard
                                                to get a chair at the
                                                pool or beach but we always managed to find one or two. There should be
                                                a
                                                system
                                                to reserve in
                                                advance! Staff were very nice. Our room (studio) had a Nespresso
                                                machine,
                                                which
                                                was a real plus
                                                for us. However, the coffee was not Nespresso (generic capsules). As a
                                                coffee
                                                person I could tell
                                                the difference… ended up buying coffee at the shop downstairs. Overall
                                                the
                                                experience was really good.</p>
                                            <div class="d-flex justify-content-between align-items-center">
                                                <div class="d-flex align-items-center">
                                                    <img src="assets/img/hotel-details/flag.jpg" alt="" width="40px">
                                                    <div class="pl-3">
                                                        <p class="text-black ml-3">Mariana, 4-night family trip</p>
                                                    </div>
                                                </div>
                                                <div>
                                                    <a href="#" class="text-black">Verified guest review</a>
                                                </div>
                                            </div>
                                            <hr>
                                            <div class="py-3">
                                                <h4>9/10 very good</h4>
                                                <p>28 November 2023</p>
                                                <p>Beautiful place, great location and amenities! The pools (beach pool
                                                    and
                                                    rooftop
                                                    pool) were really
                                                    nice. The beach club was very cool, we loved the 70’s vibe.
                                                    Sometimes
                                                    was
                                                    hard
                                                    to get a chair at the
                                                    pool or beach but we always managed to find one or two. There should
                                                    be
                                                    a
                                                    system
                                                    to reserve in
                                                    advance! Staff were very nice. Our room (studio) had a Nespresso
                                                    machine,
                                                    which
                                                    was a real plus
                                                    for us. However, the coffee was not Nespresso (generic capsules). As
                                                    a
                                                    coffee
                                                    person I could tell
                                                    the difference… ended up buying coffee at the shop downstairs.
                                                    Overall
                                                    the
                                                    experience was really good.</p>
                                                <img src="../../../../assets/img/hotel-details/review1.jpg" alt=""
                                                    class="p-2" width="80px">
                                                <img src="../../../../assets/img/hotel-details/review2.jpg" alt=""
                                                    class="p-2" width="80px">
                                                <img src="../../../../assets/img/hotel-details/review3.jpg" alt=""
                                                    class="p-2" width="80px">
                                                <div class="d-flex justify-content-between align-items-center">
                                                    <div class="d-flex align-items-center pt-3">
                                                        <img src="assets/img/hotel-details/flag2.jpg" alt=""
                                                            width="40px">
                                                        <div class="pl-3">
                                                            <p class="text-black ml-3">Mahmoud, 6-night romance trip</p>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <a href="#" class="text-black">Verified guest review</a>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr>
                                            <div class="py-3">
                                                <div class="seeall">
                                                    <a href="#">See all review</a>
                                                </div>
                                            </div>
                                        </div>



                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>





<!-- <div class="col-md-4 mb-4">
                                            <div class="roomsinfo">
                                                <img src="assets/img/hotel-details/room2.jpg" alt="">
                                                <div class="p-4 pb-1">
                                                    <h4 class="text-black">Superior Room</h4>
                                                    <h6 class="text-black">10/10 Exceptional</h6>
                                                    <div class="room-fea">
                                                        <div>
                                                            <p><i class="fa-solid fa-check"></i> 35 sq m</p>
                                                            <p><i class="fa-solid fa-check"></i> City view</p>
                                                            <p><i class="fa-solid fa-check"></i> Sleeps 2</p>
                                                            <p><i class="fa-solid fa-check"></i> Free breakfast</p>
                                                            <p><i class="fa-solid fa-check"></i> Free wifi</p>
                                                            <p><i class="fa-solid fa-xmark"></i> Free valet parking</p>
                                                            <p><i class="fa-solid fa-check"></i> 1 King Bed or Two Twin
                                                                Bed</p>
                                                        </div>
                                                        <div class="pt-2"><a href="#">More details</a></div>
                                                    </div>
                                                </div>
                                                <div class="hr">
    
                                                </div>
                                                <div class="p-4 pt-1">
                                                    <h6 class="text-black">Cancellation Policy</h6>
                                                    <p>More details on all policy optional</p>
                                                    <mat-radio-group aria-label="Select an option">
                                                        <mat-radio-button value="1">
                                                            <div class="d-flex justify-content-between align-items-center">
                                                                <div>
                                                                    <p>Non-refundable</p>
                                                                </div>
                                                                <div>
                                                                    <h6 class="mb-0">+ AED 0</h6>
                                                                </div>
                                                            </div>
                                                        </mat-radio-button>
                                                        <mat-radio-button value="2">
                                                            <div class="d-flex justify-content-between align-items-center">
                                                                <div>
                                                                    <p>Fully refundable before 30 Nov</p>
                                                                </div>
                                                                <div>
                                                                    <h6 class="mb-0">+ AED 5,267</h6>
                                                                </div>
                                                            </div>
                                                        </mat-radio-button>
                                                    </mat-radio-group>
                                                    <div class="pt-5">
                                                        <h4 class="mb-0">AED 54,348 <span class="text-muted h6"><s>AED
                                                                    54,348</s></span></h4>
                                                        <p class="mb-0">AED 61,743 total</p>
                                                        <p class="mb-0">Includes taxes & fees</p>
                                                    </div>
                                                    <div class="d-flex justify-content-between pt-3">
                                                        <div class="price-details">
                                                            <a href="#">Price details</a>
                                                        </div>
                                                        <div>
                                                            <a href="#" class="reserve">Reserve</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4 mb-4">
                                            <div class="roomsinfo">
                                                <img src="assets/img/hotel-details/room3.jpg" alt="">
                                                <div class="p-4 pb-1">
                                                    <h4 class="text-black">Deluxe Room</h4>
                                                    <h6 class="text-black">10/10 Exceptional</h6>
                                                    <div class="room-fea">
                                                        <div>
                                                            <p><i class="fa-solid fa-check"></i> 35 sq m</p>
                                                            <p><i class="fa-solid fa-check"></i> City view</p>
                                                            <p><i class="fa-solid fa-check"></i> Sleeps 2</p>
                                                            <p><i class="fa-solid fa-check"></i> Free breakfast</p>
                                                            <p><i class="fa-solid fa-check"></i> Free wifi</p>
                                                            <p><i class="fa-solid fa-xmark"></i> Free valet parking</p>
                                                            <p><i class="fa-solid fa-check"></i> 1 King Bed or Two Twin
                                                                Bed</p>
                                                        </div>
                                                        <div class="pt-2"><a href="#">More details</a></div>
                                                    </div>
                                                </div>
                                                <div class="hr">
    
                                                </div>
                                                <div class="p-4 pt-1">
                                                    <h6 class="text-black">Cancellation Policy</h6>
                                                    <p>More details on all policy optional</p>
                                                    <mat-radio-group aria-label="Select an option">
                                                        <mat-radio-button value="1">
                                                            <div class="d-flex justify-content-between align-items-center">
                                                                <div>
                                                                    <p>Non-refundable</p>
                                                                </div>
                                                                <div>
                                                                    <h6 class="mb-0">+ AED 0</h6>
                                                                </div>
                                                            </div>
                                                        </mat-radio-button>
                                                        <mat-radio-button value="2">
                                                            <div class="d-flex justify-content-between align-items-center">
                                                                <div>
                                                                    <p>Fully refundable before 30 Nov</p>
                                                                </div>
                                                                <div>
                                                                    <h6 class="mb-0">+ AED 5,267</h6>
                                                                </div>
                                                            </div>
                                                        </mat-radio-button>
                                                    </mat-radio-group>
                                                    <div class="pt-5">
                                                        <h4 class="mb-0">AED 54,348 <span class="text-muted h6"><s>AED
                                                                    54,348</s></span></h4>
                                                        <p class="mb-0">AED 61,743 total</p>
                                                        <p class="mb-0">Includes taxes & fees</p>
                                                    </div>
                                                    <div class="d-flex justify-content-between pt-3">
                                                        <div class="price-details">
                                                            <a href="#">Price details</a>
                                                        </div>
                                                        <div>
                                                            <a href="#" class="reserve">Reserve</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4 mb-4">
                                            <div class="roomsinfo">
                                                <img src="assets/img/hotel-details/room1.jpg" alt="">
                                                <div class="p-4 pb-1">
                                                    <h4 class="text-black">Premium Club Room</h4>
                                                    <h6 class="text-black">10/10 Exceptional</h6>
                                                    <div class="room-fea">
                                                        <div>
                                                            <p><i class="fa-solid fa-check"></i> 35 sq m</p>
                                                            <p><i class="fa-solid fa-check"></i> City view</p>
                                                            <p><i class="fa-solid fa-check"></i> Sleeps 2</p>
                                                            <p><i class="fa-solid fa-check"></i> Free breakfast</p>
                                                            <p><i class="fa-solid fa-check"></i> Free wifi</p>
                                                            <p><i class="fa-solid fa-xmark"></i> Free valet parking</p>
                                                            <p><i class="fa-solid fa-check"></i> 1 King Bed or Two Twin
                                                                Bed</p>
                                                        </div>
                                                        <div class="pt-2"><a href="#">More details</a></div>
                                                    </div>
                                                </div>
                                                <div class="hr">
    
                                                </div>
                                                <div class="p-4 pt-1">
                                                    <h6 class="text-black">Cancellation Policy</h6>
                                                    <p>More details on all policy optional</p>
                                                    <mat-radio-group aria-label="Select an option">
                                                        <mat-radio-button value="1">
                                                            <div class="d-flex justify-content-between align-items-center">
                                                                <div>
                                                                    <p>Non-refundable</p>
                                                                </div>
                                                                <div>
                                                                    <h6 class="mb-0">+ AED 0</h6>
                                                                </div>
                                                            </div>
                                                        </mat-radio-button>
                                                        <mat-radio-button value="2">
                                                            <div class="d-flex justify-content-between align-items-center">
                                                                <div>
                                                                    <p>Fully refundable before 30 Nov</p>
                                                                </div>
                                                                <div>
                                                                    <h6 class="mb-0">+ AED 5,267</h6>
                                                                </div>
                                                            </div>
                                                        </mat-radio-button>
                                                    </mat-radio-group>
                                                    <div class="pt-5">
                                                        <h4 class="mb-0">AED 54,348 <span class="text-muted h6"><s>AED
                                                                    54,348</s></span></h4>
                                                        <p class="mb-0">AED 61,743 total</p>
                                                        <p class="mb-0">Includes taxes & fees</p>
                                                    </div>
                                                    <div class="d-flex justify-content-between pt-3">
                                                        <div class="price-details">
                                                            <a href="#">Price details</a>
                                                        </div>
                                                        <div>
                                                            <a href="#" class="reserve">Reserve</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4 mb-4">
                                            <div class="roomsinfo">
                                                <img src="assets/img/hotel-details/room2.jpg" alt="">
                                                <div class="p-4 pb-1">
                                                    <h4 class="text-black">Superior Room</h4>
                                                    <h6 class="text-black">10/10 Exceptional</h6>
                                                    <div class="room-fea">
                                                        <div>
                                                            <p><i class="fa-solid fa-check"></i> 35 sq m</p>
                                                            <p><i class="fa-solid fa-check"></i> City view</p>
                                                            <p><i class="fa-solid fa-check"></i> Sleeps 2</p>
                                                            <p><i class="fa-solid fa-check"></i> Free breakfast</p>
                                                            <p><i class="fa-solid fa-check"></i> Free wifi</p>
                                                            <p><i class="fa-solid fa-xmark"></i> Free valet parking</p>
                                                            <p><i class="fa-solid fa-check"></i> 1 King Bed or Two Twin
                                                                Bed</p>
                                                        </div>
                                                        <div class="pt-2"><a href="#">More details</a></div>
                                                    </div>
                                                </div>
                                                <div class="hr">
    
                                                </div>
                                                <div class="p-4 pt-1">
                                                    <h6 class="text-black">Cancellation Policy</h6>
                                                    <p>More details on all policy optional</p>
                                                    <mat-radio-group aria-label="Select an option">
                                                        <mat-radio-button value="1">
                                                            <div class="d-flex justify-content-between align-items-center">
                                                                <div>
                                                                    <p>Non-refundable</p>
                                                                </div>
                                                                <div>
                                                                    <h6 class="mb-0">+ AED 0</h6>
                                                                </div>
                                                            </div>
                                                        </mat-radio-button>
                                                        <mat-radio-button value="2">
                                                            <div class="d-flex justify-content-between align-items-center">
                                                                <div>
                                                                    <p>Fully refundable before 30 Nov</p>
                                                                </div>
                                                                <div>
                                                                    <h6 class="mb-0">+ AED 5,267</h6>
                                                                </div>
                                                            </div>
                                                        </mat-radio-button>
                                                    </mat-radio-group>
                                                    <div class="pt-5">
                                                        <h4 class="mb-0">AED 54,348 <span class="text-muted h6"><s>AED
                                                                    54,348</s></span></h4>
                                                        <p class="mb-0">AED 61,743 total</p>
                                                        <p class="mb-0">Includes taxes & fees</p>
                                                    </div>
                                                    <div class="d-flex justify-content-between pt-3">
                                                        <div class="price-details">
                                                            <a href="#">Price details</a>
                                                        </div>
                                                        <div>
                                                            <a href="#" class="reserve">Reserve</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4 mb-4">
                                            <div class="roomsinfo">
                                                <img src="assets/img/hotel-details/room3.jpg" alt="">
                                                <div class="p-4 pb-1">
                                                    <h4 class="text-black">Deluxe Room</h4>
                                                    <h6 class="text-black">10/10 Exceptional</h6>
                                                    <div class="room-fea">
                                                        <div>
                                                            <p><i class="fa-solid fa-check"></i> 35 sq m</p>
                                                            <p><i class="fa-solid fa-check"></i> City view</p>
                                                            <p><i class="fa-solid fa-check"></i> Sleeps 2</p>
                                                            <p><i class="fa-solid fa-check"></i> Free breakfast</p>
                                                            <p><i class="fa-solid fa-check"></i> Free wifi</p>
                                                            <p><i class="fa-solid fa-xmark"></i> Free valet parking</p>
                                                            <p><i class="fa-solid fa-check"></i> 1 King Bed or Two Twin
                                                                Bed</p>
                                                        </div>
                                                        <div class="pt-2"><a href="#">More details</a></div>
                                                    </div>
                                                </div>
                                                <div class="hr">
    
                                                </div>
                                                <div class="p-4 pt-1">
                                                    <h6 class="text-black">Cancellation Policy</h6>
                                                    <p>More details on all policy optional</p>
                                                    <mat-radio-group aria-label="Select an option">
                                                        <mat-radio-button value="1">
                                                            <div class="d-flex justify-content-between align-items-center">
                                                                <div>
                                                                    <p>Non-refundable</p>
                                                                </div>
                                                                <div>
                                                                    <h6 class="mb-0">+ AED 0</h6>
                                                                </div>
                                                            </div>
                                                        </mat-radio-button>
                                                        <mat-radio-button value="2">
                                                            <div class="d-flex justify-content-between align-items-center">
                                                                <div>
                                                                    <p>Fully refundable before 30 Nov</p>
                                                                </div>
                                                                <div>
                                                                    <h6 class="mb-0">+ AED 5,267</h6>
                                                                </div>
                                                            </div>
                                                        </mat-radio-button>
                                                    </mat-radio-group>
                                                    <div class="pt-5">
                                                        <h4 class="mb-0">AED 54,348 <span class="text-muted h6"><s>AED
                                                                    54,348</s></span></h4>
                                                        <p class="mb-0">AED 61,743 total</p>
                                                        <p class="mb-0">Includes taxes & fees</p>
                                                    </div>
                                                    <div class="d-flex justify-content-between pt-3">
                                                        <div class="price-details">
                                                            <a href="#">Price details</a>
                                                        </div>
                                                        <div>
                                                            <a href="#" class="reserve">Reserve</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> -->