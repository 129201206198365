<app-navbar-one></app-navbar-one>

<div class="top-home-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 col-md-12">
                        <div class="hero-content">
                            <h1>Top Sights</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<section class="bg-dark">

    <div class="container p-5">
        <div class="row m-5">
            <div class="col-12" *ngFor="let main of topsight | slice:0:1">
                <img src="{{main.img}}">
                <a routerLink="/sights/{{main.slug}}">
                    <p>{{main.place}} : {{main.title}}</p>
                    <p class="saas-btn">Explore</p>
                </a>
            </div>
        </div>
        <div class="row m-5">
            <div class="col-4 space" *ngFor="let main of topsight | slice:1:10">
                <img src="{{main.img}}">
                <a routerLink="/sights/{{main.slug}}">
                    <p>{{main.place}} : {{main.title}}</p>
                    <p class="saas-btn">Explore</p>
                </a>
            </div>
        </div>
    </div>

</section>
