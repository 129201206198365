import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import {
    FormBuilder,
    FormGroup,
    Form,
    Validators,
    FormControl,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { NgForm } from "@angular/forms";
import data from "../../data/flight-destinations.json";
import blogs from "../../data/blog.json";
import offers from "../../data/offer.json";
// import { log } from "console";

@Component({
    selector: "app-marketing-home",
    templateUrl: "./marketing-home.component.html",
    styleUrls: ["./marketing-home.component.scss"],
})
export class MarketingHomeComponent implements OnInit {
    value1: string;
    value2: string;
    return_disabled = true;
    // adultsno = "1";
    demo: any;
    demo1: any;
    public blog = blogs;
    @ViewChild("passenger") passenger: TemplateRef<any>;
    public dialogRef: any;
    temp: any;
    public offer = offers;
    // adults: any;
    constructor(public dialog: MatDialog) {}
    minDate = new Date();

    public unfilteredDataToSearch: any[] = data;
    public filteredDataToSearch: any[] = [];
    adults = new FormControl("1");
    infants = new FormControl("0");
    children = new FormControl("0");
    class = new FormControl("Economy/Premium Economy");
    isvisble = false;
    // Travellers = new +this.adults + this.infants + this.children;
    // sum o adults infants and children?

    public beComponentForm: FormGroup = new FormGroup({
        slct_cntrl: new FormControl(""),
        slct_cntrl1: new FormControl(""),
        departure_date: new FormControl(""),
        return_date: new FormControl(""),
        adults: new FormControl("1"),
        children: new FormControl(""),
        infants: new FormControl(""),
        class: new FormControl(""),
        triptype: new FormControl(""),
    });

    ngOnInit() {
        this.filteredDataToSearch = this.unfilteredDataToSearch.map((w) => {
            return {
                text:
                    w.teamName +
                    " - " +
                    w.abbreviation +
                    "(" +
                    w.location +
                    ")",
                value: w.teamId,
                location: w.location,
                code: w.abbreviation,
                loc2: w.teamName,
            };
        });
        this.getvalueform();
    }

    lookup(e) {
        this.filteredDataToSearch = this.unfilteredDataToSearch
            .filter(
                (i) =>
                    (
                        i.teamName +
                        " - " +
                        i.abbreviation +
                        ">>" +
                        i.location +
                        ")"
                    )
                        .toString()
                        .toLowerCase()
                        .indexOf(e) > -1
            )
            .map((w) => {
                return {
                    text:
                        w.teamName +
                        " - " +
                        w.abbreviation +
                        ">>" +
                        w.location +
                        ")",
                    value: w.teamId,
                };
            });
    }

    clean(t) {
        t.value = "";
        this.lookup(t.value);
    }

    openpassDialog() {
        this.dialogRef = this.dialog.open(this.passenger, {
            width: "50%",
            disableClose: true,
        });
    }
    disableInputField() {
        this.return_disabled = false;
        this.isvisble = true;
    }
    enableinput() {
        this.return_disabled = true;
        this.isvisble = false;
    }

    async getvalueform() {
        console.log(
            // typeof this.nochild1,
            // this.nochild,
            // this.hotelsearch
            this.beComponentForm.value
            //  console.log(this.minDate)

            // control
        );
    }

    async interchangeValues(demo, demo1) {
        this.demo1 = demo;
        this.demo = demo1;
        this.beComponentForm.patchValue(
            ((this.beComponentForm.value.slct_cntrl = this.demo1),
            (this.beComponentForm.value.slct_cntrl1 = this.demo))
        );
    }
}
