<app-navbar-one></app-navbar-one>

<div class="offer-home-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 col-md-12">
                        <div class="hero-content">
                            <h1>Offers</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<section class="bg-dark">

    <div class="container p-5">
        <div class="row m-5">
            <div class="col-4 space" *ngFor="let main of topsight | slice:0:9">
                <a routerLink="/offers/{{main.slug}}">
                    <div class="offer-card space borders-5">
                        <img src="{{main.img}}" alt="offer">
                        <h4 class="offer-detail p-2">{{main.name}}</h4>
                        <h5 class="maintitle p-2">{{main.description}}</h5>
                        <div class="col-12 p-2">
                            <div class="borders-5 p-2 coupon box-modal">
                                <div class="row">
                                    <div class="col-3">
                                        <p>{{main.coupon}}</p>
                                    </div>
                                    <div class="col-9 copy">
                                        <button [cdkCopyToClipboard]="main.coupon">Copy
                                            Code <i class="fa-regular fa-copy"></i></button>
                                        <!-- <button (click)="copyInputMessage()">Copy
                                        Code <i class="fa-regular fa-copy"></i></button> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    </div>


</section>
