<div class="details_search">
    <div class="container">
        <form action="">
            <div class="row">
                <div class="col-md-8">
                    <div class="row">
                        <div class="col-md-4">
                            <div>
                                <mat-form-field class="example-full-width">
                                    <mat-label>City, Property name or Location</mat-label>
                                    <input matInput placeholder="" value="">
                                    <!-- <mat-hint>Location</mat-hint> -->
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div>
                                <mat-form-field>
                                    <mat-label>Check-In - Check-OUT</mat-label>
                                    <mat-date-range-input [rangePicker]="picker">

                                        <input matStartDate placeholder="Check-In">
                                        <input matEndDate placeholder="Check-OUT">
                                    </mat-date-range-input>
                                    <!-- <mat-hint>MM/DD/YYYY - MM/DD/YYYY</mat-hint> -->
                                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                                    <mat-date-range-picker #picker></mat-date-range-picker>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div>
                                <mat-form-field class="example-full-width">
                                    <mat-label>Rooms & guests</mat-label>
                                    <input matInput placeholder="" value="1 Rooms, 2 Adults">
                                    <!-- <mat-hint>Rooms & guests</mat-hint> -->
                                </mat-form-field>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="col-md-4 submitbtn">
                    <div class="row">
                        <div class="">
                            <div class="book_nw">
                                <a class="saas-btn" routerLink="/../hotel">BOOK NOW</a>
                                <!-- <button class="saas-btn" type="submit" value="Book Now"></button> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
