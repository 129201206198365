import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import {
    FormBuilder,
    FormGroup,
    Form,
    Validators,
    FormControl,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { NgForm } from "@angular/forms";

@Component({
    selector: "app-flight-search",
    templateUrl: "./flight-search.component.html",
    styleUrls: ["./flight-search.component.scss"],
})
export class FlightSearchComponent {
    @ViewChild("passenger") passenger: TemplateRef<any>;
    public dialogRef: any;
    constructor(public dialog: MatDialog) {}

    openpassDialog() {
        this.dialogRef = this.dialog.open(this.passenger, {
            width: "99%",
            disableClose: true,
        });
    }
}
