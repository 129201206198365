<app-navbar-three></app-navbar-three>
<app-hotel-form></app-hotel-form>
<section class="hotel-search">
    <div class="container">
        <!-- Sort By -->
        <div class="row">
            <div class="col-3 m-auto">
                <button (click)="filterdilog()" class="filterbtn">
                    <span class="secfont "><i class="fa fa-filter secfont" aria-hidden="true"></i>Filter</span>
                </button>
                <ng-template #filter>
                    <div class="btnclose text-end">
                        <button mat-raised-button class="m-1 " matDialogClose="yes"><i
                                class="fa-solid fa-xmark"></i></button>
                    </div>
                    <div class="container py-2 filtersearch">
                        <div class="row">
                            <div class="col-md-4">


                                <h4>Price For {{1}} Nights</h4>
                                <!-- <p><mat-checkbox formControlName="120to154">AED 120 - AED 154</mat-checkbox></p>
                                    <p><mat-checkbox formControlName="154to308">AED 154 - AED 308</mat-checkbox></p>
                                    <p><mat-checkbox formControlName="308to418">AED 308 - AED 418</mat-checkbox></p>
                                    <p><mat-checkbox formControlName="418to507">AED 418 - AED 507</mat-checkbox></p>
                                    <p><mat-checkbox formControlName="507to661">AED 507 - AED 661</mat-checkbox></p>
                                    <p><mat-checkbox formControlName="661to1322">AED 661 - AED 1322</mat-checkbox></p>
                                    <p><mat-checkbox formControlName="gt1322">AED 1322+</mat-checkbox></p> -->
                                <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                                    [(ngModel)]="pricerange">
                                    <mat-radio-button class="example-radio-button" *ngFor="let price of prices"
                                        [value]="price">{{price}}</mat-radio-button><br>
                                </mat-radio-group>


                            </div>
                            <div class="col-md-4">
                                <section class="example-section">
                                    <h4>Number of Rooms</h4>
                                    <mat-button-toggle-group name="fontStyle">
                                        <mat-button-toggle value="1">1</mat-button-toggle>
                                        <mat-button-toggle value="2">2</mat-button-toggle>
                                        <mat-button-toggle value="3">3</mat-button-toggle>
                                        <mat-button-toggle value="4">4</mat-button-toggle>
                                        <mat-button-toggle value="5">5</mat-button-toggle>
                                        <mat-button-toggle value="6">6</mat-button-toggle>
                                    </mat-button-toggle-group>
                                </section>
                            </div>
                            <div class="col-md-4">

                                <h4>Hotel Rating</h4>
                                <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                                    [(ngModel)]="starrsting">
                                    <mat-radio-button class="example-radio-button" *ngFor="let star of stars"
                                        [value]="star">{{star}}</mat-radio-button><br>
                                </mat-radio-group>



                            </div>
                        </div>
                        <div class="text-end">
                            <button mat-raised-button class="m-1 filterapply" matDialogClose="yes">Apply</button>
                        </div>
                    </div>

                </ng-template>
            </div>
            <div class="col-3 m-auto">
                <button class="filterbtn">Sort By : </button>
            </div>
            <div class="col-3 m-auto">
                <button class="filterbtn">Price <i class="fa-solid fa-arrow-down-up-across-line"></i></button>
            </div>

            <div class="col-3 m-auto">
                <button class="filterbtn">Hotel Rating <i class="fa-solid fa-arrow-down-up-across-line"></i></button>
            </div>

            <!-- <div class="col-2">
                <p class="font-s secfont">Search</p>
            </div> -->
        </div>
        <!-- Sort By -->
    </div>
    <section class="bg-dark">
        <div class="container">
            <div class="row breadcrumb m-auto">
                <p class="mt-3 mb-3"><span>Home </span><i class="fa fa-long-arrow-right" aria-hidden="true"></i><span>
                        Hotel Search in Dubai</span></p>
            </div>
        </div>
        <div class="container">
            <div class="row mauto text-center">
                <h3 class="prifont">Showing Properties in Dubai</h3>
            </div>
            <div class="row">
                <div class="col-lg-12 col-sm-12" *ngFor="let item of hotel">
                    <div class="hotelcard row border-10 mt-3 mb-3">

                        <div class="col-lg-3 p-3">
                            <div class="row">
                                <div class="col-12 mainp"><img src="assets/img/hotel-details/img1.jpg" alt=""
                                        class="border-10 hotelmain"></div>
                                <div class="col-12 mt-1">
                                    <div class="row">
                                        <div class="col-4 p-1"><img src="assets/img/hotel-details/img2.jpg" alt="">
                                        </div>
                                        <div class="col-4 p-1"><img src="assets/img/hotel-details/img.jpg" alt=""></div>
                                        <div class="col-4 p-1"><img src="assets/img/hotel-details/img2.jpg" alt="">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 m-auto text-center plr-5">
                            <h3>{{item.name}}</h3>
                            <p class="m-0"> <span class="highlight">{{item.location}}</span> |
                                <span>{{item.attraction}}</span>
                            </p>
                            <span *ngFor="let amme of item.amenities" class="highlight m-2">
                                {{amme}}
                            </span>
                            <br />
                            <div class="ammin">
                                <span *ngFor="let can of item.cancel" class="cancel m-2">
                                    {{can}}<br />
                                </span>
                            </div>
                        </div>
                        <div class="col-lg-3 borderl-2 p-4">
                            <div class="row">
                                <div class="col-6 text-center">
                                    <h6>{{item.overallreview}}</h6>
                                </div>
                                <div class="col-6">
                                    <div class="rate">
                                        <span>{{item.rating}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <p class="text-center">({{item.totalreview}} Reviews)</p>
                                </div>
                                <div class="col-12 text-right">
                                    <h2 class="m-0">{{item.price}}</h2>
                                    <p>{{item.gst}}</p>
                                </div>
                                <div class="col-12 text-center">
                                    <form>
                                        <input _ngcontent-ng-c2470330660="" routerLink="/hotel-details" type="submit"
                                            value="Book Now" class="saas-btn">
                                    </form>
                                </div>

                            </div>

                        </div>

                    </div>
                </div>

            </div>
        </div>
    </section>
</section>