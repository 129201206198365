<div class="bg-dark flight-payment">
    <section>
        <div class="container">
            <div class="row py-5">
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="bg-btn p-3">
                                <div class="row align-items-center">
                                    <div class="col-md-2">
                                        <img src="../../../../assets/img/logo/logo-2.svg" alt="">
                                    </div>
                                    <div class="col-md-8">
                                        <p>Hey, You are saving 150 additionally! Hurry and Book</p>

                                    </div>
                                    <div class="col-md-2">
                                        <div class="text-end">
                                            <p class="text-white"><i class="fa-solid fa-shield-halved"></i>
                                                SAFE & SECURE
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>


                </div>
            </div>
        </div>
    </section>
    <section>
        <div class="container">
            <div class="row py-5">
                <div class="col-md-8 ">
                    <div class="bg-btn rounded px-3">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="bg-white rounded">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <h3 class="text-black rounded`pl-3  pt-3 pb-3 mb-0">Payment Options</h3>
                                            <mat-tab-group>
                                                <!-- <mat-tab>
                                                    <ng-template mat-tab-label>
                                                        <div class="d-flex flex-column">
                                                            <div class="d-flex">
                                                                <img src="../../../../assets/img/flight-payment/bhimupi.png"
                                                                    alt="" width="30px">
                                                                <h4>UPI options</h4>
                                                            </div>
                                                            <div class="text-left sub-tab">
                                                                <p>Pay Directly From<br>
                                                                    Your Bank Account</p>
                                                            </div>

                                                        </div>
                                                    </ng-template>
                                                    <div class="container">
                                                        <div class="row">
                                                            <div class="col-md-12 px-4 py-2">
                                                                <p><i class="fa-solid fa-mobile"></i> Keep your phone
                                                                    handy!
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-md-12 p-4">
                                                                <div class="border12">
                                                                    <div class="row">
                                                                        <div class="col-md-6">
                                                                            <div class="text-center">
                                                                                <p class="text-black pt-3 pb-2">Scan and
                                                                                    pay
                                                                                </p>
                                                                            </div>
                                                                            <div class="text-center">
                                                                                <div class="border1234">
                                                                                    <div class="qr-overlay">
                                                                                        <button>View</button>
                                                                                    </div>
                                                                                    <img src="../../../../assets/img/flight-payment/qrcode.png"
                                                                                        alt="" width="100px">
                                                                                </div>
                                                                                <div class="p-3">
                                                                                    <p>Scan and pay any<br>
                                                                                        banking app</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6">
                                                                            <div class=" py-3">
                                                                                <div class="text-center">
                                                                                    <mat-form-field>
                                                                                        <mat-label>Enter UPI
                                                                                            ID</mat-label>
                                                                                        <input matInput
                                                                                            placeholder="mobileNumber@upi"
                                                                                            class="border12">
                                                                                    </mat-form-field>
                                                                                </div>
                                                                                <div class="text-center">
                                                                                    <button class="upiidcheck">VERIFY &
                                                                                        PAY</button>
                                                                                </div>
                                                                                <div class="py-2">
                                                                                    <ul class=" text-left">
                                                                                        <li>Enter your registered VPA
                                                                                        </li>
                                                                                        <li>Receive payment request on
                                                                                            bank
                                                                                            app</li>
                                                                                        <li>Authorize payment request
                                                                                        </li>
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <div class="text-center pymentsupport py-2">
                                                                            <img src="../../../../assets/img/flight-payment/google.png"
                                                                                alt="">
                                                                            <img src="../../../../assets/img/flight-payment/phonepe.png"
                                                                                alt="">
                                                                            <img src="../../../../assets/img/flight-payment/bhimupi.png"
                                                                                alt="">
                                                                            <img src="../../../../assets/img/flight-payment/paytm.png"
                                                                                alt="">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-12 p-3">
                                                                    <h4>AED 22,234 <sub>Due Now</sub></h4>
                                                                    <p>By continuing to pay. I understand and agree with
                                                                        the privacy policy. the user agreement and
                                                                        terms of service of Gulf Travel and Tourism.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </mat-tab> -->

                                                <mat-tab>
                                                    <ng-template mat-tab-label>
                                                        <div class="d-flex flex-column">
                                                            <div class="d-flex">
                                                                <mat-icon
                                                                    class="example-tab-icon">credit_card</mat-icon>
                                                                <h5>Credit/Debit/<br>ATM Card</h5>
                                                            </div>
                                                            <div class="text-left sub-tab">
                                                                <p>Visa,MasterCard,Amex,<br>Rupay And More</p>
                                                            </div>
                                                        </div>
                                                    </ng-template>
                                                    <div class="container py-3 ">
                                                        <div class="row">
                                                            <div class="col-md-12">
                                                                <mat-form-field appearance="outline"
                                                                    [style.width.%]="100">
                                                                    <mat-label>Card Number</mat-label>
                                                                    <input matInput type="number" maxLength="16"
                                                                        placeholder="XXXX-XXXX-XXXX-XXXX">
                                                                    <mat-icon matSuffix>credit_card</mat-icon>

                                                                </mat-form-field>
                                                                <mat-form-field appearance="outline"
                                                                    [style.width.%]="100">
                                                                    <mat-label>Name on Card</mat-label>
                                                                    <input matInput
                                                                        placeholder="Enter Your Name On Card">
                                                                    <!-- <mat-icon>account_circle</mat-icon> -->

                                                                </mat-form-field>
                                                            </div>
                                                            <div class="col-md-12">

                                                                <div class="d-flex justify-content-between">
                                                                    <div><mat-form-field appearance="outline">
                                                                            <mat-label>Month and Year</mat-label>
                                                                            <input matInput [matDatepicker]="dp"
                                                                                [formControl]="date" [min]="minDate">

                                                                            <mat-datepicker-toggle matIconSuffix
                                                                                [for]="dp"></mat-datepicker-toggle>
                                                                            <mat-datepicker #dp startView="multi-year"
                                                                                (monthSelected)="setMonthAndYear($event, dp)"
                                                                                panelClass="example-month-picker">
                                                                            </mat-datepicker>
                                                                        </mat-form-field></div>
                                                                    <div>
                                                                        <mat-form-field appearance="outline">
                                                                            <mat-label>Card CVV</mat-label>
                                                                            <input matInput type="password"
                                                                                maxlength="3"
                                                                                placeholder="Enter Card CVV">


                                                                        </mat-form-field>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                            <div class="col-md-12">
                                                                <div class="row">
                                                                    <div class="col-md-6">
                                                                        <h4>AED 155,679 <span class="sub-tab">Due
                                                                                Now</span></h4>
                                                                    </div>
                                                                    <div class="col-md-6">
                                                                        <div class="text-end">
                                                                            <button class="paybtn">PAY NOW</button>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-12">
                                                                        <p>By continuing to pay, I understand and agree
                                                                            with the privacy policy, the user agreement
                                                                            and
                                                                            terms of service of Gulf.
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </mat-tab>

                                                <mat-tab>
                                                    <ng-template mat-tab-label>
                                                        <div class="d-flex flex-column">
                                                            <div class="d-flex">
                                                                <mat-icon>account_balance</mat-icon>
                                                                <p>Net Banking</p>
                                                            </div>
                                                            <div class="text-left sub-tab">
                                                                <p>All Major Banks Available</p>
                                                            </div>
                                                        </div>
                                                    </ng-template>

                                                    <div class="container py-5">
                                                        <div class="row">
                                                            <div class="col-md-12">
                                                                <mat-form-field appearance="outline"
                                                                    [style.width.%]="100">
                                                                    <mat-label>Search</mat-label>

                                                                    <input matInput type="search"
                                                                        placeholder="Search By Bank">
                                                                    <mat-icon
                                                                        matSuffix>sentiment_very_satisfied</mat-icon>


                                                                </mat-form-field>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </mat-tab>

                                            </mat-tab-group>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="card mb-4">
                        <div class="card-body p-0">
                            <div class="row p-2">
                                <div class="col-md-6">
                                    <p class="text-back">Your Booking</p>
                                </div>
                                <div class="col-md-6">
                                    <div class="text-end">
                                        ONE WAY FLIGHT
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <p class="mb-0">SAT 30 DEC'23</p>
                                </div>

                            </div>
                            <div class="row  p-2">
                                <div class="col-md-4">
                                    <div class="d-flex align-items-center">
                                        <div>
                                            <img src="../../../../assets/img/404.jpg" alt="">
                                        </div>
                                        <div>
                                            <p class="text-black mb-0">
                                                4:20AM
                                            </p>
                                            <sub>Bengaluru</sub>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 text-center">
                                    <p class="mb-0">7h 35m</p>
                                    <hr class="betwn">
                                    <p class="mb-0">1 Stop(s)</p>
                                </div>
                                <div class="col-md-4">
                                    <p class="text-black mb-0">
                                        4:20AM
                                    </p>
                                    <sub>Bengaluru</sub>
                                </div>
                            </div>
                            <hr>
                            <div class="row  p-2">
                                <div class="col-md-12">
                                    <div class="d-flex">
                                        <div>
                                            <img src="../../../../assets/img/404.jpg" alt="" width="50px">
                                        </div>
                                        <div>
                                            <p class="text-black mb-0">
                                                Traveler(s)
                                            </p>
                                            <p class="mb-0">1. KANHU PATRA( M, ADULT)</p>
                                            <sub>example@gmail.com | +91123456789</sub>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-body p-0">
                            <div class="row p-2">
                                <div class="col-md-6">
                                    <h5 class="text-black">Fare Summary</h5>
                                </div>
                                <div class="col-md-6">
                                    <div class="text-end">
                                        <a href="#"> view Details</a>
                                    </div>
                                </div>


                            </div>
                            <hr>
                            <div class="row p-2">
                                <div class="col-md-6">
                                    <p class="text-black mb-0">Fare</p>
                                    <p class="text-black mb-0">OTHERS</p>
                                </div>
                                <div class="col-md-6">
                                    <div class="text-end">
                                        <p class="text-black mb-0">AED 243</p>
                                        <p class="text-black mb-0">AED 40</p>
                                    </div>
                                </div>
                            </div>
                            <hr>
                            <div class="row p-2">
                                <div class="col-md-6">
                                    <h4 class="text-black mb-0">Total Due</h4>
                                    <p class="mb-0">Convenience fee added</p>
                                </div>
                                <div class="col-md-6">
                                    <div class="text-end">
                                        <p class="text-black mb-0">AED 283</p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <sub>Over 53 million satisfied Gulf customers.</sub>
                </div>
            </div>
        </div>
    </section>
</div>